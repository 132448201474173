// Generated using j2ts version null on 2019-11-18 10:07:25.

namespace com.vmware.vsphere.client.h5.network.host.netstack.model {
   export class NetStackReconfigureSpec$DnsReconfigureSpec {
      _type: string = 'com.vmware.vsphere.client.h5.network.host.netstack.model.NetStackReconfigureSpec$DnsReconfigureSpec';
      alternateDns: string;
      domainName: string;
      hostName: string;
      isDhcpEnabled: boolean;
      preferredDns: string;
      searchDomains: string;
      selectedVnic: string;
   }
}

namespace com.vmware.vsphere.client.dvs.api.spec {
   export class DvsResControlUpgradeStatistics {
      _type: string = 'com.vmware.vsphere.client.dvs.api.spec.DvsResControlUpgradeStatistics';
      modifiedPortGroupCount: number;
      modifiedSystemNrpCount: number;
      modifiedVmCount: number;
      userDefinedNrpCount: number;
   }
}

namespace com.vmware.vsphere.client.h5.vm.model.migration {
   export class ComputeResourceTreePageSpec {
      _type: string = 'com.vmware.vsphere.client.h5.vm.model.migration.ComputeResourceTreePageSpec';
      computeResource: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      fetchClusterName: boolean;
      isBatchMigration: boolean;
      isHotMigration: boolean;
      isXvcMigration: boolean;
      migrationType: string;
      vms: com.vmware.vim.binding.vmodl.ManagedObjectReference[];
   }
}

namespace com.vmware.vsphere.client.h5.storage.spec {
   export class QueryReclamationOptionsSpec {
      _type: string = 'com.vmware.vsphere.client.h5.storage.spec.QueryReclamationOptionsSpec';
      datastores: com.vmware.vim.binding.vmodl.ManagedObjectReference[];
   }
}

namespace com.vmware.vsphere.client.dvs.api.spec {
   export class DvsHostRemoveSpec {
      _type: string = 'com.vmware.vsphere.client.dvs.api.spec.DvsHostRemoveSpec';
      hosts: com.vmware.vim.binding.vmodl.ManagedObjectReference[];
   }
}

namespace com.vmware.vsphere.client.host {
   export class SmartCardAuthSettingsSpec {
      _type: string = 'com.vmware.vsphere.client.host.SmartCardAuthSettingsSpec';
      addCertificates: string[];
      enable: boolean;
      removeCertificates: any;
   }
}

namespace com.vmware.vsphere.client.vm.customizations {
   export class VmCustomizationSpec {
      _type: string = 'com.vmware.vsphere.client.vm.customizations.VmCustomizationSpec';
      config: com.vmware.vim.binding.vim.CustomizationSpecItem;
      customizationSpecManager: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      encryptAdminPassword: boolean;
      encryptDomainPassword: boolean;
   }
}

namespace com.vmware.vsphere.client.dvs.api.featureupgrade {
   export class ValidationCheckResult {
      _type: string = 'com.vmware.vsphere.client.dvs.api.featureupgrade.ValidationCheckResult';
      affectedEntities: string[];
      checkName: string;
      message: string;
      type: com.vmware.vsphere.client.dvs.api.common.ValidationResultType;
   }
}

namespace com.vmware.vsphere.client.dvs.api.spec {
   export class DvsConfigSpec {
      _type: string = 'com.vmware.vsphere.client.dvs.api.spec.DvsConfigSpec';
      configSpec: com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$ConfigSpec;
      enableResourceManagement?: boolean;
   }
}

namespace com.vmware.vsphere.client.h5.vm.data {
   export class RegisterVmCompatibilityCheckSpec {
      _type: string = 'com.vmware.vsphere.client.h5.vm.data.RegisterVmCompatibilityCheckSpec';
      datastoreRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      isTemplate: boolean;
      targetRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.upgrade.model {
   export class HostCompatibilityListItem {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.upgrade.model.HostCompatibilityListItem';
      compatibilityErrors: string[];
      hostIcon: string;
      hostName: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter {
   export class DvpgCloneTrafficRulesSpec {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgCloneTrafficRulesSpec';
      portgroups: com.vmware.vim.binding.vmodl.ManagedObjectReference[];
   }
}

namespace com.vmware.vsphere.client.hostprofile.data {
   export class ProfileWrapper {
      _type: string = 'com.vmware.vsphere.client.hostprofile.data.ProfileWrapper';
      arrayFieldName?: string;
      children: com.vmware.vsphere.client.hostprofile.data.ProfileWrapper[];
      description: string;
      errors: string[];
      label: string;
      meta?: any;
      missingTypeData: com.vmware.vsphere.client.hostprofile.data.MissingTypeData[];
      obj?: any;
      policies: com.vmware.vsphere.client.hostprofile.data.ProfileWrapper[];
      profilePath: string;
      profileTypeName?: string;
      propertyName?: string;
      remoteTypeName?: string;
      type: string;
      updateFailure: com.vmware.vsphere.client.hostprofile.data.ProfileUpdateFailure[];
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.create.model {
   export class CreateDvPortgroupPoliciesModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.create.model.CreateDvPortgroupPoliciesModel';
      failoverPolicyModel: com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgFailoverPolicyModel;
      miscPolicyModel: com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgMiscPolicyModel;
      monitoringPolicyModel: com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgMonitoringPolicyModel;
      securityPolicyModel: com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgSecurityPolicyModel;
      trafficShapingPolicyModel: com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgTrafficShapingPolicyModel;
   }
}

namespace com.vmware.vsphere.client.h5.vm {
   export class NameValidationResult {
      _type: string = 'com.vmware.vsphere.client.h5.vm.NameValidationResult';
      error: string;
      vmFolderRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.portlist {
   export class DvsSpanSessionPortListSpec {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.portlist.DvsSpanSessionPortListSpec';
      portIds: string[];
   }
}

namespace com.vmware.vsphere.client.dvs.api.spec {
   export class DvpgImportInfoWrapperSpec {
      _type: string = 'com.vmware.vsphere.client.dvs.api.spec.DvpgImportInfoWrapperSpec';
      dvsImportPortgroupWrapperSpec: com.vmware.vsphere.client.dvs.api.spec.DvsImportPortgroupWrapperSpec;
      error: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.ports.model {
   export class DvsPortFailoverPolicyModel$OverrideOptions {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortFailoverPolicyModel$OverrideOptions';
      beaconProbing: boolean;
      failoverOrder: boolean;
      loadBalancing: boolean;
      notifySwitches: boolean;
      rollingOrder: boolean;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.common.model {
   export class DvpgSecurityPolicyModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgSecurityPolicyModel';
      allowPromiscuous: boolean;
      forgedTransmits: boolean;
      macChanges: boolean;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.portmirroring.model {
   export class DvsPortMirroringSpec {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.DvsPortMirroringSpec';
      sessionKey: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.host.model {
   export class GhostDvsData {
      _type: string = 'com.vmware.vsphere.client.h5.network.host.model.GhostDvsData';
      dvsRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      name: string;
   }
}

namespace com.vmware.vsphere.client.network.switchdiagram.api.spec {
   export class VswitchDiagramVmsModel {
      _type: string = 'com.vmware.vsphere.client.network.switchdiagram.api.spec.VswitchDiagramVmsModel';
      externalAdaptersBySwitch: { [index: string]: com.vmware.vsphere.client.dvs.api.switchdiagram.model.SriovAdapterModel[] };
      portModelsByPortgroup: { [index: string]: com.vmware.vsphere.client.dvs.api.switchdiagram.model.PortModel[] };
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.addvnics.model {
   export class DvpgHostInfoModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.addvnics.model.DvpgHostInfoModel';
      hostName: string;
      hostRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.portbrowser {
   export class DvsSpanSessionPortBrowserData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.portbrowser.DvsSpanSessionPortBrowserData';
      ports: com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.portbrowser.DvsSpanSessionPortBrowserPortData[];
   }
}

namespace com.vmware.vsphere.client.h5.vm.configuration {
   export class WindowsTimeZone {
      _type: string = 'com.vmware.vsphere.client.h5.vm.configuration.WindowsTimeZone';
      code: number;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.common.model {
   export class DvpgResourceAllocationPolicyModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgResourceAllocationPolicyModel';
      isNetworkResourcePoolSupported: boolean;
      isVmVnicResourcePoolSupported: boolean;
      networkResourcePoolKey: string;
      networkResourcePools: com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgResourceAllocationPolicyModel$NetworkResourcePool[];
   }
}

namespace com.vmware.vsphere.client.h5.vm.model.provisioning {
   export class VmCreateScheduledTaskEditDefaultsValidationSpec {
      _type: string = 'com.vmware.vsphere.client.h5.vm.model.provisioning.VmCreateScheduledTaskEditDefaultsValidationSpec';
      datastoreNames?: string[];
      deviceChange?: com.vmware.vim.binding.vim.vm.device.VirtualDeviceSpec[];
      host: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      locators?: com.vmware.vim.binding.vim.vm.RelocateSpec$DiskLocator[];
      pool: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      vm?: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      vmProfile?: com.vmware.vim.binding.vim.vm.ProfileSpec[];
   }
}

namespace com.vmware.vsphere.client.h5.storage.data.host {
   export class HostData {
      _type: string = 'com.vmware.vsphere.client.h5.storage.data.host.HostData';
      areKerberosCredentialsSet: boolean;
      clusterName: string;
      formattedName: string;
      hasConfigStoragePrivilege: boolean;
      hostRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      isHostConnected: boolean;
      isInDomain: boolean;
      isIpV6NfsSupported: boolean;
      isKerberos5iSupported: boolean;
      isNfs4Supported: boolean;
      isVMFS6Supported: boolean;
      isVvolSupported: boolean;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.manage.model {
   export class DvpgPoliciesModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.manage.model.DvpgPoliciesModel';
      failoverPolicyModel: com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgFailoverPolicyModel;
      miscPolicyModel: com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgMiscPolicyModel;
      monitoringPolicyModel: com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgMonitoringPolicyModel;
      policyTypeModel: com.vmware.vsphere.client.h5.network.dvportgroup.manage.model.DvpgPolicyTypeModel;
      resourceAllocationPolicyModel: com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgResourceAllocationPolicyModel;
      securityPolicyModel: com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgSecurityPolicyModel;
      trafficShapingPolicyModel: com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgTrafficShapingPolicyModel;
      vlanPolicyModel: com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgVlanPolicyModel;
   }
}

namespace com.vmware.vsphere.client.h5.network.host.proxyswitch.model {
   export class UplinkPortMapping {
      _type: string = 'com.vmware.vsphere.client.h5.network.host.proxyswitch.model.UplinkPortMapping';
      pnicName: string;
      uplinkPort: any;
      uplinkPortgroup: any;
   }
}

namespace com.vmware.vsphere.client.dvs.api.switchdiagram.model.details {
   export class VmkernelAdapterDetails {
      _type: string = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.details.VmkernelAdapterDetails';
      dvsPortData: com.vmware.vsphere.client.dvs.api.port.DvsPortData;
      hostNetworkDetailsData: com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData;
   }
}

namespace com.vmware.vsphere.client.h5.vm.model {
   export class VmEvcData {
      _type: string = 'com.vmware.vsphere.client.h5.vm.model.VmEvcData';
      guaranteedCPUFeatures: com.vmware.vim.binding.vim.host.CpuIdInfo[];
      key: string;
      label: string;
      summary: string;
      vendor: com.vmware.vsphere.client.h5.vm.model.VmEvcData$VmEvcVendor;
   }
}

namespace com.vmware.vsphere.client.h5.vm.model.migration {
   export class VmMigrationFlowValidationData {
      _type: string = 'com.vmware.vsphere.client.h5.vm.model.migration.VmMigrationFlowValidationData';
      availableVms: string[];
      errors: string[];
      hasNetworkAdapter: boolean;
      vmProperties: { [index: string]: { [index: string]: any } };
      warnings: string[];
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.manage.model {
   export class DvpgPolicyTypeModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.manage.model.DvpgPolicyTypeModel';
      isFailoverPolicyAvailable: boolean;
      isMonitoringPolicyAvailable: boolean;
      isResAllocPolicyAvailable: boolean;
      isSecurityPolicyAvailable: boolean;
      isTrafficFilterPolicyAvailable: boolean;
      isVlanPolicyAvailable: boolean;
   }
}

namespace com.vmware.vsphere.client.h5.storage.data.datastore.reclamation {
   export class DatastoreData {
      _type: string = 'com.vmware.vsphere.client.h5.storage.data.datastore.reclamation.DatastoreData';
      connectedHost: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      datastore: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      datastoreUuid: string;
   }
}

namespace com.vmware.vsphere.client.h5.storage.model {
   export class IncreaseDatastoreDetailsData {
      _type: string = 'com.vmware.vsphere.client.h5.storage.model.IncreaseDatastoreDetailsData';
      datastoreName: string;
      maxFileSizeInB: number;
      vmfs: com.vmware.vim.binding.vim.host.VmfsVolume;
   }
}

namespace com.vmware.vsphere.client.dvs.api.switchdiagram.model {
   export class VirtualMachineSectionModel {
      _type: string = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.VirtualMachineSectionModel';
      externalSriovAdapters: com.vmware.vsphere.client.dvs.api.switchdiagram.model.SriovAdapterModel[];
      portGroupModel: com.vmware.vsphere.client.dvs.api.switchdiagram.model.PortGroupModel;
   }
}

namespace com.vmware.vsphere.client.vm.config {
   export class VmConfigContext {
      _type: string = 'com.vmware.vsphere.client.vm.config.VmConfigContext';
      config: com.vmware.vim.binding.vim.vm.ConfigInfo;
      deviceChange: com.vmware.vim.binding.vim.vm.device.VirtualDeviceSpec[];
      environment: com.vmware.vsphere.client.vm.config.VmConfigEnvironment;
      npivNodeWorldWideNameHexStrings: string[];
      npivPortWorldWideNameHexStrings: string[];
      npivWwnOp: string;
      privileges: string[];
      provisioningType: any;
      rtInfo: com.vmware.vim.binding.vim.vm.RuntimeInfo;
      storageInfo: any;
      times: com.vmware.vsphere.client.vm.config.VmConfigPefMetrics;
      vAppConfigRemoved: boolean;
   }
}

namespace com.vmware.vsphere.client.h5.vm.model.migration {
   export class ComputeResourceValidationData {
      _type: string = 'com.vmware.vsphere.client.h5.vm.model.migration.ComputeResourceValidationData';
      clusterName: string;
      clusterRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      hostName: string;
      hostRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      resourcePoolName: string;
      resourcePoolRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.addhost.model {
   export class DvPortgroupModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.addhost.model.DvPortgroupModel';
      key: string;
      name: string;
   }
}

namespace com.vmware.vsphere.client.vm {
   export class VmRemovalSpec {
      _type: string = 'com.vmware.vsphere.client.vm.VmRemovalSpec';
      deleteFromDisk: boolean;
   }
}

namespace com.vmware.vsphere.client.h5.storage.data.adapters {
   export class NetworkSettingsItem {
      _type: string = 'com.vmware.vsphere.client.h5.storage.data.adapters.NetworkSettingsItem';
      alternateDns: string;
      capabilities: com.vmware.vim.binding.vim.host.InternetScsiHba$IPCapabilities;
      ipv4Address: string;
      ipv4DefaultGateway: string;
      ipv4SubnetMask: string;
      ipv6Address: string[];
      ipv6DefaultGateway: string;
      isAlternateDnsSupported: boolean;
      isIpv4Enabled: boolean;
      isIpv4Supported: boolean;
      isIpv6Enabled: boolean;
      isIpv6Supported: boolean;
      isPreferedDnsSupported: boolean;
      preferedDns: string;
      properties: com.vmware.vim.binding.vim.host.InternetScsiHba$IPProperties;
   }
}

namespace com.vmware.vsphere.client.hostprofile.data {
   export class ProfileUpdateFailure {
      _type: string = 'com.vmware.vsphere.client.hostprofile.data.ProfileUpdateFailure';
      errorMessage?: string;
      parameterId?: string;
      policyId?: string;
      profilePath?: string;
   }
}

namespace com.vmware.vsphere.client.h5.vm.model {
   export class SupportedEvcMode {
      _type: string = 'com.vmware.vsphere.client.h5.vm.model.SupportedEvcMode';
      featureMasks: com.vmware.vim.binding.vim.host.FeatureMask[];
      key: string;
      label: string;
      summary: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.common.model {
   export class DvpgVlanPolicyModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgVlanPolicyModel';
      pvlanConfig: com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$PvlanMapEntry[];
      pvlanId: number;
      vlanId: number;
      vlanTrunkNumericRanges: com.vmware.vim.binding.vim.NumericRange[];
      vlanTrunkRanges: string;
      vlanType: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.ports.model {
   export class DvsPortVlanPolicyModel extends com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgVlanPolicyModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortVlanPolicyModel';
      isUplink: boolean;
      isVlanPolicyOverrided: boolean;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.portlist {
   export class DvsSpanSessionPortListData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.portlist.DvsSpanSessionPortListData';
      items: com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.portlist.DvsSpanSessionPortListItemData[];
   }
}

namespace com.vmware.vsphere.client.dvs.api.switchdiagram.model {
   export class VirtualMachineSectionsModel {
      _type: string = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.VirtualMachineSectionsModel';
      externalSriovAdapters: com.vmware.vsphere.client.dvs.api.switchdiagram.model.SriovAdapterModel[];
      portModelsByPortgroup: { [index: string]: com.vmware.vsphere.client.dvs.api.switchdiagram.model.PortModel[] };
   }
}

namespace com.vmware.vsphere.client.h5.vm.model.migration {
   export class VmMigrationScheduledTaskEditDefaultsValidationSpec {
      _type: string = 'com.vmware.vsphere.client.h5.vm.model.migration.VmMigrationScheduledTaskEditDefaultsValidationSpec';
      movePriority: any;
      spec: com.vmware.vim.binding.vim.vm.RelocateSpec;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model {
   export class DvpgPortModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgPortModel';
      operation: number;
      port: string;
      portRangeEnd: string;
   }
}

namespace com.vmware.vsphere.client.vm.ft {
   export class VmEnableSecondarySpec {
      _type: string = 'com.vmware.vsphere.client.vm.ft.VmEnableSecondarySpec';
   }
}

namespace com.vmware.vsphere.client.spbm.data.profile.content {
   export class PropertyData {
      _type: string = 'com.vmware.vsphere.client.spbm.data.profile.content.PropertyData';
      nestedProperties: com.vmware.vsphere.client.spbm.data.profile.content.PropertyData[];
      propertyName: string;
      propertyValues: string[];
   }
}

namespace com.vmware.vsphere.client.network.switchdiagram.api.spec {
   export class VssDiagramVmsSpec {
      _type: string = 'com.vmware.vsphere.client.network.switchdiagram.api.spec.VssDiagramVmsSpec';
      networkIds: string[];
      switchKeys: string[];
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter {
   export class DvpgEditTrafficRuleSpec {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgEditTrafficRuleSpec';
      editedRule: com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgTrafficRuleModel;
   }
}

namespace com.vmware.vsphere.client.vm.ft {
   export class VmTurnOffFtSpec {
      _type: string = 'com.vmware.vsphere.client.vm.ft.VmTurnOffFtSpec';
   }
}

namespace com.vmware.vsphere.client.dvs.api.host {
   export class HostListItemData {
      _type: string = 'com.vmware.vsphere.client.dvs.api.host.HostListItemData';
      clusterName: string;
      compatibilityErrors: string[];
      connectionState: any;
      connectionStateLabel: string;
      hostIconId: string;
      hostName: string;
      hostRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      vdsState: string;
      vdsStateIcon: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.resmgmt.systemtraffic.model {
   export class DvsSystemTrafficListData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.systemtraffic.model.DvsSystemTrafficListData';
      items: com.vmware.vsphere.client.h5.network.dvs.resmgmt.systemtraffic.model.DvsSystemTrafficListItemData[];
   }
}

namespace com.vmware.vsphere.client.h5.vm.model.migration {
   export class VmMigrationScheduledTaskEditDefaultsData {
      _type: string = 'com.vmware.vsphere.client.h5.vm.model.migration.VmMigrationScheduledTaskEditDefaultsData';
      computeResource: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      migrationType: string;
      movePriority: any;
      networkMatches: any[];
      replicationGroupsData: { [index: string]: com.vmware.vim.binding.sms.storage.replication.GroupInfo };
   }
}

namespace com.vmware.vsphere.client.dvpg.api {
   export class HostNetworkDetailsData$PortPolicy$Security {
      _type: string = 'com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData$PortPolicy$Security';
      allowPromiscuous: boolean;
      forgedTransmits: boolean;
      macChanges: boolean;
   }
}

namespace com.vmware.vsphere.client.dvs.api.spec {
   export class DvsImportPortgroupWrapperSpec {
      _type: string = 'com.vmware.vsphere.client.dvs.api.spec.DvsImportPortgroupWrapperSpec';
      dvsImportPortgroupSpec: com.vmware.vsphere.client.dvs.api.spec.DvsImportPortgroupSpec;
      vlanLabel: string;
   }
}

namespace com.vmware.vsphere.client.h5.vm.configuration {
   export class LinuxTimeZoneLocation {
      _type: string = 'com.vmware.vsphere.client.h5.vm.configuration.LinuxTimeZoneLocation';
      name: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.pvlan.model {
   export class DvsPvlanData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.pvlan.model.DvsPvlanData';
      pvlans: com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$PvlanMapEntry[];
   }
}

namespace com.vmware.vsphere.client.vm.api {
   export class DatastoreMirrorsInfo {
      _type: string = 'com.vmware.vsphere.client.vm.api.DatastoreMirrorsInfo';
      mirrors: com.vmware.vim.binding.vmodl.ManagedObjectReference[];
      ref: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      url: string;
   }
}

namespace com.vmware.vsphere.client.dvs.api.host {
   export class DvsHostsData {
      _type: string = 'com.vmware.vsphere.client.dvs.api.host.DvsHostsData';
      dvsHosts: com.vmware.vsphere.client.dvs.api.host.HostListItemData[];
   }
}

namespace com.vmware.vsphere.client.storage {
   export class DatastoreProtocolEndpointHostData {
      _type: string = 'com.vmware.vsphere.client.storage.DatastoreProtocolEndpointHostData';
      hostURL: string;
      peData: com.vmware.vsphere.client.storage.ProtocolEndpointData;
   }
}

namespace com.vmware.vsphere.client.h5.storage.data.datastore {
   export class DatastoreCopyItem {
      _type: string = 'com.vmware.vsphere.client.h5.storage.data.datastore.DatastoreCopyItem';
      freeSpace: number;
      name: string;
      ref: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      type: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.host.migratevmkadapter.model {
   export class MigrateVmkToVssSpec {
      _type: string = 'com.vmware.vsphere.client.h5.network.host.migratevmkadapter.model.MigrateVmkToVssSpec';
      networkName: string;
      networkToRemoveName: string;
      strandardSwitchName: string;
      vlanId: number;
      vmkDevice: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.resmgmt.systemtraffic.model {
   export class DvsSystemTrafficMeterData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.systemtraffic.model.DvsSystemTrafficMeterData';
      available: number;
      capacity: number;
      ratio: number;
      reserved: number;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model {
   export class DvsVmVnicResourceSettingsDialogData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsVmVnicResourceSettingsDialogData';
      highSharesValue: number;
      limit: number;
      maxReservation: number;
      reservation: number;
      serverError: string;
      serverWarning: string;
      sharesLevel: string;
      sharesValue: number;
      vnicSpecs: any;
   }
}

namespace com.vmware.vsphere.client.h5.network.host.migratevmkadapter.model {
   export class HostStandardPortGroupInfo {
      _type: string = 'com.vmware.vsphere.client.h5.network.host.migratevmkadapter.model.HostStandardPortGroupInfo';
      networkLabel: string;
      sourcePortGroupVlanId: number;
      vlanIds: number[];
   }
}

namespace com.vmware.vsphere.client.vm.migration {
   export class LocationSpec {
      _type: string = 'com.vmware.vsphere.client.vm.migration.LocationSpec';
      cancelRecommendations?: string[];
      priority?: any;
      recommendations?: string[];
      relocateSpec: com.vmware.vim.binding.vim.vm.RelocateSpec;
      testsToRun?: any;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter {
   export class DvpgEditTrafficFilterSpec {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgEditTrafficFilterSpec';
      isTrafficRulesetEnabled: boolean;
      rules: com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgEditRuleSequenceSpec[];
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.addhost.model {
   export class DvsNewHostsData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.addhost.model.DvsNewHostsData';
      hosts: com.vmware.vsphere.client.dvs.api.host.HostListItemData[];
   }
}

namespace com.vmware.vsphere.client.dvpg.api {
   export class HostNetworkDetailsData {
      _type: string = 'com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData';
      device: string;
      dvPortgroup: boolean;
      enabledServices: com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData$EnabledServices;
      ipProperties: com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData$IpProperties;
      mac: string;
      mtu: number;
      networkLabel: string;
      networkStack: string;
      pinnedPnic: string;
      portPolicy: com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData$PortPolicy;
      showNetworkStack: boolean;
      showVlanId: boolean;
      vlanId: number;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.addhost.model {
   export class VnicAssignmentSpec {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.addhost.model.VnicAssignmentSpec';
      changeVersion: string;
      distributedPortgroupRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      host: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      standardPortgroupName: string;
      vnicDevice: string;
   }
}

namespace com.vmware.vsphere.client.network.switchdiagram.api.spec {
   export class OvsDiagramVmsSpec {
      _type: string = 'com.vmware.vsphere.client.network.switchdiagram.api.spec.OvsDiagramVmsSpec';
      networkIds: string[];
      networkKeys: string[];
   }
}

namespace com.vmware.vsphere.client.dvs.api.spec {
   export class DvsImportActionSpec {
      _type: string = 'com.vmware.vsphere.client.dvs.api.spec.DvsImportActionSpec';
      configData: number[];
      createNewObjectKeys: boolean;
      infoSpec: com.vmware.vsphere.client.dvs.api.spec.DvsImportInfoSpec;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.addvnics.model {
   export class DvpgVnicsIpSettingsModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.addvnics.model.DvpgVnicsIpSettingsModel';
      autoConfigurationEnabled: boolean;
      defaultGateway: string;
      defaultGatewayType: string;
      dhcpEnabled: boolean;
      dhcpV6Enabled: boolean;
      ipV6DefaultGateway: string;
      ipV6DefaultGatewayType: string;
      ipv4Enabled: boolean;
      ipv6Enabled: boolean;
      staticIpv6Enabled: boolean;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.ports.model {
   export class DvsPortAddTrafficRuleSpec {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortAddTrafficRuleSpec';
      newRule: com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgTrafficRuleModel;
      portKey: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model {
   export class DvpgSystemQualifierModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgSystemQualifierModel';
      enableSystemQualifier: boolean;
      trafficOperation: number;
      trafficType: string;
   }
}

namespace com.vmware.vsphere.client.h5.spbm.data.wizard {
   export class SpbmVcData {
      _type: string = 'com.vmware.vsphere.client.h5.spbm.data.wizard.SpbmVcData';
      dataServiceNames: string[];
      dataServiceProfiles: com.vmware.vim.binding.pbm.profile.Profile[];
      hasViewAndUpdatePrivileges: boolean;
      namespaceMetadata: any;
      profileNames: string[];
      spbmSupportedFeatures: string[];
      vcErrorMessage: string;
   }
}

namespace com.vmware.vsphere.client.h5.vm.data {
   export class CompatibilityCheckResult {
      _type: string = 'com.vmware.vsphere.client.h5.vm.data.CompatibilityCheckResult';
      localizationArgs: string[];
      localizationKey: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model {
   export class DvsNetworkResourcePoolDialogData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsNetworkResourcePoolDialogData';
      description: string;
      limit: number;
      name: string;
      reservationQuota: number;
   }
}

namespace com.vmware.vsphere.client.h5.vm.data {
   export class ValidateMoveVmSpec {
      _type: string = 'com.vmware.vsphere.client.h5.vm.data.ValidateMoveVmSpec';
      entities: com.vmware.vim.binding.vmodl.ManagedObjectReference[];
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.addhost.model {
   export class VmVnicAssignmentSpec {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.addhost.model.VmVnicAssignmentSpec';
      changeVersion: string;
      distributedPortgroupRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      host: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      key: number;
      vm: com.vmware.vim.binding.vmodl.ManagedObjectReference;
   }
}

namespace com.vmware.vsphere.client.dvpg.api {
   export class HostNetworkDetailsData$PortPolicy {
      _type: string = 'com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData$PortPolicy';
      basicLacpAvailable: boolean;
      blocked: boolean;
      inTrafficShaping: com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData$PortPolicy$TrafficShaping;
      lacp: com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData$PortPolicy$Lacp;
      monitoring: boolean;
      outTrafficShaping: com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData$PortPolicy$TrafficShaping;
      security: com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData$PortPolicy$Security;
      teamingAndFailover: com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData$PortPolicy$TeamingAndFailover;
      trafficShaping: com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData$PortPolicy$TrafficShaping;
      vlan: com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData$PortPolicy$Vlan;
   }
}

namespace com.vmware.vsphere.client.hostprofile.data {
   export class DeferredHostSettingsSpec {
      _type: string = 'com.vmware.vsphere.client.hostprofile.data.DeferredHostSettingsSpec';
      currentHostCustomizations: any[];
      executeProfile: boolean;
      forceValidationOnHosts: com.vmware.vim.binding.vmodl.ManagedObjectReference[];
      formattedCustomizations: string;
      hostApplyProfileWrapper: com.vmware.vsphere.client.hostprofile.data.ProfileWrapper;
      isNewlyAssociatedProfile: boolean;
      skipValidationOnHosts: com.vmware.vim.binding.vmodl.ManagedObjectReference[];
   }
}

namespace com.vmware.vsphere.client.vm.customizations {
   export class VmCustomizationEditSpec extends VmCustomizationSpec {
      _type: string = 'com.vmware.vsphere.client.vm.customizations.VmCustomizationEditSpec';
      isSpecModified: boolean;
      newName: string;
      oldName: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.resmgmt.systemtraffic.model {
   export class DvsSystemTrafficViewData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.systemtraffic.model.DvsSystemTrafficViewData';
      listData: com.vmware.vsphere.client.h5.network.dvs.resmgmt.systemtraffic.model.DvsSystemTrafficListData;
      meterData: com.vmware.vsphere.client.h5.network.dvs.resmgmt.systemtraffic.model.DvsSystemTrafficMeterData;
      overviewData: com.vmware.vsphere.client.h5.network.dvs.resmgmt.systemtraffic.model.DvsSystemTrafficOverviewData;
   }
}

namespace com.vmware.vsphere.client.h5.network.model {
   export class DvsPortsListData {
      _type: string = 'com.vmware.vsphere.client.h5.network.model.DvsPortsListData';
      dvsMor: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      portTrafficFilterSupported: boolean;
      ports: com.vmware.vsphere.client.dvs.api.port.DvsPortData[];
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.addvnics.model {
   export class DvpgHostNetStackGatewayModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.addvnics.model.DvpgHostNetStackGatewayModel';
      hostInfo: com.vmware.vsphere.client.h5.network.dvportgroup.addvnics.model.DvpgHostInfoModel;
      ipv4GatewayAddress: string;
      ipv6GatewayAddress: string;
      isMultiNetStackInstanceSupported: boolean;
   }
}

namespace com.vmware.vsphere.client.dvs.api.switchdiagram.model {
   export class VswitchDiagramModel {
      _type: string = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.VswitchDiagramModel';
      pnics: com.vmware.vsphere.client.dvs.api.switchdiagram.model.PhysicalAdapterModel[];
      portGroups: com.vmware.vsphere.client.dvs.api.switchdiagram.model.PortGroupModel[];
      relations: com.vmware.vsphere.client.dvs.api.switchdiagram.model.GraphRelation[];
      switchKey: string;
      switchType: string;
   }
}

namespace com.vmware.vsphere.client.hostprofile.data.h5 {
   export class ReadHostCustomizationsSpec {
      _type: string = 'com.vmware.vsphere.client.hostprofile.data.h5.ReadHostCustomizationsSpec';
      currentHostCustomizations?: any;
      formattedHostCustomizations?: string;
      hostProfile: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      isNewlyAssociatedProfile: boolean;
   }
}

namespace com.vmware.vsphere.client.spbm.spec {
   export class CalculateCostSpecBase {
      _type: string = 'com.vmware.vsphere.client.spbm.spec.CalculateCostSpecBase';
      profileId: com.vmware.vim.binding.pbm.profile.ProfileId;
      storageContainer: com.vmware.vim.binding.vmodl.ManagedObjectReference;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.healthcheck.model {
   export class DvsHealthCheckSettingsData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.healthcheck.model.DvsHealthCheckSettingsData';
      name: string;
      teamingAndFailoverEnabled: boolean;
      teamingAndFailoverEnabledLabel: string;
      teamingAndFailoverInterval: number;
      vlanMtuEnabled: boolean;
      vlanMtuEnabledLabel: string;
      vlanMtuInterval: number;
   }
}

namespace com.vmware.vsphere.client.dvs.api.spec {
   export class DvPortgroupApplyRestoreSpec {
      _type: string = 'com.vmware.vsphere.client.dvs.api.spec.DvPortgroupApplyRestoreSpec';
      configSpec: com.vmware.vim.binding.vim.dvs.DistributedVirtualPortgroup$ConfigSpec;
   }
}

namespace com.vmware.vsphere.client.h5.network.host.pnic.model {
   export class EditHostPnicModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.host.pnic.model.EditHostPnicModel';
      linkSpecification: com.vmware.vim.binding.vim.host.PhysicalNic$LinkSpeedDuplex[];
      linkSpeed: com.vmware.vim.binding.vim.host.PhysicalNic$LinkSpeedDuplex;
      name: string;
      pci: string;
      sriovInfoItem: any;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.portmirroring.model {
   export class DvsPortMirroringDestinationPorts {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.DvsPortMirroringDestinationPorts';
      destinationPortData: any;
   }
}

namespace com.vmware.vsphere.client.h5.spbm.data {
   export class StorageProfileContainerCompatibilityData$StorageContainerCompatibilityInfo {
      _type: string = 'com.vmware.vsphere.client.h5.spbm.data.StorageProfileContainerCompatibilityData$StorageContainerCompatibilityInfo';
      capacityInBytes: number;
      datacenterDisplayName: string;
      displayName: string;
      errors: string[];
      formattedType: string;
      freeSpaceBytes: number;
      isCompatibile: boolean;
      mor: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      podDisplayName: string;
      podMor: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      warnings: string[];
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model {
   export class DvsNetworkResourcePoolsListData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsNetworkResourcePoolsListData';
      listItems: com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsNetworkResourcePoolsListItemData[];
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.addvnics {
   export class DvpgAddVnicsSpec {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.addvnics.DvpgAddVnicsSpec';
      ipSettingsSpec: com.vmware.vsphere.client.h5.network.dvportgroup.addvnics.model.DvpgVnicsIpSettingsModel;
      mtu: number;
      netStackInstanceKey: string;
      portgroupKey: string;
      switchUuid: string;
      vnicIpSpecs: com.vmware.vsphere.client.h5.network.dvportgroup.addvnics.model.DvpgHostVnicIpModel[];
      vnicSettingsSpec: any;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.wizard {
   export class DvsAddSpanSessionSpec {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.wizard.DvsAddSpanSessionSpec';
      description: string;
      destinationIps: string[];
      destinationPorts: string[];
      destinationUplinks: string[];
      enabled: boolean;
      encapsulationType: string;
      encapsulationVlanId: number;
      mirroredPacketLength: number;
      normalTrafficAllowed: boolean;
      preserveOriginalVlan: boolean;
      samplingRate: number;
      sessionId: number;
      sessionName: string;
      sessionType: string;
      sourcePortsEgress: string[];
      sourcePortsIngress: string[];
      sourceVlanIds: number[];
      tcpIpStack: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model {
   export class DvsNetworkResourcePoolVmsListItemData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsNetworkResourcePoolVmsListItemData';
      deviceKey: number;
      limit: number;
      name: string;
      networkAdapter: string;
      primaryIconId: string;
      reservation: number;
      sharesLevel: string;
      sharesValue: number;
      vmRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model {
   export class DvpgIpQualifierModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgIpQualifierModel';
      destinationIpAddress: com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgIpAddressModel;
      destinationPort: com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgPortModel;
      enableIpQualifier: boolean;
      protocol: com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgProtocolModel;
      sourceIpAddress: com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgIpAddressModel;
      sourcePort: com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgPortModel;
   }
}

namespace com.vmware.vsphere.client.h5.network.host.netstack.model {
   export class NetStackConfigSpec {
      _type: string = 'com.vmware.vsphere.client.h5.network.host.netstack.model.NetStackConfigSpec';
      netStackInstanceKey: string;
   }
}

namespace com.vmware.vim.binding.sms.storage.replication {
   export class GroupInfo {
      _type: string = 'com.vmware.vim.binding.sms.storage.replication.GroupInfo';
      groupId: com.vmware.vim.binding.vim.vm.replication.ReplicationGroupId;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.common.model {
   export class DvpgFailoverPolicyModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgFailoverPolicyModel';
      beaconProbing: boolean;
      failoverOrder: any;
      loadBalancing: string;
      loadBalancingModes: string[];
      notifySwitches: boolean;
      rollingOrder: boolean;
   }
}

namespace com.vmware.vsphere.client.dvs.api.switchdiagram.model.details {
   export class VmVnicAdapterDetails {
      _type: string = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.details.VmVnicAdapterDetails';
      dvsPortData: com.vmware.vsphere.client.dvs.api.port.DvsPortData;
      vmVnicModel: com.vmware.vsphere.client.dvs.api.switchdiagram.model.details.VmVnicModel;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.addhost.model {
   export class VmVnicSettingsData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.addhost.model.VmVnicSettingsData';
      adapterName: string;
      adapterType: string;
      key: number;
      macAddress: string;
      portgroupName: string;
      sriovInfo: com.vmware.vsphere.client.h5.network.dvs.addhost.model.VmVnicSettingsData$SriovInfo;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.pvlan.model {
   export class DvsEditPvlanData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.pvlan.model.DvsEditPvlanData';
      configVersion: string;
      name: string;
      pvlans: com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$PvlanMapEntry[];
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.exp.model {
   export class ExportDvsSpec {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.export.model.ExportDvsSpec';
      description: string;
      includePortGroups: boolean;
   }
}

namespace com.vmware.vsphere.client.vm.tools {
   export class VmToolsInstallerSpec {
      _type: string = 'com.vmware.vsphere.client.vm.tools.VmToolsInstallerSpec';
      operation: com.vmware.vsphere.client.vm.tools.VmToolsInstallerSpec$OperationType;
      upgradeOptions?: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.upgrade.model {
   export class UpgradeDvsActionData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.upgrade.model.UpgradeDvsActionData';
      currentSwitchSpec: com.vmware.vim.binding.vim.dvs.ProductSpec;
      supportedSwitchSpec: com.vmware.vim.binding.vim.dvs.ProductSpec[];
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.upgrade.model {
   export class DvsUpgradeWizardSpec {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.upgrade.model.DvsUpgradeWizardSpec';
      explicitLacpUpgrade: boolean;
      explicitNiocUpgrade: boolean;
      implicitLacpUpgrade: boolean;
      implicitNiocUpgrade: boolean;
      isMultiLagLacpSupported: boolean;
      lag: com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$LacpGroupConfig;
      migrateShares: boolean;
      productSpec: com.vmware.vim.binding.vim.dvs.ProductSpec;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.ports.model {
   export class DvsPortsListData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortsListData';
      ports: com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortData[];
   }
}

namespace com.vmware.vsphere.client.spbm.data.profile.content {
   export class SectionData {
      _type: string = 'com.vmware.vsphere.client.spbm.data.profile.content.SectionData';
      categories: com.vmware.vsphere.client.spbm.data.profile.content.CategoryData[];
      messages: com.vmware.vsphere.client.spbm.data.profile.content.MessageItem[];
      title: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.addvnics.model {
   export class DvpgAddVnicsGatewayModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.addvnics.model.DvpgAddVnicsGatewayModel';
      hostGatewayModels: com.vmware.vsphere.client.h5.network.dvportgroup.addvnics.model.DvpgHostNetStackGatewayModel[];
      netStackKey: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.common.model {
   export class DvpgTrafficShapingPolicyModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgTrafficShapingPolicyModel';
      inShapingPolicy: com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgTrafficShapingPolicyModel$TrafficShapingPolicy;
      outShapingPolicy: com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgTrafficShapingPolicyModel$TrafficShapingPolicy;
   }
}

namespace com.vmware.vsphere.client.dvpg.api {
   export class HostNetworkDetailsData$EnabledServices {
      _type: string = 'com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData$EnabledServices';
      isFtEnabled: boolean;
      isManagementTrafficEnabled: boolean;
      isProvisioningNfcTrafficEnabled: boolean;
      isVmotionEnabled: boolean;
      isVrNfcTrafficEnabled: boolean;
      isVrTrafficEnabled: boolean;
      isVsanEnabled: boolean;
      isVsanWitnessTrafficEnabled: boolean;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.addhost.model {
   export class PnicAssignmentSpec {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.addhost.model.PnicAssignmentSpec';
      host: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      isPnicReassigned: boolean;
      pnicDevice: string;
      uplink: string;
      uplinkPortgroupKey: string;
   }
}

namespace com.vmware.vsphere.client.h5.spbm.data {
   export class StorageProfileContainerCompatibilityData {
      _type: string = 'com.vmware.vsphere.client.h5.spbm.data.StorageProfileContainerCompatibilityData';
      freeCompatibleCapacityInBytes: number;
      freeIncompatibleCapacityInBytes: number;
      standaloneDatastoreCompatibility: com.vmware.vsphere.client.h5.spbm.data.StorageProfileContainerCompatibilityData$StorageContainerCompatibilityInfo[];
      storagePodCompatibility: com.vmware.vsphere.client.h5.spbm.data.StorageProfileContainerCompatibilityData$StoragePodContainerCompatibilityInfo[];
      totalCompatibleCapacityInBytes: number;
      totalIncompatibleCapacityInBytes: number;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.ports.model {
   export class DvsPortPropertiesModel$NetworkResourcePoolData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortPropertiesModel$NetworkResourcePoolData';
      key: string;
      name: string;
      overrided: boolean;
   }
}

namespace com.vmware.vsphere.client.vm.ft {
   export class VmRestartSecondarySpec {
      _type: string = 'com.vmware.vsphere.client.vm.ft.VmRestartSecondarySpec';
   }
}

namespace com.vmware.vsphere.client.h5.vm.data {
   export class VmCryptoUnlockSpec {
      _type: string = 'com.vmware.vsphere.client.h5.vm.data.VmCryptoUnlockSpec';
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.common.model {
   export class DvpgMiscPolicyModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgMiscPolicyModel';
      arePortsBlocked: boolean;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.ports.model {
   export class DvsPortMiscPolicyModel extends com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgMiscPolicyModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortMiscPolicyModel';
      blockPortsOverrided: boolean;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.ports.model {
   export class DvsPortTrafficFilterModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortTrafficFilterModel';
      isTrafficRulesetEnabled: boolean;
      isTrafficRulesetOverrideEnabled: boolean;
      isTrafficRulesetOverwritten: boolean;
      rules: com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgTrafficFilterRule[];
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model {
   export class DvpgProtocolModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgProtocolModel';
      customValue: string;
      operation: number;
      type: number;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model {
   export class DvsNetworkResourcePoolPortgroupsListData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsNetworkResourcePoolPortgroupsListData';
      listItems: com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsNetworkResourcePoolPortgroupsListItemData[];
   }
}

namespace com.vmware.vsphere.client.dvs.api.switchdiagram.model {
   export class SwitchDiagramBatchVmsSpec {
      _type: string = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.SwitchDiagramBatchVmsSpec';
      portgroupIds: string[];
      portgroupKeys: string[];
      switchDiagramFilter: com.vmware.vsphere.client.dvs.api.switchdiagram.model.SwitchDiagramFilter;
   }
}

namespace com.vmware.vsphere.client.spbm.data.profile.content {
   export class ProfileContent {
      _type: string = 'com.vmware.vsphere.client.spbm.data.profile.content.ProfileContent';
      sections: com.vmware.vsphere.client.spbm.data.profile.content.SectionData[];
   }
}

namespace com.vmware.vsphere.client.vm.ft {
   export class VmCreateSecondarySpec {
      _type: string = 'com.vmware.vsphere.client.vm.ft.VmCreateSecondarySpec';
      configSpec: com.vmware.vim.binding.vim.vm.FaultToleranceConfigSpec;
      host: com.vmware.vim.binding.vmodl.ManagedObjectReference;
   }
}

namespace com.vmware.vsphere.client.h5.network.host.netstack.model {
   export class NetStackReconfigureSpec {
      _type: string = 'com.vmware.vsphere.client.h5.network.host.netstack.model.NetStackReconfigureSpec';
      congestionControlAlgorithm: string;
      dnsConfig: com.vmware.vsphere.client.h5.network.host.netstack.model.NetStackReconfigureSpec$DnsReconfigureSpec;
      ipRouteConfig: com.vmware.vim.binding.vim.host.IpRouteConfig;
      name: string;
      netStackInstanceKey: string;
      requestedMaxNumberOfConnections: number;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.addvnics.model {
   export class DvpgHostVnicIpModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.addvnics.model.DvpgHostVnicIpModel';
      hostName: string;
      hostRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      ipAddress: string;
      ipv6Address: string;
      prefixLength: number;
      subnetMask: string;
   }
}

namespace com.vmware.vsphere.client.spbm.spec {
   export class AssignDrpSpec {
      _type: string = 'com.vmware.vsphere.client.spbm.spec.AssignDrpSpec';
      datastores: com.vmware.vim.binding.vmodl.ManagedObjectReference[];
      defaultProfile: com.vmware.vim.binding.pbm.profile.ProfileId;
   }
}

namespace com.vmware.vsphere.client.vcextension {
   export class VcExtensionDetailsData {
      _type: string = 'com.vmware.vsphere.client.vcextension.VcExtensionDetailsData';
      description: string;
      managementUrl: string;
      productUrl: string;
      vendorUrl: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.host.migratevmkadapter.model {
   export class DestinationStandardPortGroupInitialInfo {
      _type: string = 'com.vmware.vsphere.client.h5.network.host.migratevmkadapter.model.DestinationStandardPortGroupInitialInfo';
      portGroupUniquePreffix: string;
      vmkDevice: string;
   }
}

namespace com.vmware.vsphere.client.h5.vm.configuration {
   export class LinuxTimeZoneArea {
      _type: string = 'com.vmware.vsphere.client.h5.vm.configuration.LinuxTimeZoneArea';
      locations: com.vmware.vsphere.client.h5.vm.configuration.LinuxTimeZoneLocation[];
      name: string;
   }
}

namespace com.vmware.vsphere.client.dvs.api.spec {
   export class DvsImportPortgroupSpec {
      _type: string = 'com.vmware.vsphere.client.dvs.api.spec.DvsImportPortgroupSpec';
      annotation: string;
      autoExpand: boolean;
      isUplink: boolean;
      nameAlreadyExists: boolean;
      newPortgroupName: string;
      noPortgroups: boolean;
      portBinding: any;
      portgroupBlob?: number[];
      portgroupName: string;
      pvlanPortType: string;
      pvlanPrimaryId: number;
      tooManyPortgroups: boolean;
      vlanSpec: com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$VlanSpec;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.portlist {
   export class DvsSpanSessionPortListItemData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.portlist.DvsSpanSessionPortListItemData';
      connectee: string;
      connecteeType: string;
      host: string;
      portId: string;
      trafficDirection: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.dialog {
   export class DvsEditSpanSessionDialogData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.dialog.DvsEditSpanSessionDialogData';
      description: string;
      destinationAddresses: string[];
      destinationPorts: string[];
      dvsName: string;
      enabled: boolean;
      encapsulationType: string;
      encapsulationVlanId: number;
      erspanSupported: boolean;
      key: string;
      mirrorNetstackSupported: boolean;
      mirroredPacketLength: number;
      mirroredPacketLengthEnabled: boolean;
      normalTrafficAllowed: boolean;
      preserveOriginalVlan: boolean;
      samplingRate: number;
      sessionId: number;
      sessionName: string;
      sessionType: string;
      sourcePortsEgress: string[];
      sourcePortsIngress: string[];
      sourceVlans: number[];
      tcpIpStack: string;
      uplinkPortsExisting: string[];
      uplinkPortsSelected: string[];
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter {
   export class DvpgEditRuleSequenceSpec {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgEditRuleSequenceSpec';
      ruleKey: string;
      sequence: number;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.ports.model {
   export class DvsPortEditData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortEditData';
      configVersion: string;
      dvPortgroupPortProperties: com.vmware.vsphere.client.dvpg.api.DvPortgroupPortProperties;
      failoverPolicy: com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortFailoverPolicyModel;
      isLag: boolean;
      isUplink: boolean;
      miscPolicy: com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortMiscPolicyModel;
      monitoringPolicy: com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortMonitoringPolicyModel;
      properties: com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortPropertiesModel;
      securityPolicy: com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$SecurityPolicy;
      switchName: string;
      switchRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      trafficShapingPolicy: com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortTrafficShapingPolicyModel;
      uplinkPortTeamingPolicy: com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$UplinkPortTeamingPolicy;
      vlanPolicy: com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortVlanPolicyModel;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.ports.model {
   export class DvsPortEditTrafficRuleSpec {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortEditTrafficRuleSpec';
      editedRule: com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgTrafficRuleModel;
      portKey: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.addhost.model {
   export class VmVnicSettingsData$SriovInfo {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.addhost.model.VmVnicSettingsData$SriovInfo';
      allowGuestOsMtuChange: boolean;
      physicalFunctionDeviceId: string;
      physicalFunctionId: string;
      virtualFunctionId: string;
   }
}

namespace com.vmware.vsphere.client.vm.ft {
   export class VmTestFailoverSpec {
      _type: string = 'com.vmware.vsphere.client.vm.ft.VmTestFailoverSpec';
   }
}

namespace com.vmware.vsphere.client.vm.customizations {
   export class VmCustomizationDuplicateValidationResult {
      _type: string = 'com.vmware.vsphere.client.vm.customizations.VmCustomizationDuplicateValidationResult';
      hasVcPrivileges: boolean;
      keyValidationSucceeded: boolean;
   }
}

namespace com.vmware.vsphere.client.h5.storage.data.datastore.vvol {
   export class VvolStorageProvidersViewData {
      _type: string = 'com.vmware.vsphere.client.h5.storage.data.datastore.vvol.VvolStorageProvidersViewData';
      storageProviderItems: com.vmware.vsphere.client.h5.storage.data.datastore.vvol.VvolStorageProvidersViewData$StorageProviderItem[];
   }
}

namespace com.vmware.vsphere.client.vm.customizations {
   export class VmCustomizationImportValidationResult {
      _type: string = 'com.vmware.vsphere.client.vm.customizations.VmCustomizationImportValidationResult';
      customizationSpecItem: com.vmware.vim.binding.vim.CustomizationSpecItem;
      validationSucceeded: boolean;
   }
}

namespace com.vmware.vsphere.client.dvs.api.spec {
   export class DvsUpdateHealthCheckSpec {
      _type: string = 'com.vmware.vsphere.client.dvs.api.spec.DvsUpdateHealthCheckSpec';
      config: com.vmware.vim.binding.vim.DistributedVirtualSwitch$HealthCheckConfig[];
   }
}

namespace com.vmware.vsphere.client.h5.network.host.wizard.addnetworking.model {
   export class PnicBindingDataItem {
      _type: string = 'com.vmware.vsphere.client.h5.network.host.wizard.addnetworking.model.PnicBindingDataItem';
      device: string;
      pnicId: string;
   }
}

namespace com.vmware.vsphere.client.dvpg.api {
   export class HostNetworkDetailsData$PortPolicy$Lacp {
      _type: string = 'com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData$PortPolicy$Lacp';
      enable: boolean;
      mode: string;
   }
}

namespace com.vmware.vsphere.client.h5.vm {
   export class ValidateNameSpec {
      _type: string = 'com.vmware.vsphere.client.h5.vm.ValidateNameSpec';
      datacenterRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      folderRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      name: string;
   }
}

namespace com.vmware.vsphere.client.h5.vapp.data {
   export class SourceVappData {
      _type: string = 'com.vmware.vsphere.client.h5.vapp.data.SourceVappData';
      ipAllocationPolicy: string;
      ipProtocol: string;
      name: string;
      networks: com.vmware.vim.binding.vmodl.ManagedObjectReference[];
      properties: com.vmware.vim.binding.vim.vApp.PropertyInfo[];
      vms: com.vmware.vim.binding.vmodl.ManagedObjectReference[];
   }
}

namespace com.vmware.vsphere.client.h5.vm.data {
   export class MigrateOrMoveValidationSpec {
      _type: string = 'com.vmware.vsphere.client.h5.vm.data.MigrateOrMoveValidationSpec';
      entities: com.vmware.vim.binding.vmodl.ManagedObjectReference[];
      target: com.vmware.vim.binding.vmodl.ManagedObjectReference;
   }
}

namespace com.vmware.vsphere.client.hostprofile.data {
   export class MissingTypeData {
      _type: string = 'com.vmware.vsphere.client.hostprofile.data.MissingTypeData';
      paramId: string;
      policyId: string;
      policyOptionId: string;
      valueType: string;
   }
}

namespace com.vmware.vsphere.client.vm.config {
   export class VmConfigEnvironment {
      _type: string = 'com.vmware.vsphere.client.vm.config.VmConfigEnvironment';
      configOption: com.vmware.vim.binding.vim.vm.ConfigOption;
      configTarget: com.vmware.vim.binding.vim.vm.ConfigTarget;
      datastoreBrowser: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      descriptors: com.vmware.vim.binding.vim.vm.ConfigOptionDescriptor[];
      hostCapability: com.vmware.vim.binding.vim.host.Capability;
      hostCpuMhz: any;
      ipPoolData: any;
      isInCluster: boolean;
      parentVApp: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      powerState: any;
   }
}

namespace com.vmware.vsphere.client.h5.spbm.data {
   export class VmStorageProfileComponentsData {
      _type: string = 'com.vmware.vsphere.client.h5.spbm.data.VmStorageProfileComponentsData';
      diskId: string;
      diskSizeInB: number;
      dsMor: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      dsName: string;
      dsType: string;
      formattedDsType: string;
      isVmHome: boolean;
      name: string;
      profile: com.vmware.vim.binding.pbm.profile.Profile;
      virtualDisk: com.vmware.vim.binding.vim.vm.device.VirtualDisk;
      vmHomePath: string;
   }
}

namespace com.vmware.vsphere.client.h5.vm.model {
   export class VmStoragePageData {
      _type: string = 'com.vmware.vsphere.client.h5.vm.model.VmStoragePageData';
      profileError: string;
      profileStoragePageData: { [index: string]: any };
   }
}

namespace com.vmware.vsphere.client.dvs.api.spec {
   export class DvpgRestoreSectionRepresentationSpec {
      _type: string = 'com.vmware.vsphere.client.dvs.api.spec.DvpgRestoreSectionRepresentationSpec';
      sectionSettings: com.vmware.vsphere.client.dvs.api.spec.DvpgRestoreSettingRepresentationSpec[];
      sectionTitle: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.portmirroring.model {
   export class DvsPortMirroringData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.DvsPortMirroringData';
      portMirroringSessionsData: com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.DvsPortMirroringSessionData[];
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.addhost.model {
   export class DvsAddAndManageHostsSpec {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.addhost.model.DvsAddAndManageHostsSpec';
      areMemberHosts: boolean;
      dvsConfigVersion: string;
      hosts: com.vmware.vim.binding.vmodl.ManagedObjectReference[];
      hostsToMutate: com.vmware.vim.binding.vmodl.ManagedObjectReference[];
      pnicAssignmentSpecs: com.vmware.vsphere.client.h5.network.dvs.addhost.model.PnicAssignmentSpec[];
      vmVnicAssignmentSpecs: com.vmware.vsphere.client.h5.network.dvs.addhost.model.VmVnicAssignmentSpec[];
      vnicAssignmentSpecs: com.vmware.vsphere.client.h5.network.dvs.addhost.model.VnicAssignmentSpec[];
   }
}

namespace com.vmware.vsphere.client.hostprofile.data.h5 {
   export class HostProfileCheckComplianceSpec {
      _type: string = 'com.vmware.vsphere.client.hostprofile.data.h5.HostProfileCheckComplianceSpec';
   }
}

namespace com.vmware.vsphere.client.dvs.api.switchdiagram.model.details {
   export class VmVnicResourceAllocationModel {
      _type: string = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.details.VmVnicResourceAllocationModel';
      limit: number;
      reservation: number;
      sharesLevel: string;
      sharesValue: number;
   }
}

namespace com.vmware.vsphere.client.h5.vm.model.migration {
   export class VmMigrationCancelRecommendationsSpec {
      _type: string = 'com.vmware.vsphere.client.h5.vm.model.migration.VmMigrationCancelRecommendationsSpec';
      keys: string[];
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.addvnics.model {
   export class DvpgHostVnicsSettingsModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.addvnics.model.DvpgHostVnicsSettingsModel';
      CUSTOM_MTU: string;
      IPV4: string;
      IPV6: string;
      MIXED: string;
      SWITCH_MTU: string;
      dvpgKey: string;
      dvsUuid: string;
      gatewayModels: com.vmware.vsphere.client.h5.network.dvportgroup.addvnics.model.DvpgAddVnicsGatewayModel[];
      gatewayOnNicSupported: boolean;
      ipSettingsType: string;
      ipv6IncompatibleHosts: com.vmware.vsphere.client.h5.network.dvportgroup.addvnics.model.DvpgHostInfoModel[];
      isDvsMtuAvailable: boolean;
      isIpv6Enabled: boolean;
      isProvVnicAvailOnDefaultStack: boolean;
      isVmotionVnicAvailOnDefaultStack: boolean;
      mtu: number;
      mtuSelectedType: string;
      multipleNetStackIncompatibleHosts: com.vmware.vsphere.client.h5.network.dvportgroup.addvnics.model.DvpgHostInfoModel[];
      multipleNetStackSupported: boolean;
      netStackItems: any[];
      networkLabel: string;
      selectedNetStackKey: string;
      supportedServices: com.vmware.vsphere.client.h5.network.dvportgroup.addvnics.model.DvpgHostVnicServiceModel[];
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.portmirroring.model {
   export class DvsPortMirroringSessionData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.DvsPortMirroringSessionData';
      description: string;
      enabled: string;
      encapsulationType: string;
      encapsulationVlanId: string;
      isDvPortMirrorSession: boolean;
      isEncapsulatedSession: boolean;
      isLegacySession: boolean;
      isRemoteDestinationSession: boolean;
      isRemoteSourceSession: boolean;
      key: string;
      mirroredPacketLength: string;
      name: string;
      normalTrafficOnDestPorts: string;
      preserveOriginalVlan: string;
      samplingRate: string;
      sessionId: number;
      sessionType: string;
      tcpIpStack: string;
   }
}

namespace com.vmware.vsphere.client.vm.customizations {
   export class VmCustomizationImportSpec {
      _type: string = 'com.vmware.vsphere.client.vm.customizations.VmCustomizationImportSpec';
      customizationSpecItem: com.vmware.vim.binding.vim.CustomizationSpecItem;
      customizationSpecManager: com.vmware.vim.binding.vmodl.ManagedObjectReference;
   }
}

namespace com.vmware.vsphere.client.h5.vm.model.provisioning {
   export class VmConfigEnvironmentPerPoolParams {
      _type: string = 'com.vmware.vsphere.client.h5.vm.model.provisioning.VmConfigEnvironmentPerPoolParams';
      gosId: string;
      hostRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      key: string;
   }
}

namespace com.vmware.vsphere.client.dvportgroup {
   export class DvPortgroupCreateSpec {
      _type: string = 'com.vmware.vsphere.client.dvportgroup.DvPortgroupCreateSpec';
      configSpec: com.vmware.vim.binding.vim.dvs.DistributedVirtualPortgroup$ConfigSpec;
      switchRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
   }
}

namespace com.vmware.vsphere.client.dvpg.api {
   export class HostNetworkDetailsData$PortPolicy$TrafficShaping {
      _type: string = 'com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData$PortPolicy$TrafficShaping';
      averageBandwidthSummary: string;
      burstSizeSummary: string;
      enabled: boolean;
      peakBandwidthSummary: string;
   }
}

namespace com.vmware.vsphere.client.spbm.spec {
   export class CalculateProvisioningCostSpec extends CalculateCostSpecBase {
      _type: string = 'com.vmware.vsphere.client.spbm.spec.CalculateProvisioningCostSpec';
      sizeInBytes: number;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model {
   export class DvsNetworkResourcePoolVmsListData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsNetworkResourcePoolVmsListData';
      listItems: com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsNetworkResourcePoolVmsListItemData[];
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.common.model {
   export class DvpgTrafficShapingPolicyModel$TrafficShapingPolicy {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgTrafficShapingPolicyModel$TrafficShapingPolicy';
      averageBandwidth: number;
      burstSize: number;
      enabled: boolean;
      peakBandwidth: number;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.addhost.model {
   export class DvsAddHostUplinkData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.addhost.model.DvsAddHostUplinkData';
      lacpGroupConfigs: com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$LacpGroupConfig[];
      uplinkNames: string[];
      uplinkPortgroups: com.vmware.vsphere.client.h5.network.dvs.addhost.model.DvPortgroupModel[];
      uuid: string;
   }
}

namespace com.vmware.vsphere.client.h5.vm.data {
   export class VmEvcSpec {
      _type: string = 'com.vmware.vsphere.client.h5.vm.data.VmEvcSpec';
      featureMasks: com.vmware.vim.binding.vim.host.FeatureMask[];
   }
}

namespace com.vmware.vsphere.client.h5.network.vm.migrate.model {
   export class MigrateVmNetworkDragAndDropResponse {
      _type: string = 'com.vmware.vsphere.client.h5.network.vm.migrate.model.MigrateVmNetworkDragAndDropResponse';
      errorMessage: string;
      warnUser: boolean;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.ports.model {
   export class DvsPortTrunkVlanSpec {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortTrunkVlanSpec';
      numericRanges: string;
   }
}

namespace com.vmware.vsphere.client.vm.migration {
   export class LocationSpecPair {
      _type: string = 'com.vmware.vsphere.client.vm.migration.LocationSpecPair';
      entity: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      spec: com.vmware.vsphere.client.vm.migration.LocationSpec;
   }
}

namespace com.vmware.vsphere.client.h5.vm.model {
   export class VmAdvancedStoragePageData {
      _type: string = 'com.vmware.vsphere.client.h5.vm.model.VmAdvancedStoragePageData';
      devices?: com.vmware.vim.binding.vim.vm.device.VirtualDevice[];
      host?: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      storagePoliciesAssignments: any;
      virtualDisks: com.vmware.vim.binding.vim.vm.device.VirtualDisk[];
      vmHomeDatastore: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      vmReplicationGroupAssignments: any[];
   }
}

namespace com.vmware.vsphere.client.h5.network.host.proxyswitch.spec {
   export class UpdateProxySwitchUplinkMappingSpec {
      _type: string = 'com.vmware.vsphere.client.h5.network.host.proxyswitch.spec.UpdateProxySwitchUplinkMappingSpec';
      proxySwitchKey: string;
      uplinkPortMappings: com.vmware.vsphere.client.h5.network.host.proxyswitch.model.UplinkPortMapping[];
   }
}

namespace com.vmware.vsphere.client.vm.customizations {
   export class VmCustomizationDuplicateSpec {
      _type: string = 'com.vmware.vsphere.client.vm.customizations.VmCustomizationDuplicateSpec';
      newSpecDesc: string;
      newSpecName: string;
      sourceSpecManagerRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      sourceSpecName: string;
      targetVcGuid: string;
   }
}

namespace com.vmware.vsphere.client.vm.ft {
   export class VmDisableSecondarySpec {
      _type: string = 'com.vmware.vsphere.client.vm.ft.VmDisableSecondarySpec';
   }
}

namespace com.vmware.vsphere.client.vm.config {
   export class VmConfigPefMetrics {
      _type: string = 'com.vmware.vsphere.client.vm.config.VmConfigPefMetrics';
      browserFinishTime: number;
      browserStartTime: number;
      javaFinishTime: number;
      javaStartTime: number;
      javaTiming: string;
   }
}

namespace com.vmware.vsphere.client.dvs.api.spec {
   export class DvPortgroupRestoreInfoWrapperSpec {
      _type: string = 'com.vmware.vsphere.client.dvs.api.spec.DvPortgroupRestoreInfoWrapperSpec';
      dvPortgroupRestoreInfoSpec: any;
      dvpgRestoreSectionRepresentationSpec: com.vmware.vsphere.client.dvs.api.spec.DvpgRestoreSectionRepresentationSpec[];
      error: string;
   }
}

namespace com.vmware.vsphere.client.dvs.api.port {
   export class DvsPortData {
      _type: string = 'com.vmware.vsphere.client.dvs.api.port.DvsPortData';
      connectedEntity: string;
      connectedEntityType: string;
      description: string;
      host: string;
      lagName: string;
      mac: string;
      name: string;
      portId: string;
      portgroup: string;
      portgroupKey: string;
      state: string;
      vlan: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.ports.model {
   export class DvsPortData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortData';
      connectedEntity: string;
      connectedEntityType: string;
      host: string;
      mac: string;
      name: string;
      portId: string;
      portgroup: string;
      state: string;
      vlan: string;
   }
}

namespace com.vmware.vsphere.client.dvs.api.spec {
   export class DvsApplyProductSpecCommandSpec {
      _type: string = 'com.vmware.vsphere.client.dvs.api.spec.DvsApplyProductSpecCommandSpec';
      dependentSpecs: any;
      dynamicProperty: any;
      dynamicType: string;
      isResultTaskProvider: boolean;
      mor: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      operationType: any;
      productSpec: com.vmware.vim.binding.vim.dvs.ProductSpec;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model {
   export class DvpgMacAddressModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgMacAddressModel';
      macAddress: string;
      operation: number;
      wildcardMask: string;
   }
}

namespace com.vmware.vsphere.client.vm.config {
   export class VmCdromContext {
      _type: string = 'com.vmware.vsphere.client.vm.config.VmCdromContext';
      cdRom: com.vmware.vim.binding.vim.vm.CdromInfo[];
      datastore: com.vmware.vim.binding.vim.vm.DatastoreInfo[];
      datastoreBrowser: com.vmware.vim.binding.vmodl.ManagedObjectReference;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter {
   export class DvpgRemoveTrafficRuleSpec {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgRemoveTrafficRuleSpec';
      key: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter {
   export class DvpgTrafficFilterRule {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgTrafficFilterRule';
      action: string;
      direction: string;
      displaySequence: number;
      expanded: boolean;
      key: string;
      name: string;
      qualifierTypeLabels: string[];
      qualifiers: string[];
      sequence: number;
   }
}

namespace com.vmware.vsphere.client.h5.network.host.proxyswitch.model {
   export class ProxySwitchUplinkData {
      _type: string = 'com.vmware.vsphere.client.h5.network.host.proxyswitch.model.ProxySwitchUplinkData';
      lagMapping: { [index: string]: string[] };
      pnicDetails: { [index: string]: com.vmware.vsphere.client.network.host.api.HostPnicDetailsItem };
      unclaimedPnics: string[];
      uplinkPortMapping: com.vmware.vsphere.client.h5.network.host.proxyswitch.model.UplinkPortMapping[];
      uplinkPortgroups: any;
   }
}

namespace com.vmware.vsphere.client.dvs.api.switchdiagram.model {
   export class BaseSwitchDiagramComponent {
      _type: string = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.BaseSwitchDiagramComponent';
      id: string;
   }
}

namespace com.vmware.vsphere.client.dvs.api.switchdiagram.model {
   export class UplinkPortModel extends BaseSwitchDiagramComponent {
      _type: string = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.UplinkPortModel';
      adapters: com.vmware.vsphere.client.dvs.api.switchdiagram.model.AdapterModel[];
      hostsCount: number;
      portStatus: com.vmware.vsphere.client.dvs.api.switchdiagram.model.PortStatus;
      title: string;
   }
}

namespace com.vmware.vsphere.client.dvs.api.switchdiagram.model {
   export class PortModel extends BaseSwitchDiagramComponent {
      _type: string = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.PortModel';
      activeUplinkPorts: string[];
      hostValue: string;
      isOrphanedVm: boolean;
      isSriovVirtualFunction: boolean;
      macAddress: string;
      portConnecteeType: any;
      portStatus: com.vmware.vsphere.client.dvs.api.switchdiagram.model.PortStatus;
      powerState: any;
      title: string;
      virtualDevice: com.vmware.vim.binding.vim.vm.device.VirtualDevice;
      virtualDeviceKey: number;
      vmName: string;
      vmValue: string;
      vmkDevice: string;
   }
}

namespace com.vmware.vsphere.client.dvs.api.switchdiagram.model {
   export class PortGroupModel extends BaseSwitchDiagramComponent {
      _type: string = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.PortGroupModel';
      isFakeGroup: boolean;
      portgroupKey: string;
      portgroupValue: string;
      title: string;
      virtualMachinesCount: number;
      vlan: string;
      vmVnics: com.vmware.vsphere.client.dvs.api.switchdiagram.model.PortModel[];
      vmkernelAdapters: com.vmware.vsphere.client.dvs.api.switchdiagram.model.PortModel[];
   }
}

namespace com.vmware.vsphere.client.dvs.api.switchdiagram.model {
   export class UplinkPortGroupModel extends BaseSwitchDiagramComponent {
      _type: string = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.UplinkPortGroupModel';
      childItems: com.vmware.vsphere.client.dvs.api.switchdiagram.model.BaseSwitchDiagramComponent[];
      isFake: boolean;
      portgroupValue: string;
      title: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.healthcheck.model {
   export class DvsHealthCheckDetailsData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.healthcheck.model.DvsHealthCheckDetailsData';
      detailsItemsData: com.vmware.vsphere.client.h5.network.dvs.healthcheck.model.DvsHealthCheckDetailsItemData[];
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model {
   export class DvsNetworkResourcePoolsData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsNetworkResourcePoolsData';
      meterData: com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsNetworkResourcePoolsMeterData;
      overviewData: com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsNetworkResourcePoolsOverviewData;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.wizard {
   export class DvsSpanSessionDestinationUplinksData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.wizard.DvsSpanSessionDestinationUplinksData';
      uplinkName: string;
   }
}

namespace com.vmware.vsphere.client.dvs.api.spec {
   export class ResControlUpgradeVersionCommandSpec {
      _type: string = 'com.vmware.vsphere.client.dvs.api.spec.ResControlUpgradeVersionCommandSpec';
      configVersion: string;
      dependentSpecs: any;
      dynamicProperty: any;
      dynamicType: string;
      isResultTaskProvider: boolean;
      mor: com.vmware.vim.binding.vmodl.ManagedObjectReference;
   }
}

namespace com.vmware.vsphere.client.dvpg.api {
   export class DvPortgroupPortProperties {
      _type: string = 'com.vmware.vsphere.client.dvpg.api.DvPortgroupPortProperties';
      blockOverrideAllowed: boolean;
      ipfixOverrideAllowed: boolean;
      networkResourcePoolOverrideAllowed: boolean;
      portConfigResetAtDisconnect: boolean;
      securityPolicyOverrideAllowed: boolean;
      shapingOverrideAllowed: boolean;
      trafficFilterOverrideAllowed: boolean;
      uplinkTeamingOverrideAllowed: boolean;
      vendorConfigOverrideAllowed: boolean;
      vlanOverrideAllowed: boolean;
   }
}

namespace com.vmware.vsphere.client.h5.vm.data {
   export class VmConfigEnvironmentData {
      _type: string = 'com.vmware.vsphere.client.h5.vm.data.VmConfigEnvironmentData';
      configOption: com.vmware.vim.binding.vim.vm.ConfigOption;
      configTarget: com.vmware.vim.binding.vim.vm.ConfigTarget;
      datastoreBrowser: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      envBrowserRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      hostCapability: com.vmware.vim.binding.vim.host.Capability;
      hostCpuMhz: any;
      ipPoolData: any;
      isInCluster: boolean;
      parentVApp: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      powerState: any;
   }
}

namespace com.vmware.vsphere.client.h5.vapp.data {
   export class ValidateMoveVappSpec {
      _type: string = 'com.vmware.vsphere.client.h5.vapp.data.ValidateMoveVappSpec';
      entities: com.vmware.vim.binding.vmodl.ManagedObjectReference[];
   }
}

namespace com.vmware.vsphere.client.vapp {
   export class VAppRemovalSpec {
      _type: string = 'com.vmware.vsphere.client.vapp.VAppRemovalSpec';
      deleteFromDisk: boolean;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.addhost.model {
   export class DvsHostsSpec {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.addhost.model.DvsHostsSpec';
      areMemberHosts: boolean;
      hosts: com.vmware.vim.binding.vmodl.ManagedObjectReference[];
   }
}

namespace com.vmware.vsphere.client.h5.vapp.data {
   export class ValidateSourceVappSpec {
      _type: string = 'com.vmware.vsphere.client.h5.vapp.data.ValidateSourceVappSpec';
      sourceVappId: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.healthcheck.model {
   export class DvsHealthCheckItemData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.healthcheck.model.DvsHealthCheckItemData';
      dvsStatus: string;
      dvsStatusIcon: string;
      hostIcon: string;
      hostName: string;
      hostNameFormated: string;
      hostState: string;
      mtuHealthStatus: string;
      mtuHealthStatusIcon: string;
      teamingAndFailoverHealthStatus: string;
      teamingAndFailoverHealthStatusIcon: string;
      vlanHealthStatus: string;
      vlanHealthStatusIcon: string;
   }
}

namespace com.vmware.vsphere.client.h5.vm.model {
   export class MigrateOrMoveValidationData {
      _type: string = 'com.vmware.vsphere.client.h5.vm.model.MigrateOrMoveValidationData';
      migrateTarget: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      migrateVms: com.vmware.vim.binding.vmodl.ManagedObjectReference[];
      moveTarget: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      moveVms: com.vmware.vim.binding.vmodl.ManagedObjectReference[];
   }
}

namespace com.vmware.vsphere.client.h5.vm.model.provisioning {
   export class VmCreateScheduledTaskEditDefaultsData {
      _type: string = 'com.vmware.vsphere.client.h5.vm.model.provisioning.VmCreateScheduledTaskEditDefaultsData';
      computeResource: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      datastoreRefs: { [index: string]: com.vmware.vim.binding.vmodl.ManagedObjectReference };
      guestOsId: string;
      name: string;
      replicationGroupsData: { [index: string]: com.vmware.vim.binding.sms.storage.replication.GroupInfo };
   }
}

namespace com.vmware.vsphere.client.dvpg.api {
   export class HostNetworkDetailsData$PortPolicy$Vlan {
      _type: string = 'com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData$PortPolicy$Vlan';
      type: string;
      value: string;
   }
}

namespace com.vmware.vsphere.client.storage {
   export class DatastoreCreateSpec {
      _type: string = 'com.vmware.vsphere.client.storage.DatastoreCreateSpec';
      host: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      storageFolder: com.vmware.vim.binding.vmodl.ManagedObjectReference;
   }
}

namespace com.vmware.vsphere.client.storage.vvol {
   export class CreateVvolDatastoreSpec extends com.vmware.vsphere.client.storage.DatastoreCreateSpec {
      _type: string = 'com.vmware.vsphere.client.storage.vvol.CreateVvolDatastoreSpec';
      vvolDatastoreSpec: com.vmware.vim.binding.vim.host.DatastoreSystem$VvolDatastoreSpec;
   }
}

namespace com.vmware.vsphere.client.dvpg.api {
   export class DvPortgroupDetailsData {
      _type: string = 'com.vmware.vsphere.client.dvpg.api.DvPortgroupDetailsData';
      policies: com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData$PortPolicy;
      properties: any;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.healthcheck.model {
   export class DvsHealthCheckDetailsItemData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.healthcheck.model.DvsHealthCheckDetailsItemData';
      hostMor: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      hostName: string;
      ipHashDetails: string;
      mtuStatus: string;
      mtuStatusIcon: string;
      pnicName: string;
      teamingStatus: string;
      teamingStatusIcon: string;
      uplinkName: string;
      vlanStatus: string;
      vlanStatusIcon: string;
      vlanTrunk: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model {
   export class DvsNetworkResourcePoolsMeterData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsNetworkResourcePoolsMeterData';
      configuredReservation: number;
      grantedQuota: number;
      unusedQuota: number;
      vmReservation: number;
   }
}

namespace com.vmware.vsphere.client.network {
   export class PhysicalNicEditSpec {
      _type: string = 'com.vmware.vsphere.client.network.PhysicalNicEditSpec';
      device: string;
      linkSpeed: com.vmware.vim.binding.vim.host.PhysicalNic$LinkSpeedDuplex;
      sriovConfig: com.vmware.vim.binding.vim.host.SriovConfig;
   }
}

namespace com.vmware.vsphere.client.dvs.api.spec {
   export class LacpUpgradeVersionCommandSpec {
      _type: string = 'com.vmware.vsphere.client.dvs.api.spec.LacpUpgradeVersionCommandSpec';
      apiVersion: string;
      configVersion: string;
      dependentSpecs: any;
      dynamicProperty: any;
      dynamicType: string;
      isResultTaskProvider: boolean;
      mor: com.vmware.vim.binding.vmodl.ManagedObjectReference;
   }
}

namespace com.vmware.vsphere.client.dvs.api.spec {
   export class DvsRestoreActionSpec {
      _type: string = 'com.vmware.vsphere.client.dvs.api.spec.DvsRestoreActionSpec';
      configData: number[];
      infoSpec: com.vmware.vsphere.client.dvs.api.spec.DvsImportInfoSpec;
      restorePortgroups: boolean;
   }
}

namespace com.vmware.vsphere.client.vm.customizations {
   export class VmCustomizationCopySpec {
      _type: string = 'com.vmware.vsphere.client.vm.customizations.VmCustomizationCopySpec';
      customizationSpecManager: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      specName: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.host.vnic.model {
   export class VnicRemoveValidationResult {
      _type: string = 'com.vmware.vsphere.client.h5.network.host.vnic.model.VnicRemoveValidationResult';
      message: string;
      messageType: any;
   }
}

namespace com.vmware.vsphere.client.h5.vapp.data {
   export class ValidateNameAndFolderSpec {
      _type: string = 'com.vmware.vsphere.client.h5.vapp.data.ValidateNameAndFolderSpec';
      locationId: string;
      name: string;
   }
}

namespace com.vmware.vsphere.client.dvs.api.switchdiagram.model {
   export class AdapterModel extends BaseSwitchDiagramComponent {
      _type: string = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.AdapterModel';
      hostName: string;
      hostValue: string;
      isSriovActiveAdapter: boolean;
      labelOne: string;
      labelTwo: string;
      pNicLinkUp: boolean;
      portStatus: com.vmware.vsphere.client.dvs.api.switchdiagram.model.PortStatus;
      showCdpInfoButton: boolean;
      title: string;
      uplinkName: string;
   }
}

namespace com.vmware.vsphere.client.dvs.api.switchdiagram.model {
   export class PhysicalAdapterModel extends AdapterModel {
      _type: string = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.PhysicalAdapterModel';
      duplex: string;
      linkSpeed: string;
   }
}

namespace com.vmware.vsphere.client.dvs.api.switchdiagram.model {
   export class SriovAdapterModel extends PhysicalAdapterModel {
      _type: string = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.SriovAdapterModel';
      physicalFunctionId: string;
   }
}

namespace com.vmware.vsphere.client.dvs {
   export class DvsConfigSpec {
      _type: string = 'com.vmware.vsphere.client.dvs.DvsConfigSpec';
      configSpec: com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$ConfigSpec;
      enableResourceManagement?: boolean;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList {
   export class DvsAddHostHierarchicalListItem {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListItem';
      id: string;
      label: string;
      parentId: string;
      type: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList {
   export class DvsAddHostHierarchicalListGroupingItem extends DvsAddHostHierarchicalListItem {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListGroupingItem';
      isOnThisSwitch: boolean;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList {
   export class DvsAddHostHierarchicalListPnicItem extends DvsAddHostHierarchicalListItem {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListPnicItem';
      device: string;
      hostRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      iconClass: string;
      inUseBySwitch: string;
      isAssigned: boolean;
      isStandardSwitch: boolean;
      onOtherSwitchesItemId: string;
      onThisSwitchItemId: string;
      orderInList: number;
      uplink: string;
      uplinkPortgroup: com.vmware.vsphere.client.h5.network.dvs.addhost.model.DvPortgroupModel;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList {
   export class DvsAddHostHierarchicalListVmItem extends DvsAddHostHierarchicalListItem {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListVmItem';
      hostRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      iconClass: string;
      isOrphaned: boolean;
      isReassigned: boolean;
      nicCount: number;
      vmRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList {
   export class DvsAddHostHierarchicalListVmVnicItem extends DvsAddHostHierarchicalListItem {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListVmVnicItem';
      changeVersion: string;
      destinationPortgroup: string;
      destinationPortgroupRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      hostRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      iconClass: string;
      key: number;
      showWarning: boolean;
      sourcePortgroup: string;
      vmRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList {
   export class DvsAddHostHierarchicalListHostItem extends DvsAddHostHierarchicalListItem {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListHostItem';
      hostRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      iconClass: string;
   }
}

namespace com.vmware.vsphere.client.h5.spbm.data {
   export class SpbmComplianceFailure {
      _type: string = 'com.vmware.vsphere.client.h5.spbm.data.SpbmComplianceFailure';
      currentValue: string;
      expectedValue: string;
      propertyName: string;
   }
}

namespace com.vmware.vsphere.client.dvs.api.switchdiagram.model {
   export class GraphRelation {
      _type: string = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.GraphRelation';
      destinationId: string;
      sourceId: string;
   }
}

namespace com.vmware.vsphere.client.vm {
   export class VmCloneSpec {
      _type: string = 'com.vmware.vsphere.client.vm.VmCloneSpec';
      cloneSpec: com.vmware.vim.binding.vim.vm.CloneSpec;
      folder: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      name: string;
      podSelectionSpec: com.vmware.vim.binding.vim.storageDrs.PodSelectionSpec;
      testsToRun: any;
      vm: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      vmSpec: any;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.resmgmt.systemtraffic.model {
   export class DvsSystemTrafficListItemData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.systemtraffic.model.DvsSystemTrafficListItemData';
      key: string;
      limit: number;
      reservation: number;
      sharesLevel: string;
      sharesValue: number;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.common.model {
   export class DvpgMonitoringPolicyModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgMonitoringPolicyModel';
      ipfixEnabled: boolean;
      ipfixSupported: boolean;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.ports.model {
   export class DvsPortMonitoringPolicyModel extends com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgMonitoringPolicyModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortMonitoringPolicyModel';
      ipfixOverrided: boolean;
   }
}

namespace com.vmware.vsphere.client.h5.spbm.data.datastore {
   export class DatastoreCapabilityProfilesCapacityData {
      _type: string = 'com.vmware.vsphere.client.h5.spbm.data.datastore.DatastoreCapabilityProfilesCapacityData';
      hasViewPrivilege: boolean;
      profilesData: any;
   }
}

namespace com.vmware.vsphere.client.spbm.data.profile.content {
   export class MessageItem {
      _type: string = 'com.vmware.vsphere.client.spbm.data.profile.content.MessageItem';
      message: string;
      messageType: any;
   }
}

namespace com.vmware.vsphere.client.dvs.api.spec {
   export class DvPortgroupQueryRestoreSpec {
      _type: string = 'com.vmware.vsphere.client.dvs.api.spec.DvPortgroupQueryRestoreSpec';
      fileConfig?: number[];
      fromMemory: boolean;
   }
}

namespace com.vmware.vsphere.client.spbm.spec {
   export class CheckVmRollupComplianceSpec {
      _type: string = 'com.vmware.vsphere.client.spbm.spec.CheckVmRollupComplianceSpec';
   }
}

namespace com.vmware.vsphere.client.h5.network {
   export class MoveNetworkSpec {
      _type: string = 'com.vmware.vsphere.client.h5.network.MoveNetworkSpec';
      entities: com.vmware.vim.binding.vmodl.ManagedObjectReference[];
   }
}

namespace com.vmware.vsphere.client.dvs.api.spec {
   export class DvsImportInfoSpec {
      _type: string = 'com.vmware.vsphere.client.dvs.api.spec.DvsImportInfoSpec';
      annotation: string;
      differentContextKey: boolean;
      dvsName: string;
      dvsVersion: string;
      lagCount: number;
      nameAlreadyExists: boolean;
      newDvsName: string;
      nrpCount: number;
      portgroupCount: number;
      uplinksCount: number;
   }
}

namespace com.vmware.vsphere.client.dvpg.api {
   export class HostNetworkDetailsData$PortPolicy$TeamingAndFailover {
      _type: string = 'com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData$PortPolicy$TeamingAndFailover';
      activeAdapters: string[];
      failback: boolean;
      failureDetection: boolean;
      loadBalancing: string;
      notifySwitches: boolean;
      standByAdapters: string[];
      unusedAdapters: string[];
   }
}

namespace com.vmware.vsphere.client.h5.storage.data.datastore {
   export class StorageContainerInfoItem {
      _type: string = 'com.vmware.vsphere.client.h5.storage.data.datastore.StorageContainerInfoItem';
      capacity: string;
      datastoreExists: boolean;
      existingDatastoreName: string;
      identifier: string;
      info: any;
      name: string;
      storageArrays: string;
      storageProviders: string;
   }
}

namespace com.vmware.vsphere.client.h5.vm.data {
   export class CustomizeGosSpecData {
      _type: string = 'com.vmware.vsphere.client.h5.vm.data.CustomizeGosSpecData';
      compatibility: com.vmware.vsphere.client.h5.vm.data.CustomizeGosSpecData$Compatibility;
      guestFullName: string;
      guestId: string;
      guestType: string;
      pendingCustomization: boolean;
      relevantSpecs: com.vmware.vim.binding.vim.CustomizationSpecInfo[];
      vcReadSpecPrivGranted: boolean;
      vmCustomizePrivGranted: boolean;
   }
}

namespace com.vmware.vsphere.client.vm {
   export class VmCustomizationValidationResult {
      _type: string = 'com.vmware.vsphere.client.vm.VmCustomizationValidationResult';
      areTypicalNetworkingSettingsUsed: boolean;
      hasUnknownIpv4TypeGen: boolean;
      hasUnknownIpv6TypeGen: boolean;
      hasUnknownNameTypeGen: boolean;
      info: com.vmware.vim.binding.vim.CustomizationSpecInfo;
      isNetworkCardsCountTheSame: boolean;
      isPublicKeyTheSame: boolean;
      spec: com.vmware.vim.binding.vim.vm.customization.Specification;
      specInfoName: string;
   }
}

namespace com.vmware.vsphere.client.h5.spbm.data {
   export class StorageProfileContainerCompatibilityData$StoragePodContainerCompatibilityInfo extends StorageProfileContainerCompatibilityData$StorageContainerCompatibilityInfo {
      _type: string = 'com.vmware.vsphere.client.h5.spbm.data.StorageProfileContainerCompatibilityData$StoragePodContainerCompatibilityInfo';
      datastoreCompatibility: com.vmware.vsphere.client.h5.spbm.data.StorageProfileContainerCompatibilityData$StorageContainerCompatibilityInfo[];
      isStoragePod: boolean;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model {
   export class DvpgTrafficRuleModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgTrafficRuleModel';
      action: string;
      direction: string;
      displaySequence: number;
      dscpTagSelected: boolean;
      dscpTagValue: string;
      ipQualifierModel: com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgIpQualifierModel;
      key: string;
      macQualifierModel: com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgMacQualifierModel;
      name: string;
      qosTagSelected: boolean;
      qosTagValue: string;
      sequence: number;
      systemQualifierModel: com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgSystemQualifierModel;
   }
}

namespace com.vmware.vsphere.client.dvpg.api {
   export class HostNetworkDetailsData$IpProperties {
      _type: string = 'com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData$IpProperties';
      dnsServers: string[];
      formattedIpv6Addresses: string[];
      hasIpv4Settings: boolean;
      hasIpv6Settings: boolean;
      ipv4Address: string;
      ipv4DefaultGateway: string;
      ipv4DhcpEnabled: boolean;
      ipv6AutoConfigEnabled: boolean;
      ipv6DefaultGateway: string;
      ipv6DhcpEnabled: boolean;
      shouldShowDnsServers: boolean;
      subnetMask: string;
   }
}

namespace com.vmware.vsphere.client.dvs.api.spec {
   export class DvsPortsStatisticsSpec {
      _type: string = 'com.vmware.vsphere.client.dvs.api.spec.DvsPortsStatisticsSpec';
      bytesInBroadcast: string;
      bytesInMulticast: string;
      bytesInUnicast: string;
      bytesOutBroadcast: string;
      bytesOutMulticast: string;
      bytesOutUnicast: string;
      isErrorFound: boolean;
      packetsInBroadcast: number;
      packetsInDropped: number;
      packetsInException: number;
      packetsInMulticast: number;
      packetsInUnicast: number;
      packetsOutBroadcast: number;
      packetsOutDropped: number;
      packetsOutException: number;
      packetsOutMulticast: number;
      packetsOutUnicast: number;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.upgrade.model {
   export class UpgradeLacpDvsData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.upgrade.model.UpgradeLacpDvsData';
      dvsName: string;
      hostCount: number;
      lagConfig: com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$LacpGroupConfig;
      portgroupCount: number;
      prerequisiteCheckResults: com.vmware.vsphere.client.dvs.api.featureupgrade.ValidationCheckResult[];
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model {
   export class DvpgIpAddressModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgIpAddressModel';
      ipAddress: string;
      ipAddressType: string;
      operation: number;
      prefixLength: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.ports.model {
   export class DvsPortFailoverPolicyModel extends com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgFailoverPolicyModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortFailoverPolicyModel';
      overrideOptions: com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortFailoverPolicyModel$OverrideOptions;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model {
   export class DvsNetworkResourcePoolPortgroupsListItemData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsNetworkResourcePoolPortgroupsListItemData';
      name: string;
      networkProtocolProfile: string;
      numPorts: string;
      numVms: string;
      pgRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      portBinding: string;
      vlan: string;
   }
}

namespace com.vmware.vsphere.client.dvs.api.switchdiagram.model {
   export class SriovPortModel extends PortModel {
      _type: string = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.SriovPortModel';
      physicalFunctionId: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.ports.model {
   export class DvsPortRemoveTrafficRuleSpec {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortRemoveTrafficRuleSpec';
      portKey: string;
      ruleKey: string;
   }
}

namespace com.vmware.vsphere.client.storage.adapters {
   export class InternetScsiStorageAdapterAuthenticationUpdateSpec {
      _type: string = 'com.vmware.vsphere.client.storage.adapters.InternetScsiStorageAdapterAuthenticationUpdateSpec';
      device: string;
      properties: com.vmware.vim.binding.vim.host.InternetScsiHba$AuthenticationProperties;
      targetSet: com.vmware.vim.binding.vim.host.InternetScsiHba$TargetSet;
   }
}

namespace com.vmware.vsphere.client.h5.vm.model {
   export class VmEditEvcData {
      _type: string = 'com.vmware.vsphere.client.h5.vm.model.VmEditEvcData';
      evcModesByVendor: { [index: string]: com.vmware.vsphere.client.h5.vm.model.SupportedEvcMode[] };
      key: string;
      vendor: com.vmware.vsphere.client.h5.vm.model.VmEvcData$VmEvcVendor;
   }
}

namespace com.vmware.vsphere.client.spbm.data.profile.content {
   export class CategoryData {
      _type: string = 'com.vmware.vsphere.client.spbm.data.profile.content.CategoryData';
      collapsable: boolean;
      headerTitle: string;
      properties: com.vmware.vsphere.client.spbm.data.profile.content.PropertyData[];
   }
}

namespace com.vmware.vsphere.client.h5.spbm.data {
   export class VmStorageProfilesData {
      _type: string = 'com.vmware.vsphere.client.h5.spbm.data.VmStorageProfilesData';
      complianceFailures: com.vmware.vsphere.client.h5.spbm.data.SpbmComplianceFailure[];
      complianceStatus: string;
      lastChecked: Date;
      name: string;
      storagePolicy: string;
      type: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.manage.model {
   export class ManagePortgroupsWizardModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.manage.model.ManagePortgroupsWizardModel';
      dvsName: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model {
   export class DvpgMacQualifierModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgMacQualifierModel';
      destinationMacAddress: com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgMacAddressModel;
      enableMacQualifier: boolean;
      protocol: com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgProtocolModel;
      sourceMacAddress: com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgMacAddressModel;
      vlanId: string;
      vlanOperation: number;
   }
}

namespace com.vmware.vsphere.client.storage {
   export class ProtocolEndpointData {
      _type: string = 'com.vmware.vsphere.client.storage.ProtocolEndpointData';
      accessible: boolean;
      lunNumber: number;
      ownerPlugin: string;
      protocolEndpoint: com.vmware.vim.binding.vim.host.ProtocolEndpoint;
      transport: any;
      underlyingLun: com.vmware.vim.binding.vim.host.ScsiLun;
   }
}

namespace com.vmware.vsphere.client.hostprofile.data.h5 {
   export class UpdateHostCustomizationsSpec {
      _type: string = 'com.vmware.vsphere.client.hostprofile.data.h5.UpdateHostCustomizationsSpec';
      currentHostCustomizations: any;
      hostProfile: com.vmware.vim.binding.vmodl.ManagedObjectReference;
   }
}

namespace com.vmware.vsphere.client.h5.spbm.data {
   export class VmStorageProfiles {
      _type: string = 'com.vmware.vsphere.client.h5.spbm.data.VmStorageProfiles';
      hasSpbmViewPrivilege: boolean;
      profilesDataList: com.vmware.vsphere.client.h5.spbm.data.VmStorageProfilesData[];
   }
}

namespace com.vmware.vsphere.client.h5.vapp.data {
   export class ValidateDestinationSpec {
      _type: string = 'com.vmware.vsphere.client.h5.vapp.data.ValidateDestinationSpec';
      destinationId: string;
      sourceVappId: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.portmirroring.model {
   export class DvsPortMirroringSourcePorts {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.DvsPortMirroringSourcePorts';
      sourcePortData: any;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model {
   export class DvsNetworkResourcePoolsOverviewData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsNetworkResourcePoolsOverviewData';
      capacity: number;
      vmTrafficReservation: number;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.addvnics.model {
   export class DvpgHostVnicServiceModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.addvnics.model.DvpgHostVnicServiceModel';
      incompatibleHosts: com.vmware.vsphere.client.h5.network.dvportgroup.addvnics.model.DvpgHostInfoModel[];
      isEnabled: boolean;
      isSelected: boolean;
      isSupported: boolean;
      key: string;
      name: string;
      tooltip: string;
   }
}

namespace com.vmware.vsphere.client.h5.storage.data.datastore {
   export class DatastoreLocatorData {
      _type: string = 'com.vmware.vsphere.client.h5.storage.data.datastore.DatastoreLocatorData';
      capacity: number;
      diskFormatsAvailable: any[];
      formattedType: string;
      freeSpace: number;
      hardwareAcceleration: string;
      multipleHostAccess: boolean;
      name: string;
      parentStoragePod: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      provisionedSpace: number;
      storageRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      thinProvisioningSupported: boolean;
      type: string;
      vStorageSupported: boolean;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.ports.model {
   export class DvsPortTrafficShapingPolicyModel extends com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgTrafficShapingPolicyModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortTrafficShapingPolicyModel';
      inShapingOverrided: boolean;
      outShapingOverrided: boolean;
   }
}

namespace com.vmware.vsphere.client.h5.storage.data.datastore.vvol {
   export class VvolStorageProvidersViewData$StorageProviderItem {
      _type: string = 'com.vmware.vsphere.client.h5.storage.data.datastore.vvol.VvolStorageProvidersViewData$StorageProviderItem';
      formattedName: string;
      formattedPriority: string;
      priority: number;
      state: string;
      url: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.upgrade.model {
   export class UpgradeDvsData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.upgrade.model.UpgradeDvsData';
      currentLacpVersion: string;
      currentNiocVersion: number;
      currentSwitchSpec: com.vmware.vim.binding.vim.dvs.ProductSpec;
      dvsName: string;
      isLacpConfigured: boolean;
      isLacpSupported: boolean;
      lacpPrerequisiteCheckResults: com.vmware.vsphere.client.dvs.api.featureupgrade.ValidationCheckResult[];
      lagConfig: com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$LacpGroupConfig;
      niocPrerequisiteCheckResults: com.vmware.vsphere.client.dvs.api.featureupgrade.ValidationCheckResult[];
      niocUpgradeStatistics: com.vmware.vsphere.client.dvs.api.spec.DvsResControlUpgradeStatistics;
      supportedSwitchSpec: com.vmware.vim.binding.vim.dvs.ProductSpec[];
   }
}

namespace com.vmware.vsphere.client.library.specs {
   export class ComputeResourceSubscriptionSpec {
      _type: string = 'com.vmware.vsphere.client.library.specs.ComputeResourceSubscriptionSpec';
      targetRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
   }
}

namespace com.vmware.vsphere.client.h5.network.vm.migrate.specs {
   export class MigrateVmNetworkingDragAndDropSpec {
      _type: string = 'com.vmware.vsphere.client.h5.network.vm.migrate.specs.MigrateVmNetworkingDragAndDropSpec';
      destinationNetworkId: string;
      forceOperation: boolean;
      sourceNetworkId: string;
      vmIds: string[];
   }
}

namespace com.vmware.vsphere.client.hostprofile.data {
   export class HostCustomizationImportException {
      _type: string = 'com.vmware.vsphere.client.hostprofile.data.HostCustomizationImportException';
      errorMessages: any;
      warningMessages: any;
   }
}

namespace com.vmware.vsphere.client.network.switchdiagram.api.spec {
   export class VirtualMachineSectionsModel {
      _type: string = 'com.vmware.vsphere.client.network.switchdiagram.api.spec.VirtualMachineSectionsModel';
      externalAdaptersBySwitch: { [index: string]: com.vmware.vsphere.client.dvs.api.switchdiagram.model.SriovAdapterModel[] };
      portModelsByPortgroup: { [index: string]: com.vmware.vsphere.client.dvs.api.switchdiagram.model.PortModel[] };
   }
}

namespace com.vmware.vsphere.client.dvs.api.spec {
   export class DvpgRestoreSettingRepresentationSpec {
      _type: string = 'com.vmware.vsphere.client.dvs.api.spec.DvpgRestoreSettingRepresentationSpec';
      key: string;
      value: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.wizard {
   export class DvsAddSpanSessionWizardData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.wizard.DvsAddSpanSessionWizardData';
      configVersion: string;
      dvPortMirrorSupported: boolean;
      dvsName: string;
      erspanSupported: boolean;
      mirrorNetstackSupported: boolean;
      remoteDestSupported: boolean;
      remoteSourceSupported: boolean;
      sessionName: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.resmgmt.systemtraffic.model {
   export class DvsSystemTrafficEditDialogData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.systemtraffic.model.DvsSystemTrafficEditDialogData';
      availableBandwidth: number;
      configVersion: string;
      key: string;
      limit: number;
      minLinkSpeed: number;
      reservation: number;
      sharesLevel: string;
      sharesValue: number;
   }
}

namespace com.vmware.vsphere.client.vm {
   export class VmCustomizationSpecification {
      _type: string = 'com.vmware.vsphere.client.vm.VmCustomizationSpecification';
      info: com.vmware.vim.binding.vim.CustomizationSpecInfo;
      spec: com.vmware.vim.binding.vim.vm.customization.Specification;
   }
}

namespace com.vmware.vsphere.client.h5.spbm.data.datastore {
   export class DatastoreResourceProfilesData {
      _type: string = 'com.vmware.vsphere.client.h5.spbm.data.datastore.DatastoreResourceProfilesData';
      hasViewPrivilege: boolean;
      namespaceCapabilityMetadata: any;
      profiles: com.vmware.vim.binding.pbm.profile.Profile[];
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.portbrowser {
   export class DvsSpanSessionPortBrowserPortData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.portbrowser.DvsSpanSessionPortBrowserPortData';
      connectedEntity: string;
      connectedEntityType: string;
      host: string;
      mac: string;
      name: string;
      portId: string;
      portgroup: string;
      state: string;
      vlan: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.upgrade.model {
   export class UpgradeNiocDvsData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.upgrade.model.UpgradeNiocDvsData';
      dvsName: string;
      niocUpgradeStatistics: com.vmware.vsphere.client.dvs.api.spec.DvsResControlUpgradeStatistics;
      prerequisiteCheckResults: com.vmware.vsphere.client.dvs.api.featureupgrade.ValidationCheckResult[];
   }
}

namespace com.vmware.vsphere.client.network {
   export class GhostProxySwitchRemoveSpec {
      _type: string = 'com.vmware.vsphere.client.network.GhostProxySwitchRemoveSpec';
      dvsUuid: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.host.netstack.model {
   export class NetStackConfigItem {
      _type: string = 'com.vmware.vsphere.client.h5.network.host.netstack.model.NetStackConfigItem';
      alternateDns: string;
      congestionControlAlgorithm: string;
      defaultGateway: string;
      domainName: string;
      hasVnicWithIpv4Settings: boolean;
      hasVnicWithIpv6Settings: boolean;
      hostName: string;
      ipV6DefaultGateway: string;
      ipv4RouteTableEntries: any;
      ipv6RouteTableEntries: any;
      isDefaultNetStack: boolean;
      isDhcpEnabled: boolean;
      isIpv6Enabled: boolean;
      isRuntimeInfoActive: boolean;
      isSystemNetStack: boolean;
      maxNumberOfConnections: number;
      name: string;
      preferredDns: string;
      runtimeMaxNumberOfConnections: number;
      searchDomains: string;
      selectedVnic: string;
      vnicsWithDhcpEnabled: string[];
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.wizard {
   export class DvsSpanSessionNameSpec {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.wizard.DvsSpanSessionNameSpec';
      name: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter {
   export class DvpgAddTrafficRuleSpec {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgAddTrafficRuleSpec';
      newRule: com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgTrafficRuleModel;
   }
}

namespace com.vmware.vsphere.client.h5.storage.spec {
   export class UpdateVmfsUnmapBandwidthSpec {
      _type: string = 'com.vmware.vsphere.client.h5.storage.spec.UpdateVmfsUnmapBandwidthSpec';
      datastore: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      unmapBandwidthSpec: com.vmware.vim.binding.vim.host.VmfsVolume$UnmapBandwidthSpec;
      vmfsUuid: string;
   }
}

namespace com.vmware.vsphere.client.dvs.api.switchdiagram.model {
   export class PortChannelModel extends BaseSwitchDiagramComponent {
      _type: string = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.PortChannelModel';
      adapters: com.vmware.vsphere.client.dvs.api.switchdiagram.model.AdapterModel[];
      title: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.resmgmt.systemtraffic.model {
   export class DvsSystemTrafficOverviewData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.systemtraffic.model.DvsSystemTrafficOverviewData';
      excludedPnics: number;
      minPnicSpeed: number;
      niocEnabled: boolean;
      niocVersion: number;
      numPnics: number;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter {
   export class DvpgTrafficFilterModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgTrafficFilterModel';
      isTrafficRulesetEnabled: boolean;
      rules: com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgTrafficFilterRule[];
   }
}

namespace com.vmware.vsphere.client.h5.network.dvportgroup.common.model {
   export class DvpgResourceAllocationPolicyModel$NetworkResourcePool {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgResourceAllocationPolicyModel$NetworkResourcePool';
      key: string;
      name: string;
   }
}

namespace com.vmware.vsphere.client.h5.vm.data {
   export class RegisterVmSpec {
      _type: string = 'com.vmware.vsphere.client.h5.vm.data.RegisterVmSpec';
      folderRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      isVmTemplate: boolean;
      targetRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      vmName: string;
      vmPath: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList {
   export class DvsAddHostHierarchicalListVnicItem extends DvsAddHostHierarchicalListItem {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListVnicItem';
      destinationPortgroup: string;
      device: string;
      hostRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      iconClass: string;
      inUseBySwitch: string;
      isOnManagementStack: boolean;
      isOpaqueSwitch: boolean;
      isStandardPortgroup: boolean;
      isStandardSwitch: boolean;
      onThisSwitchItemId: string;
      orderInList: number;
      portgroupRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      sourcePortgroup: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.healthcheck.model {
   export class DvsHealthCheckData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.healthcheck.model.DvsHealthCheckData';
      disconnectedHostsCount: number;
      dvsHealthCheckItems: com.vmware.vsphere.client.h5.network.dvs.healthcheck.model.DvsHealthCheckItemData[];
      ipHashMismatchCount: number;
      mtuMismatchCount: number;
      normalOverallHealthCheckStatus: boolean;
      outOfSyncHostsCount: number;
      overallHealthCheckStatusIcon: string;
      overallWarningsCount: number;
      teamingAndFailoverHealthCheckEnabled: boolean;
      unsupportedVlansCount: number;
      vlanAndMtuHealthCheckEnabled: boolean;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model {
   export class DvsNetworkResourcePoolsListItemData {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsNetworkResourcePoolsListItemData';
      configVersion: string;
      key: string;
      name: string;
      reservation: number;
   }
}

namespace com.vmware.vsphere.client.vm.customizations {
   export class VmCustomizationRemovalSpec {
      _type: string = 'com.vmware.vsphere.client.vm.customizations.VmCustomizationRemovalSpec';
      specName: string;
   }
}

namespace com.vmware.vsphere.client.vm.customizations {
   export class VmCustomizationImportValidationSpec {
      _type: string = 'com.vmware.vsphere.client.vm.customizations.VmCustomizationImportValidationSpec';
      customizationSpecManager: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      customizationSpecText: string;
   }
}

namespace com.vmware.vsphere.client.dvs.api.switchdiagram.model {
   export class LagModel extends BaseSwitchDiagramComponent {
      _type: string = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.LagModel';
      key: string;
      portChannels: com.vmware.vsphere.client.dvs.api.switchdiagram.model.PortChannelModel[];
      portStatus: com.vmware.vsphere.client.dvs.api.switchdiagram.model.PortStatus;
      title: string;
   }
}

namespace com.vmware.vsphere.client.h5.spbm.data {
   export class VmComplianceSummaryData {
      _type: string = 'com.vmware.vsphere.client.h5.spbm.data.VmComplianceSummaryData';
      complianceStatus: string;
      hasSpbmViewPrivilege: boolean;
      oldestCheckTime: Date;
      replicationGroupNames: string[];
      storagePolicyNames: string[];
   }
}

namespace com.vmware.vsphere.client.h5.vm.model.migration {
   export class VmMigrationFlowValidationSpec {
      _type: string = 'com.vmware.vsphere.client.h5.vm.model.migration.VmMigrationFlowValidationSpec';
      vms: string[];
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.imports {
   export class DvpgRestoreVlanPolicyModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.imports.DvpgRestoreVlanPolicyModel';
      policy: com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$VlanSpec;
      privateVlanEntries: com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$PvlanMapEntry[];
      privateVlanEntry: com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$PvlanMapEntry;
      vlanId: string;
      vlanIdRanges: string;
      vlanType: string;
   }
}

namespace com.vmware.vsphere.client.vsancommon.data {
   export class VsanCreateCostData {
      _type: string = 'com.vmware.vsphere.client.vsancommon.data.VsanCreateCostData';
      cost: any;
      profileId: string;
      size: number;
   }
}

namespace com.vmware.vsphere.client.dvs.api.spec {
   export class DvsResControlUpgradeSpec {
      _type: string = 'com.vmware.vsphere.client.dvs.api.spec.DvsResControlUpgradeSpec';
      migrateShares: boolean;
   }
}

namespace com.vmware.vsphere.client.dvs.api.switchdiagram.model {
   export class SwitchDiagramFilter {
      _type: string = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.SwitchDiagramFilter';
      dvpgValues: string[];
      hostValues: string[];
      includeOnlyNonEmptyPortgroups: boolean;
      vmValues: string[];
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.ports.model {
   export class DvsPortEditTrafficFilterSpec {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortEditTrafficFilterSpec';
      isTrafficRulesetEnabled: boolean;
      isTrafficRulesetOverwritten: boolean;
      portKey: string;
      rules: com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgEditRuleSequenceSpec[];
   }
}

namespace com.vmware.vsphere.client.dvs.api.switchdiagram.model.details {
   export class VmVnicModel {
      _type: string = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.details.VmVnicModel';
      adapterName: string;
      adapterType: string;
      guestOsMtuChange: boolean;
      macAddress: string;
      physicalFunctionDeviceId: string;
      physicalFunctionId: string;
      portgroupName: string;
      resourceAllocation: com.vmware.vsphere.client.dvs.api.switchdiagram.model.details.VmVnicResourceAllocationModel;
      sriovCapable: boolean;
      virtualFunctionId: string;
   }
}

namespace com.vmware.vsphere.client.dvs.api.switchdiagram.model {
   export class SwitchDiagramModel extends BaseSwitchDiagramComponent {
      _type: string = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.SwitchDiagramModel';
      dvsUuid: string;
      loadedHosts: string[];
      portGroups: com.vmware.vsphere.client.dvs.api.switchdiagram.model.PortGroupModel[];
      relations: com.vmware.vsphere.client.dvs.api.switchdiagram.model.GraphRelation[];
      uplinkPortGroups: com.vmware.vsphere.client.dvs.api.switchdiagram.model.UplinkPortGroupModel[];
   }
}

namespace com.vmware.vsphere.client.hostprofile.data.h5 {
   export class ValidateHostCustomizationsSpec extends com.vmware.vsphere.client.hostprofile.data.DeferredHostSettingsSpec {
      _type: string = 'com.vmware.vsphere.client.hostprofile.data.h5.ValidateHostCustomizationsSpec';
   }
}

namespace com.vmware.vsphere.client.h5.network.ippools.data {
   export class ValidateNameAndNetworksSpec {
      _type: string = 'com.vmware.vsphere.client.h5.network.ippools.data.ValidateNameAndNetworksSpec';
      datacenterRef: com.vmware.vim.binding.vmodl.ManagedObjectReference;
      ipPoolId: number;
      name: string;
      networkRefs: com.vmware.vim.binding.vmodl.ManagedObjectReference[];
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.ports.model {
   export class DvsPortPropertiesModel {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortPropertiesModel';
      description: string;
      name: string;
      networkResourcePools: com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortPropertiesModel$NetworkResourcePoolData[];
      selectedResourcePool: com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortPropertiesModel$NetworkResourcePoolData;
   }
}

namespace com.vmware.vsphere.client.dvs.api.spec {
   export class LacpUpgradeSpec {
      _type: string = 'com.vmware.vsphere.client.dvs.api.spec.LacpUpgradeSpec';
      lag: com.vmware.vim.binding.vim.dvs.VmwareDistributedVirtualSwitch$LacpGroupConfig;
   }
}

namespace com.vmware.vsphere.client.dvs.api.spec {
   export class DvsImportInfoWrapperSpec {
      _type: string = 'com.vmware.vsphere.client.dvs.api.spec.DvsImportInfoWrapperSpec';
      dvsImportInfoSpec: com.vmware.vsphere.client.dvs.api.spec.DvsImportInfoSpec;
      error: string;
   }
}

namespace com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model {
   export class DvsVmVnicResourceSettingsSpec {
      _type: string = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsVmVnicResourceSettingsSpec';
      limit: number;
      reservation: number;
      sharesLevel: string;
      sharesValue: number;
      vnicSpecs: any;
   }
}

namespace com.vmware.vsphere.client.spbm.data {
   export class VmRollupComplianceSummayData {
      _type: string = 'com.vmware.vsphere.client.spbm.data.VmRollupComplianceSummayData';
      entityComplianceResults: any;
      oldestCheckTime?: Date;
      profileMismatch: boolean;
      rollupComplianceStatus: string;
      vm: com.vmware.vim.binding.vmodl.ManagedObjectReference;
   }
}

namespace com.vmware.vsphere.client.network.host.api {
   export class HostPnicDetailsItem {
      _type: string = 'com.vmware.vsphere.client.network.host.api.HostPnicDetailsItem';
      actualSpeed: number;
      adapterName: string;
      areNetworkHintsRetrieved: boolean;
      cdpInfo: any;
      configuredSpeed: number;
      deviceName: string;
      driverName: string;
      isActualSpeedFullDuplex: boolean;
      isConfiguredSpeedFullDuplex: boolean;
      isConnected: boolean;
      lldpInfo: any;
      location: string;
      networkHints: string[];
      resourcePoolSchedulerAllowed: boolean;
      resourcePoolSchedulerDisallowedReasons: string[];
      sriovInfo: any;
   }
}

namespace com.vmware.vsphere.client.dvs.api.common {
   export type ValidationResultType = "pass" | "fail" | "warning";
}

namespace com.vmware.vsphere.client.dvs.api.switchdiagram.model {
   export type PortStatus = "NA" | "CONNECTED" | "DISCONNECTED" | "BLOCKED" | "ERROR";
}

namespace com.vmware.vsphere.client.h5.vm.data {
   export type CustomizeGosSpecData$Compatibility = "MISSING_WINDOWS_CUSTOMIZATION" | "MISSING_LINUX_CUSTOMIZATION" | "UNCUSTOMIZABLE_GUEST" | "CUSTOMIZATION_RESOURCES_VALID";
}

namespace com.vmware.vsphere.client.h5.vm.model {
   export type VmEvcData$VmEvcVendor = "NONE" | "AMD" | "INTEL" | "CUSTOM";
}

namespace com.vmware.vsphere.client.vm.migration {
   export type MigrationReasonCode = "hostInMaintenance" | "smpFt" | "ftSecondary" | "ftPrimaryNotInCorrectState" | "noAvailableVdcs" | "vdcMigrationNotSupported" | "noPrivilege";
}

namespace com.vmware.vsphere.client.vm.migration {
   export type MigrationType = "host" | "datastore" | "hostAndDatastore";
}

namespace com.vmware.vsphere.client.vm.tools {
   export type VmToolsInstallerSpec$OperationType = "mountInstaller" | "unmountInstaller" | "upgradeTools";
}
