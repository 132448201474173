// Generated using j2ts version null on 2019-11-18 10:07:25.
var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var host;
                        (function (host) {
                            var netstack;
                            (function (netstack) {
                                var model;
                                (function (model) {
                                    var NetStackReconfigureSpec$DnsReconfigureSpec = (function () {
                                        function NetStackReconfigureSpec$DnsReconfigureSpec() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.host.netstack.model.NetStackReconfigureSpec$DnsReconfigureSpec';
                                        }
                                        return NetStackReconfigureSpec$DnsReconfigureSpec;
                                    }());
                                    model.NetStackReconfigureSpec$DnsReconfigureSpec = NetStackReconfigureSpec$DnsReconfigureSpec;
                                })(model = netstack.model || (netstack.model = {}));
                            })(netstack = host.netstack || (host.netstack = {}));
                        })(host = network.host || (network.host = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var spec;
                        (function (spec) {
                            var DvsResControlUpgradeStatistics = (function () {
                                function DvsResControlUpgradeStatistics() {
                                    this._type = 'com.vmware.vsphere.client.dvs.api.spec.DvsResControlUpgradeStatistics';
                                }
                                return DvsResControlUpgradeStatistics;
                            }());
                            spec.DvsResControlUpgradeStatistics = DvsResControlUpgradeStatistics;
                        })(spec = api.spec || (api.spec = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vm;
                    (function (vm) {
                        var model;
                        (function (model) {
                            var migration;
                            (function (migration) {
                                var ComputeResourceTreePageSpec = (function () {
                                    function ComputeResourceTreePageSpec() {
                                        this._type = 'com.vmware.vsphere.client.h5.vm.model.migration.ComputeResourceTreePageSpec';
                                    }
                                    return ComputeResourceTreePageSpec;
                                }());
                                migration.ComputeResourceTreePageSpec = ComputeResourceTreePageSpec;
                            })(migration = model.migration || (model.migration = {}));
                        })(model = vm.model || (vm.model = {}));
                    })(vm = h5.vm || (h5.vm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var storage;
                    (function (storage) {
                        var spec;
                        (function (spec) {
                            var QueryReclamationOptionsSpec = (function () {
                                function QueryReclamationOptionsSpec() {
                                    this._type = 'com.vmware.vsphere.client.h5.storage.spec.QueryReclamationOptionsSpec';
                                }
                                return QueryReclamationOptionsSpec;
                            }());
                            spec.QueryReclamationOptionsSpec = QueryReclamationOptionsSpec;
                        })(spec = storage.spec || (storage.spec = {}));
                    })(storage = h5.storage || (h5.storage = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var spec;
                        (function (spec) {
                            var DvsHostRemoveSpec = (function () {
                                function DvsHostRemoveSpec() {
                                    this._type = 'com.vmware.vsphere.client.dvs.api.spec.DvsHostRemoveSpec';
                                }
                                return DvsHostRemoveSpec;
                            }());
                            spec.DvsHostRemoveSpec = DvsHostRemoveSpec;
                        })(spec = api.spec || (api.spec = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var host;
                (function (host) {
                    var SmartCardAuthSettingsSpec = (function () {
                        function SmartCardAuthSettingsSpec() {
                            this._type = 'com.vmware.vsphere.client.host.SmartCardAuthSettingsSpec';
                        }
                        return SmartCardAuthSettingsSpec;
                    }());
                    host.SmartCardAuthSettingsSpec = SmartCardAuthSettingsSpec;
                })(host = client.host || (client.host = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var vm;
                (function (vm) {
                    var customizations;
                    (function (customizations) {
                        var VmCustomizationSpec = (function () {
                            function VmCustomizationSpec() {
                                this._type = 'com.vmware.vsphere.client.vm.customizations.VmCustomizationSpec';
                            }
                            return VmCustomizationSpec;
                        }());
                        customizations.VmCustomizationSpec = VmCustomizationSpec;
                    })(customizations = vm.customizations || (vm.customizations = {}));
                })(vm = client.vm || (client.vm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var featureupgrade;
                        (function (featureupgrade) {
                            var ValidationCheckResult = (function () {
                                function ValidationCheckResult() {
                                    this._type = 'com.vmware.vsphere.client.dvs.api.featureupgrade.ValidationCheckResult';
                                }
                                return ValidationCheckResult;
                            }());
                            featureupgrade.ValidationCheckResult = ValidationCheckResult;
                        })(featureupgrade = api.featureupgrade || (api.featureupgrade = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var spec;
                        (function (spec) {
                            var DvsConfigSpec = (function () {
                                function DvsConfigSpec() {
                                    this._type = 'com.vmware.vsphere.client.dvs.api.spec.DvsConfigSpec';
                                }
                                return DvsConfigSpec;
                            }());
                            spec.DvsConfigSpec = DvsConfigSpec;
                        })(spec = api.spec || (api.spec = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vm;
                    (function (vm) {
                        var data;
                        (function (data) {
                            var RegisterVmCompatibilityCheckSpec = (function () {
                                function RegisterVmCompatibilityCheckSpec() {
                                    this._type = 'com.vmware.vsphere.client.h5.vm.data.RegisterVmCompatibilityCheckSpec';
                                }
                                return RegisterVmCompatibilityCheckSpec;
                            }());
                            data.RegisterVmCompatibilityCheckSpec = RegisterVmCompatibilityCheckSpec;
                        })(data = vm.data || (vm.data = {}));
                    })(vm = h5.vm || (h5.vm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var upgrade;
                            (function (upgrade) {
                                var model;
                                (function (model) {
                                    var HostCompatibilityListItem = (function () {
                                        function HostCompatibilityListItem() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.upgrade.model.HostCompatibilityListItem';
                                        }
                                        return HostCompatibilityListItem;
                                    }());
                                    model.HostCompatibilityListItem = HostCompatibilityListItem;
                                })(model = upgrade.model || (upgrade.model = {}));
                            })(upgrade = dvs.upgrade || (dvs.upgrade = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var trafficfilter;
                            (function (trafficfilter) {
                                var DvpgCloneTrafficRulesSpec = (function () {
                                    function DvpgCloneTrafficRulesSpec() {
                                        this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgCloneTrafficRulesSpec';
                                    }
                                    return DvpgCloneTrafficRulesSpec;
                                }());
                                trafficfilter.DvpgCloneTrafficRulesSpec = DvpgCloneTrafficRulesSpec;
                            })(trafficfilter = dvportgroup.trafficfilter || (dvportgroup.trafficfilter = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var hostprofile;
                (function (hostprofile) {
                    var data;
                    (function (data) {
                        var ProfileWrapper = (function () {
                            function ProfileWrapper() {
                                this._type = 'com.vmware.vsphere.client.hostprofile.data.ProfileWrapper';
                            }
                            return ProfileWrapper;
                        }());
                        data.ProfileWrapper = ProfileWrapper;
                    })(data = hostprofile.data || (hostprofile.data = {}));
                })(hostprofile = client.hostprofile || (client.hostprofile = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var create;
                            (function (create) {
                                var model;
                                (function (model) {
                                    var CreateDvPortgroupPoliciesModel = (function () {
                                        function CreateDvPortgroupPoliciesModel() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.create.model.CreateDvPortgroupPoliciesModel';
                                        }
                                        return CreateDvPortgroupPoliciesModel;
                                    }());
                                    model.CreateDvPortgroupPoliciesModel = CreateDvPortgroupPoliciesModel;
                                })(model = create.model || (create.model = {}));
                            })(create = dvportgroup.create || (dvportgroup.create = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vm;
                    (function (vm) {
                        var NameValidationResult = (function () {
                            function NameValidationResult() {
                                this._type = 'com.vmware.vsphere.client.h5.vm.NameValidationResult';
                            }
                            return NameValidationResult;
                        }());
                        vm.NameValidationResult = NameValidationResult;
                    })(vm = h5.vm || (h5.vm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var portmirroring;
                            (function (portmirroring) {
                                var model;
                                (function (model) {
                                    var portlist;
                                    (function (portlist) {
                                        var DvsSpanSessionPortListSpec = (function () {
                                            function DvsSpanSessionPortListSpec() {
                                                this._type = 'com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.portlist.DvsSpanSessionPortListSpec';
                                            }
                                            return DvsSpanSessionPortListSpec;
                                        }());
                                        portlist.DvsSpanSessionPortListSpec = DvsSpanSessionPortListSpec;
                                    })(portlist = model.portlist || (model.portlist = {}));
                                })(model = portmirroring.model || (portmirroring.model = {}));
                            })(portmirroring = dvs.portmirroring || (dvs.portmirroring = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var spec;
                        (function (spec) {
                            var DvpgImportInfoWrapperSpec = (function () {
                                function DvpgImportInfoWrapperSpec() {
                                    this._type = 'com.vmware.vsphere.client.dvs.api.spec.DvpgImportInfoWrapperSpec';
                                }
                                return DvpgImportInfoWrapperSpec;
                            }());
                            spec.DvpgImportInfoWrapperSpec = DvpgImportInfoWrapperSpec;
                        })(spec = api.spec || (api.spec = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var ports;
                            (function (ports) {
                                var model;
                                (function (model) {
                                    var DvsPortFailoverPolicyModel$OverrideOptions = (function () {
                                        function DvsPortFailoverPolicyModel$OverrideOptions() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortFailoverPolicyModel$OverrideOptions';
                                        }
                                        return DvsPortFailoverPolicyModel$OverrideOptions;
                                    }());
                                    model.DvsPortFailoverPolicyModel$OverrideOptions = DvsPortFailoverPolicyModel$OverrideOptions;
                                })(model = ports.model || (ports.model = {}));
                            })(ports = dvs.ports || (dvs.ports = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var common;
                            (function (common) {
                                var model;
                                (function (model) {
                                    var DvpgSecurityPolicyModel = (function () {
                                        function DvpgSecurityPolicyModel() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgSecurityPolicyModel';
                                        }
                                        return DvpgSecurityPolicyModel;
                                    }());
                                    model.DvpgSecurityPolicyModel = DvpgSecurityPolicyModel;
                                })(model = common.model || (common.model = {}));
                            })(common = dvportgroup.common || (dvportgroup.common = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var portmirroring;
                            (function (portmirroring) {
                                var model;
                                (function (model) {
                                    var DvsPortMirroringSpec = (function () {
                                        function DvsPortMirroringSpec() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.DvsPortMirroringSpec';
                                        }
                                        return DvsPortMirroringSpec;
                                    }());
                                    model.DvsPortMirroringSpec = DvsPortMirroringSpec;
                                })(model = portmirroring.model || (portmirroring.model = {}));
                            })(portmirroring = dvs.portmirroring || (dvs.portmirroring = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var host;
                        (function (host) {
                            var model;
                            (function (model) {
                                var GhostDvsData = (function () {
                                    function GhostDvsData() {
                                        this._type = 'com.vmware.vsphere.client.h5.network.host.model.GhostDvsData';
                                    }
                                    return GhostDvsData;
                                }());
                                model.GhostDvsData = GhostDvsData;
                            })(model = host.model || (host.model = {}));
                        })(host = network.host || (network.host = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var network;
                (function (network) {
                    var switchdiagram;
                    (function (switchdiagram) {
                        var api;
                        (function (api) {
                            var spec;
                            (function (spec) {
                                var VswitchDiagramVmsModel = (function () {
                                    function VswitchDiagramVmsModel() {
                                        this._type = 'com.vmware.vsphere.client.network.switchdiagram.api.spec.VswitchDiagramVmsModel';
                                    }
                                    return VswitchDiagramVmsModel;
                                }());
                                spec.VswitchDiagramVmsModel = VswitchDiagramVmsModel;
                            })(spec = api.spec || (api.spec = {}));
                        })(api = switchdiagram.api || (switchdiagram.api = {}));
                    })(switchdiagram = network.switchdiagram || (network.switchdiagram = {}));
                })(network = client.network || (client.network = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var addvnics;
                            (function (addvnics) {
                                var model;
                                (function (model) {
                                    var DvpgHostInfoModel = (function () {
                                        function DvpgHostInfoModel() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.addvnics.model.DvpgHostInfoModel';
                                        }
                                        return DvpgHostInfoModel;
                                    }());
                                    model.DvpgHostInfoModel = DvpgHostInfoModel;
                                })(model = addvnics.model || (addvnics.model = {}));
                            })(addvnics = dvportgroup.addvnics || (dvportgroup.addvnics = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var portmirroring;
                            (function (portmirroring) {
                                var model;
                                (function (model) {
                                    var portbrowser;
                                    (function (portbrowser) {
                                        var DvsSpanSessionPortBrowserData = (function () {
                                            function DvsSpanSessionPortBrowserData() {
                                                this._type = 'com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.portbrowser.DvsSpanSessionPortBrowserData';
                                            }
                                            return DvsSpanSessionPortBrowserData;
                                        }());
                                        portbrowser.DvsSpanSessionPortBrowserData = DvsSpanSessionPortBrowserData;
                                    })(portbrowser = model.portbrowser || (model.portbrowser = {}));
                                })(model = portmirroring.model || (portmirroring.model = {}));
                            })(portmirroring = dvs.portmirroring || (dvs.portmirroring = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vm;
                    (function (vm) {
                        var configuration;
                        (function (configuration) {
                            var WindowsTimeZone = (function () {
                                function WindowsTimeZone() {
                                    this._type = 'com.vmware.vsphere.client.h5.vm.configuration.WindowsTimeZone';
                                }
                                return WindowsTimeZone;
                            }());
                            configuration.WindowsTimeZone = WindowsTimeZone;
                        })(configuration = vm.configuration || (vm.configuration = {}));
                    })(vm = h5.vm || (h5.vm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var common;
                            (function (common) {
                                var model;
                                (function (model) {
                                    var DvpgResourceAllocationPolicyModel = (function () {
                                        function DvpgResourceAllocationPolicyModel() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgResourceAllocationPolicyModel';
                                        }
                                        return DvpgResourceAllocationPolicyModel;
                                    }());
                                    model.DvpgResourceAllocationPolicyModel = DvpgResourceAllocationPolicyModel;
                                })(model = common.model || (common.model = {}));
                            })(common = dvportgroup.common || (dvportgroup.common = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vm;
                    (function (vm) {
                        var model;
                        (function (model) {
                            var provisioning;
                            (function (provisioning) {
                                var VmCreateScheduledTaskEditDefaultsValidationSpec = (function () {
                                    function VmCreateScheduledTaskEditDefaultsValidationSpec() {
                                        this._type = 'com.vmware.vsphere.client.h5.vm.model.provisioning.VmCreateScheduledTaskEditDefaultsValidationSpec';
                                    }
                                    return VmCreateScheduledTaskEditDefaultsValidationSpec;
                                }());
                                provisioning.VmCreateScheduledTaskEditDefaultsValidationSpec = VmCreateScheduledTaskEditDefaultsValidationSpec;
                            })(provisioning = model.provisioning || (model.provisioning = {}));
                        })(model = vm.model || (vm.model = {}));
                    })(vm = h5.vm || (h5.vm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var storage;
                    (function (storage) {
                        var data;
                        (function (data) {
                            var host;
                            (function (host) {
                                var HostData = (function () {
                                    function HostData() {
                                        this._type = 'com.vmware.vsphere.client.h5.storage.data.host.HostData';
                                    }
                                    return HostData;
                                }());
                                host.HostData = HostData;
                            })(host = data.host || (data.host = {}));
                        })(data = storage.data || (storage.data = {}));
                    })(storage = h5.storage || (h5.storage = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var manage;
                            (function (manage) {
                                var model;
                                (function (model) {
                                    var DvpgPoliciesModel = (function () {
                                        function DvpgPoliciesModel() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.manage.model.DvpgPoliciesModel';
                                        }
                                        return DvpgPoliciesModel;
                                    }());
                                    model.DvpgPoliciesModel = DvpgPoliciesModel;
                                })(model = manage.model || (manage.model = {}));
                            })(manage = dvportgroup.manage || (dvportgroup.manage = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var host;
                        (function (host) {
                            var proxyswitch;
                            (function (proxyswitch) {
                                var model;
                                (function (model) {
                                    var UplinkPortMapping = (function () {
                                        function UplinkPortMapping() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.host.proxyswitch.model.UplinkPortMapping';
                                        }
                                        return UplinkPortMapping;
                                    }());
                                    model.UplinkPortMapping = UplinkPortMapping;
                                })(model = proxyswitch.model || (proxyswitch.model = {}));
                            })(proxyswitch = host.proxyswitch || (host.proxyswitch = {}));
                        })(host = network.host || (network.host = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var switchdiagram;
                        (function (switchdiagram) {
                            var model;
                            (function (model) {
                                var details;
                                (function (details) {
                                    var VmkernelAdapterDetails = (function () {
                                        function VmkernelAdapterDetails() {
                                            this._type = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.details.VmkernelAdapterDetails';
                                        }
                                        return VmkernelAdapterDetails;
                                    }());
                                    details.VmkernelAdapterDetails = VmkernelAdapterDetails;
                                })(details = model.details || (model.details = {}));
                            })(model = switchdiagram.model || (switchdiagram.model = {}));
                        })(switchdiagram = api.switchdiagram || (api.switchdiagram = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vm;
                    (function (vm) {
                        var model;
                        (function (model) {
                            var VmEvcData = (function () {
                                function VmEvcData() {
                                    this._type = 'com.vmware.vsphere.client.h5.vm.model.VmEvcData';
                                }
                                return VmEvcData;
                            }());
                            model.VmEvcData = VmEvcData;
                        })(model = vm.model || (vm.model = {}));
                    })(vm = h5.vm || (h5.vm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vm;
                    (function (vm) {
                        var model;
                        (function (model) {
                            var migration;
                            (function (migration) {
                                var VmMigrationFlowValidationData = (function () {
                                    function VmMigrationFlowValidationData() {
                                        this._type = 'com.vmware.vsphere.client.h5.vm.model.migration.VmMigrationFlowValidationData';
                                    }
                                    return VmMigrationFlowValidationData;
                                }());
                                migration.VmMigrationFlowValidationData = VmMigrationFlowValidationData;
                            })(migration = model.migration || (model.migration = {}));
                        })(model = vm.model || (vm.model = {}));
                    })(vm = h5.vm || (h5.vm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var manage;
                            (function (manage) {
                                var model;
                                (function (model) {
                                    var DvpgPolicyTypeModel = (function () {
                                        function DvpgPolicyTypeModel() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.manage.model.DvpgPolicyTypeModel';
                                        }
                                        return DvpgPolicyTypeModel;
                                    }());
                                    model.DvpgPolicyTypeModel = DvpgPolicyTypeModel;
                                })(model = manage.model || (manage.model = {}));
                            })(manage = dvportgroup.manage || (dvportgroup.manage = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var storage;
                    (function (storage) {
                        var data;
                        (function (data) {
                            var datastore;
                            (function (datastore) {
                                var reclamation;
                                (function (reclamation) {
                                    var DatastoreData = (function () {
                                        function DatastoreData() {
                                            this._type = 'com.vmware.vsphere.client.h5.storage.data.datastore.reclamation.DatastoreData';
                                        }
                                        return DatastoreData;
                                    }());
                                    reclamation.DatastoreData = DatastoreData;
                                })(reclamation = datastore.reclamation || (datastore.reclamation = {}));
                            })(datastore = data.datastore || (data.datastore = {}));
                        })(data = storage.data || (storage.data = {}));
                    })(storage = h5.storage || (h5.storage = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var storage;
                    (function (storage) {
                        var model;
                        (function (model) {
                            var IncreaseDatastoreDetailsData = (function () {
                                function IncreaseDatastoreDetailsData() {
                                    this._type = 'com.vmware.vsphere.client.h5.storage.model.IncreaseDatastoreDetailsData';
                                }
                                return IncreaseDatastoreDetailsData;
                            }());
                            model.IncreaseDatastoreDetailsData = IncreaseDatastoreDetailsData;
                        })(model = storage.model || (storage.model = {}));
                    })(storage = h5.storage || (h5.storage = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var switchdiagram;
                        (function (switchdiagram) {
                            var model;
                            (function (model) {
                                var VirtualMachineSectionModel = (function () {
                                    function VirtualMachineSectionModel() {
                                        this._type = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.VirtualMachineSectionModel';
                                    }
                                    return VirtualMachineSectionModel;
                                }());
                                model.VirtualMachineSectionModel = VirtualMachineSectionModel;
                            })(model = switchdiagram.model || (switchdiagram.model = {}));
                        })(switchdiagram = api.switchdiagram || (api.switchdiagram = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var vm;
                (function (vm) {
                    var config;
                    (function (config) {
                        var VmConfigContext = (function () {
                            function VmConfigContext() {
                                this._type = 'com.vmware.vsphere.client.vm.config.VmConfigContext';
                            }
                            return VmConfigContext;
                        }());
                        config.VmConfigContext = VmConfigContext;
                    })(config = vm.config || (vm.config = {}));
                })(vm = client.vm || (client.vm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vm;
                    (function (vm) {
                        var model;
                        (function (model) {
                            var migration;
                            (function (migration) {
                                var ComputeResourceValidationData = (function () {
                                    function ComputeResourceValidationData() {
                                        this._type = 'com.vmware.vsphere.client.h5.vm.model.migration.ComputeResourceValidationData';
                                    }
                                    return ComputeResourceValidationData;
                                }());
                                migration.ComputeResourceValidationData = ComputeResourceValidationData;
                            })(migration = model.migration || (model.migration = {}));
                        })(model = vm.model || (vm.model = {}));
                    })(vm = h5.vm || (h5.vm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var addhost;
                            (function (addhost) {
                                var model;
                                (function (model) {
                                    var DvPortgroupModel = (function () {
                                        function DvPortgroupModel() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.addhost.model.DvPortgroupModel';
                                        }
                                        return DvPortgroupModel;
                                    }());
                                    model.DvPortgroupModel = DvPortgroupModel;
                                })(model = addhost.model || (addhost.model = {}));
                            })(addhost = dvs.addhost || (dvs.addhost = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var vm;
                (function (vm) {
                    var VmRemovalSpec = (function () {
                        function VmRemovalSpec() {
                            this._type = 'com.vmware.vsphere.client.vm.VmRemovalSpec';
                        }
                        return VmRemovalSpec;
                    }());
                    vm.VmRemovalSpec = VmRemovalSpec;
                })(vm = client.vm || (client.vm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var storage;
                    (function (storage) {
                        var data;
                        (function (data) {
                            var adapters;
                            (function (adapters) {
                                var NetworkSettingsItem = (function () {
                                    function NetworkSettingsItem() {
                                        this._type = 'com.vmware.vsphere.client.h5.storage.data.adapters.NetworkSettingsItem';
                                    }
                                    return NetworkSettingsItem;
                                }());
                                adapters.NetworkSettingsItem = NetworkSettingsItem;
                            })(adapters = data.adapters || (data.adapters = {}));
                        })(data = storage.data || (storage.data = {}));
                    })(storage = h5.storage || (h5.storage = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var hostprofile;
                (function (hostprofile) {
                    var data;
                    (function (data) {
                        var ProfileUpdateFailure = (function () {
                            function ProfileUpdateFailure() {
                                this._type = 'com.vmware.vsphere.client.hostprofile.data.ProfileUpdateFailure';
                            }
                            return ProfileUpdateFailure;
                        }());
                        data.ProfileUpdateFailure = ProfileUpdateFailure;
                    })(data = hostprofile.data || (hostprofile.data = {}));
                })(hostprofile = client.hostprofile || (client.hostprofile = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vm;
                    (function (vm) {
                        var model;
                        (function (model) {
                            var SupportedEvcMode = (function () {
                                function SupportedEvcMode() {
                                    this._type = 'com.vmware.vsphere.client.h5.vm.model.SupportedEvcMode';
                                }
                                return SupportedEvcMode;
                            }());
                            model.SupportedEvcMode = SupportedEvcMode;
                        })(model = vm.model || (vm.model = {}));
                    })(vm = h5.vm || (h5.vm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var common;
                            (function (common) {
                                var model;
                                (function (model) {
                                    var DvpgVlanPolicyModel = (function () {
                                        function DvpgVlanPolicyModel() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgVlanPolicyModel';
                                        }
                                        return DvpgVlanPolicyModel;
                                    }());
                                    model.DvpgVlanPolicyModel = DvpgVlanPolicyModel;
                                })(model = common.model || (common.model = {}));
                            })(common = dvportgroup.common || (dvportgroup.common = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var ports;
                            (function (ports) {
                                var model;
                                (function (model) {
                                    var DvsPortVlanPolicyModel = (function (_super) {
                                        __extends(DvsPortVlanPolicyModel, _super);
                                        function DvsPortVlanPolicyModel() {
                                            _super.apply(this, arguments);
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortVlanPolicyModel';
                                        }
                                        return DvsPortVlanPolicyModel;
                                    }(com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgVlanPolicyModel));
                                    model.DvsPortVlanPolicyModel = DvsPortVlanPolicyModel;
                                })(model = ports.model || (ports.model = {}));
                            })(ports = dvs.ports || (dvs.ports = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var portmirroring;
                            (function (portmirroring) {
                                var model;
                                (function (model) {
                                    var portlist;
                                    (function (portlist) {
                                        var DvsSpanSessionPortListData = (function () {
                                            function DvsSpanSessionPortListData() {
                                                this._type = 'com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.portlist.DvsSpanSessionPortListData';
                                            }
                                            return DvsSpanSessionPortListData;
                                        }());
                                        portlist.DvsSpanSessionPortListData = DvsSpanSessionPortListData;
                                    })(portlist = model.portlist || (model.portlist = {}));
                                })(model = portmirroring.model || (portmirroring.model = {}));
                            })(portmirroring = dvs.portmirroring || (dvs.portmirroring = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var switchdiagram;
                        (function (switchdiagram) {
                            var model;
                            (function (model) {
                                var VirtualMachineSectionsModel = (function () {
                                    function VirtualMachineSectionsModel() {
                                        this._type = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.VirtualMachineSectionsModel';
                                    }
                                    return VirtualMachineSectionsModel;
                                }());
                                model.VirtualMachineSectionsModel = VirtualMachineSectionsModel;
                            })(model = switchdiagram.model || (switchdiagram.model = {}));
                        })(switchdiagram = api.switchdiagram || (api.switchdiagram = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vm;
                    (function (vm) {
                        var model;
                        (function (model) {
                            var migration;
                            (function (migration) {
                                var VmMigrationScheduledTaskEditDefaultsValidationSpec = (function () {
                                    function VmMigrationScheduledTaskEditDefaultsValidationSpec() {
                                        this._type = 'com.vmware.vsphere.client.h5.vm.model.migration.VmMigrationScheduledTaskEditDefaultsValidationSpec';
                                    }
                                    return VmMigrationScheduledTaskEditDefaultsValidationSpec;
                                }());
                                migration.VmMigrationScheduledTaskEditDefaultsValidationSpec = VmMigrationScheduledTaskEditDefaultsValidationSpec;
                            })(migration = model.migration || (model.migration = {}));
                        })(model = vm.model || (vm.model = {}));
                    })(vm = h5.vm || (h5.vm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var trafficfilter;
                            (function (trafficfilter) {
                                var model;
                                (function (model) {
                                    var DvpgPortModel = (function () {
                                        function DvpgPortModel() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgPortModel';
                                        }
                                        return DvpgPortModel;
                                    }());
                                    model.DvpgPortModel = DvpgPortModel;
                                })(model = trafficfilter.model || (trafficfilter.model = {}));
                            })(trafficfilter = dvportgroup.trafficfilter || (dvportgroup.trafficfilter = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var vm;
                (function (vm) {
                    var ft;
                    (function (ft) {
                        var VmEnableSecondarySpec = (function () {
                            function VmEnableSecondarySpec() {
                                this._type = 'com.vmware.vsphere.client.vm.ft.VmEnableSecondarySpec';
                            }
                            return VmEnableSecondarySpec;
                        }());
                        ft.VmEnableSecondarySpec = VmEnableSecondarySpec;
                    })(ft = vm.ft || (vm.ft = {}));
                })(vm = client.vm || (client.vm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var spbm;
                (function (spbm) {
                    var data;
                    (function (data) {
                        var profile;
                        (function (profile) {
                            var content;
                            (function (content) {
                                var PropertyData = (function () {
                                    function PropertyData() {
                                        this._type = 'com.vmware.vsphere.client.spbm.data.profile.content.PropertyData';
                                    }
                                    return PropertyData;
                                }());
                                content.PropertyData = PropertyData;
                            })(content = profile.content || (profile.content = {}));
                        })(profile = data.profile || (data.profile = {}));
                    })(data = spbm.data || (spbm.data = {}));
                })(spbm = client.spbm || (client.spbm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var network;
                (function (network) {
                    var switchdiagram;
                    (function (switchdiagram) {
                        var api;
                        (function (api) {
                            var spec;
                            (function (spec) {
                                var VssDiagramVmsSpec = (function () {
                                    function VssDiagramVmsSpec() {
                                        this._type = 'com.vmware.vsphere.client.network.switchdiagram.api.spec.VssDiagramVmsSpec';
                                    }
                                    return VssDiagramVmsSpec;
                                }());
                                spec.VssDiagramVmsSpec = VssDiagramVmsSpec;
                            })(spec = api.spec || (api.spec = {}));
                        })(api = switchdiagram.api || (switchdiagram.api = {}));
                    })(switchdiagram = network.switchdiagram || (network.switchdiagram = {}));
                })(network = client.network || (client.network = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var trafficfilter;
                            (function (trafficfilter) {
                                var DvpgEditTrafficRuleSpec = (function () {
                                    function DvpgEditTrafficRuleSpec() {
                                        this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgEditTrafficRuleSpec';
                                    }
                                    return DvpgEditTrafficRuleSpec;
                                }());
                                trafficfilter.DvpgEditTrafficRuleSpec = DvpgEditTrafficRuleSpec;
                            })(trafficfilter = dvportgroup.trafficfilter || (dvportgroup.trafficfilter = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var vm;
                (function (vm) {
                    var ft;
                    (function (ft) {
                        var VmTurnOffFtSpec = (function () {
                            function VmTurnOffFtSpec() {
                                this._type = 'com.vmware.vsphere.client.vm.ft.VmTurnOffFtSpec';
                            }
                            return VmTurnOffFtSpec;
                        }());
                        ft.VmTurnOffFtSpec = VmTurnOffFtSpec;
                    })(ft = vm.ft || (vm.ft = {}));
                })(vm = client.vm || (client.vm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var host;
                        (function (host) {
                            var HostListItemData = (function () {
                                function HostListItemData() {
                                    this._type = 'com.vmware.vsphere.client.dvs.api.host.HostListItemData';
                                }
                                return HostListItemData;
                            }());
                            host.HostListItemData = HostListItemData;
                        })(host = api.host || (api.host = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var resmgmt;
                            (function (resmgmt) {
                                var systemtraffic;
                                (function (systemtraffic) {
                                    var model;
                                    (function (model) {
                                        var DvsSystemTrafficListData = (function () {
                                            function DvsSystemTrafficListData() {
                                                this._type = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.systemtraffic.model.DvsSystemTrafficListData';
                                            }
                                            return DvsSystemTrafficListData;
                                        }());
                                        model.DvsSystemTrafficListData = DvsSystemTrafficListData;
                                    })(model = systemtraffic.model || (systemtraffic.model = {}));
                                })(systemtraffic = resmgmt.systemtraffic || (resmgmt.systemtraffic = {}));
                            })(resmgmt = dvs.resmgmt || (dvs.resmgmt = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vm;
                    (function (vm) {
                        var model;
                        (function (model) {
                            var migration;
                            (function (migration) {
                                var VmMigrationScheduledTaskEditDefaultsData = (function () {
                                    function VmMigrationScheduledTaskEditDefaultsData() {
                                        this._type = 'com.vmware.vsphere.client.h5.vm.model.migration.VmMigrationScheduledTaskEditDefaultsData';
                                    }
                                    return VmMigrationScheduledTaskEditDefaultsData;
                                }());
                                migration.VmMigrationScheduledTaskEditDefaultsData = VmMigrationScheduledTaskEditDefaultsData;
                            })(migration = model.migration || (model.migration = {}));
                        })(model = vm.model || (vm.model = {}));
                    })(vm = h5.vm || (h5.vm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvpg;
                (function (dvpg) {
                    var api;
                    (function (api) {
                        var HostNetworkDetailsData$PortPolicy$Security = (function () {
                            function HostNetworkDetailsData$PortPolicy$Security() {
                                this._type = 'com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData$PortPolicy$Security';
                            }
                            return HostNetworkDetailsData$PortPolicy$Security;
                        }());
                        api.HostNetworkDetailsData$PortPolicy$Security = HostNetworkDetailsData$PortPolicy$Security;
                    })(api = dvpg.api || (dvpg.api = {}));
                })(dvpg = client.dvpg || (client.dvpg = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var spec;
                        (function (spec) {
                            var DvsImportPortgroupWrapperSpec = (function () {
                                function DvsImportPortgroupWrapperSpec() {
                                    this._type = 'com.vmware.vsphere.client.dvs.api.spec.DvsImportPortgroupWrapperSpec';
                                }
                                return DvsImportPortgroupWrapperSpec;
                            }());
                            spec.DvsImportPortgroupWrapperSpec = DvsImportPortgroupWrapperSpec;
                        })(spec = api.spec || (api.spec = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vm;
                    (function (vm) {
                        var configuration;
                        (function (configuration) {
                            var LinuxTimeZoneLocation = (function () {
                                function LinuxTimeZoneLocation() {
                                    this._type = 'com.vmware.vsphere.client.h5.vm.configuration.LinuxTimeZoneLocation';
                                }
                                return LinuxTimeZoneLocation;
                            }());
                            configuration.LinuxTimeZoneLocation = LinuxTimeZoneLocation;
                        })(configuration = vm.configuration || (vm.configuration = {}));
                    })(vm = h5.vm || (h5.vm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var pvlan;
                            (function (pvlan) {
                                var model;
                                (function (model) {
                                    var DvsPvlanData = (function () {
                                        function DvsPvlanData() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.pvlan.model.DvsPvlanData';
                                        }
                                        return DvsPvlanData;
                                    }());
                                    model.DvsPvlanData = DvsPvlanData;
                                })(model = pvlan.model || (pvlan.model = {}));
                            })(pvlan = dvs.pvlan || (dvs.pvlan = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var vm;
                (function (vm) {
                    var api;
                    (function (api) {
                        var DatastoreMirrorsInfo = (function () {
                            function DatastoreMirrorsInfo() {
                                this._type = 'com.vmware.vsphere.client.vm.api.DatastoreMirrorsInfo';
                            }
                            return DatastoreMirrorsInfo;
                        }());
                        api.DatastoreMirrorsInfo = DatastoreMirrorsInfo;
                    })(api = vm.api || (vm.api = {}));
                })(vm = client.vm || (client.vm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var host;
                        (function (host) {
                            var DvsHostsData = (function () {
                                function DvsHostsData() {
                                    this._type = 'com.vmware.vsphere.client.dvs.api.host.DvsHostsData';
                                }
                                return DvsHostsData;
                            }());
                            host.DvsHostsData = DvsHostsData;
                        })(host = api.host || (api.host = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var storage;
                (function (storage) {
                    var DatastoreProtocolEndpointHostData = (function () {
                        function DatastoreProtocolEndpointHostData() {
                            this._type = 'com.vmware.vsphere.client.storage.DatastoreProtocolEndpointHostData';
                        }
                        return DatastoreProtocolEndpointHostData;
                    }());
                    storage.DatastoreProtocolEndpointHostData = DatastoreProtocolEndpointHostData;
                })(storage = client.storage || (client.storage = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var storage;
                    (function (storage) {
                        var data;
                        (function (data) {
                            var datastore;
                            (function (datastore) {
                                var DatastoreCopyItem = (function () {
                                    function DatastoreCopyItem() {
                                        this._type = 'com.vmware.vsphere.client.h5.storage.data.datastore.DatastoreCopyItem';
                                    }
                                    return DatastoreCopyItem;
                                }());
                                datastore.DatastoreCopyItem = DatastoreCopyItem;
                            })(datastore = data.datastore || (data.datastore = {}));
                        })(data = storage.data || (storage.data = {}));
                    })(storage = h5.storage || (h5.storage = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var host;
                        (function (host) {
                            var migratevmkadapter;
                            (function (migratevmkadapter) {
                                var model;
                                (function (model) {
                                    var MigrateVmkToVssSpec = (function () {
                                        function MigrateVmkToVssSpec() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.host.migratevmkadapter.model.MigrateVmkToVssSpec';
                                        }
                                        return MigrateVmkToVssSpec;
                                    }());
                                    model.MigrateVmkToVssSpec = MigrateVmkToVssSpec;
                                })(model = migratevmkadapter.model || (migratevmkadapter.model = {}));
                            })(migratevmkadapter = host.migratevmkadapter || (host.migratevmkadapter = {}));
                        })(host = network.host || (network.host = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var resmgmt;
                            (function (resmgmt) {
                                var systemtraffic;
                                (function (systemtraffic) {
                                    var model;
                                    (function (model) {
                                        var DvsSystemTrafficMeterData = (function () {
                                            function DvsSystemTrafficMeterData() {
                                                this._type = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.systemtraffic.model.DvsSystemTrafficMeterData';
                                            }
                                            return DvsSystemTrafficMeterData;
                                        }());
                                        model.DvsSystemTrafficMeterData = DvsSystemTrafficMeterData;
                                    })(model = systemtraffic.model || (systemtraffic.model = {}));
                                })(systemtraffic = resmgmt.systemtraffic || (resmgmt.systemtraffic = {}));
                            })(resmgmt = dvs.resmgmt || (dvs.resmgmt = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var resmgmt;
                            (function (resmgmt) {
                                var nrp;
                                (function (nrp) {
                                    var model;
                                    (function (model) {
                                        var DvsVmVnicResourceSettingsDialogData = (function () {
                                            function DvsVmVnicResourceSettingsDialogData() {
                                                this._type = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsVmVnicResourceSettingsDialogData';
                                            }
                                            return DvsVmVnicResourceSettingsDialogData;
                                        }());
                                        model.DvsVmVnicResourceSettingsDialogData = DvsVmVnicResourceSettingsDialogData;
                                    })(model = nrp.model || (nrp.model = {}));
                                })(nrp = resmgmt.nrp || (resmgmt.nrp = {}));
                            })(resmgmt = dvs.resmgmt || (dvs.resmgmt = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var host;
                        (function (host) {
                            var migratevmkadapter;
                            (function (migratevmkadapter) {
                                var model;
                                (function (model) {
                                    var HostStandardPortGroupInfo = (function () {
                                        function HostStandardPortGroupInfo() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.host.migratevmkadapter.model.HostStandardPortGroupInfo';
                                        }
                                        return HostStandardPortGroupInfo;
                                    }());
                                    model.HostStandardPortGroupInfo = HostStandardPortGroupInfo;
                                })(model = migratevmkadapter.model || (migratevmkadapter.model = {}));
                            })(migratevmkadapter = host.migratevmkadapter || (host.migratevmkadapter = {}));
                        })(host = network.host || (network.host = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var vm;
                (function (vm) {
                    var migration;
                    (function (migration) {
                        var LocationSpec = (function () {
                            function LocationSpec() {
                                this._type = 'com.vmware.vsphere.client.vm.migration.LocationSpec';
                            }
                            return LocationSpec;
                        }());
                        migration.LocationSpec = LocationSpec;
                    })(migration = vm.migration || (vm.migration = {}));
                })(vm = client.vm || (client.vm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var trafficfilter;
                            (function (trafficfilter) {
                                var DvpgEditTrafficFilterSpec = (function () {
                                    function DvpgEditTrafficFilterSpec() {
                                        this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgEditTrafficFilterSpec';
                                    }
                                    return DvpgEditTrafficFilterSpec;
                                }());
                                trafficfilter.DvpgEditTrafficFilterSpec = DvpgEditTrafficFilterSpec;
                            })(trafficfilter = dvportgroup.trafficfilter || (dvportgroup.trafficfilter = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var addhost;
                            (function (addhost) {
                                var model;
                                (function (model) {
                                    var DvsNewHostsData = (function () {
                                        function DvsNewHostsData() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.addhost.model.DvsNewHostsData';
                                        }
                                        return DvsNewHostsData;
                                    }());
                                    model.DvsNewHostsData = DvsNewHostsData;
                                })(model = addhost.model || (addhost.model = {}));
                            })(addhost = dvs.addhost || (dvs.addhost = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvpg;
                (function (dvpg) {
                    var api;
                    (function (api) {
                        var HostNetworkDetailsData = (function () {
                            function HostNetworkDetailsData() {
                                this._type = 'com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData';
                            }
                            return HostNetworkDetailsData;
                        }());
                        api.HostNetworkDetailsData = HostNetworkDetailsData;
                    })(api = dvpg.api || (dvpg.api = {}));
                })(dvpg = client.dvpg || (client.dvpg = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var addhost;
                            (function (addhost) {
                                var model;
                                (function (model) {
                                    var VnicAssignmentSpec = (function () {
                                        function VnicAssignmentSpec() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.addhost.model.VnicAssignmentSpec';
                                        }
                                        return VnicAssignmentSpec;
                                    }());
                                    model.VnicAssignmentSpec = VnicAssignmentSpec;
                                })(model = addhost.model || (addhost.model = {}));
                            })(addhost = dvs.addhost || (dvs.addhost = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var network;
                (function (network) {
                    var switchdiagram;
                    (function (switchdiagram) {
                        var api;
                        (function (api) {
                            var spec;
                            (function (spec) {
                                var OvsDiagramVmsSpec = (function () {
                                    function OvsDiagramVmsSpec() {
                                        this._type = 'com.vmware.vsphere.client.network.switchdiagram.api.spec.OvsDiagramVmsSpec';
                                    }
                                    return OvsDiagramVmsSpec;
                                }());
                                spec.OvsDiagramVmsSpec = OvsDiagramVmsSpec;
                            })(spec = api.spec || (api.spec = {}));
                        })(api = switchdiagram.api || (switchdiagram.api = {}));
                    })(switchdiagram = network.switchdiagram || (network.switchdiagram = {}));
                })(network = client.network || (client.network = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var spec;
                        (function (spec) {
                            var DvsImportActionSpec = (function () {
                                function DvsImportActionSpec() {
                                    this._type = 'com.vmware.vsphere.client.dvs.api.spec.DvsImportActionSpec';
                                }
                                return DvsImportActionSpec;
                            }());
                            spec.DvsImportActionSpec = DvsImportActionSpec;
                        })(spec = api.spec || (api.spec = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var addvnics;
                            (function (addvnics) {
                                var model;
                                (function (model) {
                                    var DvpgVnicsIpSettingsModel = (function () {
                                        function DvpgVnicsIpSettingsModel() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.addvnics.model.DvpgVnicsIpSettingsModel';
                                        }
                                        return DvpgVnicsIpSettingsModel;
                                    }());
                                    model.DvpgVnicsIpSettingsModel = DvpgVnicsIpSettingsModel;
                                })(model = addvnics.model || (addvnics.model = {}));
                            })(addvnics = dvportgroup.addvnics || (dvportgroup.addvnics = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var ports;
                            (function (ports) {
                                var model;
                                (function (model) {
                                    var DvsPortAddTrafficRuleSpec = (function () {
                                        function DvsPortAddTrafficRuleSpec() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortAddTrafficRuleSpec';
                                        }
                                        return DvsPortAddTrafficRuleSpec;
                                    }());
                                    model.DvsPortAddTrafficRuleSpec = DvsPortAddTrafficRuleSpec;
                                })(model = ports.model || (ports.model = {}));
                            })(ports = dvs.ports || (dvs.ports = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var trafficfilter;
                            (function (trafficfilter) {
                                var model;
                                (function (model) {
                                    var DvpgSystemQualifierModel = (function () {
                                        function DvpgSystemQualifierModel() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgSystemQualifierModel';
                                        }
                                        return DvpgSystemQualifierModel;
                                    }());
                                    model.DvpgSystemQualifierModel = DvpgSystemQualifierModel;
                                })(model = trafficfilter.model || (trafficfilter.model = {}));
                            })(trafficfilter = dvportgroup.trafficfilter || (dvportgroup.trafficfilter = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var spbm;
                    (function (spbm) {
                        var data;
                        (function (data) {
                            var wizard;
                            (function (wizard) {
                                var SpbmVcData = (function () {
                                    function SpbmVcData() {
                                        this._type = 'com.vmware.vsphere.client.h5.spbm.data.wizard.SpbmVcData';
                                    }
                                    return SpbmVcData;
                                }());
                                wizard.SpbmVcData = SpbmVcData;
                            })(wizard = data.wizard || (data.wizard = {}));
                        })(data = spbm.data || (spbm.data = {}));
                    })(spbm = h5.spbm || (h5.spbm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vm;
                    (function (vm) {
                        var data;
                        (function (data) {
                            var CompatibilityCheckResult = (function () {
                                function CompatibilityCheckResult() {
                                    this._type = 'com.vmware.vsphere.client.h5.vm.data.CompatibilityCheckResult';
                                }
                                return CompatibilityCheckResult;
                            }());
                            data.CompatibilityCheckResult = CompatibilityCheckResult;
                        })(data = vm.data || (vm.data = {}));
                    })(vm = h5.vm || (h5.vm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var resmgmt;
                            (function (resmgmt) {
                                var nrp;
                                (function (nrp) {
                                    var model;
                                    (function (model) {
                                        var DvsNetworkResourcePoolDialogData = (function () {
                                            function DvsNetworkResourcePoolDialogData() {
                                                this._type = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsNetworkResourcePoolDialogData';
                                            }
                                            return DvsNetworkResourcePoolDialogData;
                                        }());
                                        model.DvsNetworkResourcePoolDialogData = DvsNetworkResourcePoolDialogData;
                                    })(model = nrp.model || (nrp.model = {}));
                                })(nrp = resmgmt.nrp || (resmgmt.nrp = {}));
                            })(resmgmt = dvs.resmgmt || (dvs.resmgmt = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vm;
                    (function (vm) {
                        var data;
                        (function (data) {
                            var ValidateMoveVmSpec = (function () {
                                function ValidateMoveVmSpec() {
                                    this._type = 'com.vmware.vsphere.client.h5.vm.data.ValidateMoveVmSpec';
                                }
                                return ValidateMoveVmSpec;
                            }());
                            data.ValidateMoveVmSpec = ValidateMoveVmSpec;
                        })(data = vm.data || (vm.data = {}));
                    })(vm = h5.vm || (h5.vm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var addhost;
                            (function (addhost) {
                                var model;
                                (function (model) {
                                    var VmVnicAssignmentSpec = (function () {
                                        function VmVnicAssignmentSpec() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.addhost.model.VmVnicAssignmentSpec';
                                        }
                                        return VmVnicAssignmentSpec;
                                    }());
                                    model.VmVnicAssignmentSpec = VmVnicAssignmentSpec;
                                })(model = addhost.model || (addhost.model = {}));
                            })(addhost = dvs.addhost || (dvs.addhost = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvpg;
                (function (dvpg) {
                    var api;
                    (function (api) {
                        var HostNetworkDetailsData$PortPolicy = (function () {
                            function HostNetworkDetailsData$PortPolicy() {
                                this._type = 'com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData$PortPolicy';
                            }
                            return HostNetworkDetailsData$PortPolicy;
                        }());
                        api.HostNetworkDetailsData$PortPolicy = HostNetworkDetailsData$PortPolicy;
                    })(api = dvpg.api || (dvpg.api = {}));
                })(dvpg = client.dvpg || (client.dvpg = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var hostprofile;
                (function (hostprofile) {
                    var data;
                    (function (data) {
                        var DeferredHostSettingsSpec = (function () {
                            function DeferredHostSettingsSpec() {
                                this._type = 'com.vmware.vsphere.client.hostprofile.data.DeferredHostSettingsSpec';
                            }
                            return DeferredHostSettingsSpec;
                        }());
                        data.DeferredHostSettingsSpec = DeferredHostSettingsSpec;
                    })(data = hostprofile.data || (hostprofile.data = {}));
                })(hostprofile = client.hostprofile || (client.hostprofile = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var vm;
                (function (vm) {
                    var customizations;
                    (function (customizations) {
                        var VmCustomizationEditSpec = (function (_super) {
                            __extends(VmCustomizationEditSpec, _super);
                            function VmCustomizationEditSpec() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vsphere.client.vm.customizations.VmCustomizationEditSpec';
                            }
                            return VmCustomizationEditSpec;
                        }(customizations.VmCustomizationSpec));
                        customizations.VmCustomizationEditSpec = VmCustomizationEditSpec;
                    })(customizations = vm.customizations || (vm.customizations = {}));
                })(vm = client.vm || (client.vm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var resmgmt;
                            (function (resmgmt) {
                                var systemtraffic;
                                (function (systemtraffic) {
                                    var model;
                                    (function (model) {
                                        var DvsSystemTrafficViewData = (function () {
                                            function DvsSystemTrafficViewData() {
                                                this._type = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.systemtraffic.model.DvsSystemTrafficViewData';
                                            }
                                            return DvsSystemTrafficViewData;
                                        }());
                                        model.DvsSystemTrafficViewData = DvsSystemTrafficViewData;
                                    })(model = systemtraffic.model || (systemtraffic.model = {}));
                                })(systemtraffic = resmgmt.systemtraffic || (resmgmt.systemtraffic = {}));
                            })(resmgmt = dvs.resmgmt || (dvs.resmgmt = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var model;
                        (function (model) {
                            var DvsPortsListData = (function () {
                                function DvsPortsListData() {
                                    this._type = 'com.vmware.vsphere.client.h5.network.model.DvsPortsListData';
                                }
                                return DvsPortsListData;
                            }());
                            model.DvsPortsListData = DvsPortsListData;
                        })(model = network.model || (network.model = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var addvnics;
                            (function (addvnics) {
                                var model;
                                (function (model) {
                                    var DvpgHostNetStackGatewayModel = (function () {
                                        function DvpgHostNetStackGatewayModel() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.addvnics.model.DvpgHostNetStackGatewayModel';
                                        }
                                        return DvpgHostNetStackGatewayModel;
                                    }());
                                    model.DvpgHostNetStackGatewayModel = DvpgHostNetStackGatewayModel;
                                })(model = addvnics.model || (addvnics.model = {}));
                            })(addvnics = dvportgroup.addvnics || (dvportgroup.addvnics = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var switchdiagram;
                        (function (switchdiagram) {
                            var model;
                            (function (model) {
                                var VswitchDiagramModel = (function () {
                                    function VswitchDiagramModel() {
                                        this._type = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.VswitchDiagramModel';
                                    }
                                    return VswitchDiagramModel;
                                }());
                                model.VswitchDiagramModel = VswitchDiagramModel;
                            })(model = switchdiagram.model || (switchdiagram.model = {}));
                        })(switchdiagram = api.switchdiagram || (api.switchdiagram = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var hostprofile;
                (function (hostprofile) {
                    var data;
                    (function (data) {
                        var h5;
                        (function (h5) {
                            var ReadHostCustomizationsSpec = (function () {
                                function ReadHostCustomizationsSpec() {
                                    this._type = 'com.vmware.vsphere.client.hostprofile.data.h5.ReadHostCustomizationsSpec';
                                }
                                return ReadHostCustomizationsSpec;
                            }());
                            h5.ReadHostCustomizationsSpec = ReadHostCustomizationsSpec;
                        })(h5 = data.h5 || (data.h5 = {}));
                    })(data = hostprofile.data || (hostprofile.data = {}));
                })(hostprofile = client.hostprofile || (client.hostprofile = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var spbm;
                (function (spbm) {
                    var spec;
                    (function (spec) {
                        var CalculateCostSpecBase = (function () {
                            function CalculateCostSpecBase() {
                                this._type = 'com.vmware.vsphere.client.spbm.spec.CalculateCostSpecBase';
                            }
                            return CalculateCostSpecBase;
                        }());
                        spec.CalculateCostSpecBase = CalculateCostSpecBase;
                    })(spec = spbm.spec || (spbm.spec = {}));
                })(spbm = client.spbm || (client.spbm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var healthcheck;
                            (function (healthcheck) {
                                var model;
                                (function (model) {
                                    var DvsHealthCheckSettingsData = (function () {
                                        function DvsHealthCheckSettingsData() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.healthcheck.model.DvsHealthCheckSettingsData';
                                        }
                                        return DvsHealthCheckSettingsData;
                                    }());
                                    model.DvsHealthCheckSettingsData = DvsHealthCheckSettingsData;
                                })(model = healthcheck.model || (healthcheck.model = {}));
                            })(healthcheck = dvs.healthcheck || (dvs.healthcheck = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var spec;
                        (function (spec) {
                            var DvPortgroupApplyRestoreSpec = (function () {
                                function DvPortgroupApplyRestoreSpec() {
                                    this._type = 'com.vmware.vsphere.client.dvs.api.spec.DvPortgroupApplyRestoreSpec';
                                }
                                return DvPortgroupApplyRestoreSpec;
                            }());
                            spec.DvPortgroupApplyRestoreSpec = DvPortgroupApplyRestoreSpec;
                        })(spec = api.spec || (api.spec = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var host;
                        (function (host) {
                            var pnic;
                            (function (pnic) {
                                var model;
                                (function (model) {
                                    var EditHostPnicModel = (function () {
                                        function EditHostPnicModel() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.host.pnic.model.EditHostPnicModel';
                                        }
                                        return EditHostPnicModel;
                                    }());
                                    model.EditHostPnicModel = EditHostPnicModel;
                                })(model = pnic.model || (pnic.model = {}));
                            })(pnic = host.pnic || (host.pnic = {}));
                        })(host = network.host || (network.host = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var portmirroring;
                            (function (portmirroring) {
                                var model;
                                (function (model) {
                                    var DvsPortMirroringDestinationPorts = (function () {
                                        function DvsPortMirroringDestinationPorts() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.DvsPortMirroringDestinationPorts';
                                        }
                                        return DvsPortMirroringDestinationPorts;
                                    }());
                                    model.DvsPortMirroringDestinationPorts = DvsPortMirroringDestinationPorts;
                                })(model = portmirroring.model || (portmirroring.model = {}));
                            })(portmirroring = dvs.portmirroring || (dvs.portmirroring = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var spbm;
                    (function (spbm) {
                        var data;
                        (function (data) {
                            var StorageProfileContainerCompatibilityData$StorageContainerCompatibilityInfo = (function () {
                                function StorageProfileContainerCompatibilityData$StorageContainerCompatibilityInfo() {
                                    this._type = 'com.vmware.vsphere.client.h5.spbm.data.StorageProfileContainerCompatibilityData$StorageContainerCompatibilityInfo';
                                }
                                return StorageProfileContainerCompatibilityData$StorageContainerCompatibilityInfo;
                            }());
                            data.StorageProfileContainerCompatibilityData$StorageContainerCompatibilityInfo = StorageProfileContainerCompatibilityData$StorageContainerCompatibilityInfo;
                        })(data = spbm.data || (spbm.data = {}));
                    })(spbm = h5.spbm || (h5.spbm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var resmgmt;
                            (function (resmgmt) {
                                var nrp;
                                (function (nrp) {
                                    var model;
                                    (function (model) {
                                        var DvsNetworkResourcePoolsListData = (function () {
                                            function DvsNetworkResourcePoolsListData() {
                                                this._type = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsNetworkResourcePoolsListData';
                                            }
                                            return DvsNetworkResourcePoolsListData;
                                        }());
                                        model.DvsNetworkResourcePoolsListData = DvsNetworkResourcePoolsListData;
                                    })(model = nrp.model || (nrp.model = {}));
                                })(nrp = resmgmt.nrp || (resmgmt.nrp = {}));
                            })(resmgmt = dvs.resmgmt || (dvs.resmgmt = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var addvnics;
                            (function (addvnics) {
                                var DvpgAddVnicsSpec = (function () {
                                    function DvpgAddVnicsSpec() {
                                        this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.addvnics.DvpgAddVnicsSpec';
                                    }
                                    return DvpgAddVnicsSpec;
                                }());
                                addvnics.DvpgAddVnicsSpec = DvpgAddVnicsSpec;
                            })(addvnics = dvportgroup.addvnics || (dvportgroup.addvnics = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var portmirroring;
                            (function (portmirroring) {
                                var model;
                                (function (model) {
                                    var wizard;
                                    (function (wizard) {
                                        var DvsAddSpanSessionSpec = (function () {
                                            function DvsAddSpanSessionSpec() {
                                                this._type = 'com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.wizard.DvsAddSpanSessionSpec';
                                            }
                                            return DvsAddSpanSessionSpec;
                                        }());
                                        wizard.DvsAddSpanSessionSpec = DvsAddSpanSessionSpec;
                                    })(wizard = model.wizard || (model.wizard = {}));
                                })(model = portmirroring.model || (portmirroring.model = {}));
                            })(portmirroring = dvs.portmirroring || (dvs.portmirroring = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var resmgmt;
                            (function (resmgmt) {
                                var nrp;
                                (function (nrp) {
                                    var model;
                                    (function (model) {
                                        var DvsNetworkResourcePoolVmsListItemData = (function () {
                                            function DvsNetworkResourcePoolVmsListItemData() {
                                                this._type = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsNetworkResourcePoolVmsListItemData';
                                            }
                                            return DvsNetworkResourcePoolVmsListItemData;
                                        }());
                                        model.DvsNetworkResourcePoolVmsListItemData = DvsNetworkResourcePoolVmsListItemData;
                                    })(model = nrp.model || (nrp.model = {}));
                                })(nrp = resmgmt.nrp || (resmgmt.nrp = {}));
                            })(resmgmt = dvs.resmgmt || (dvs.resmgmt = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var trafficfilter;
                            (function (trafficfilter) {
                                var model;
                                (function (model) {
                                    var DvpgIpQualifierModel = (function () {
                                        function DvpgIpQualifierModel() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgIpQualifierModel';
                                        }
                                        return DvpgIpQualifierModel;
                                    }());
                                    model.DvpgIpQualifierModel = DvpgIpQualifierModel;
                                })(model = trafficfilter.model || (trafficfilter.model = {}));
                            })(trafficfilter = dvportgroup.trafficfilter || (dvportgroup.trafficfilter = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var host;
                        (function (host) {
                            var netstack;
                            (function (netstack) {
                                var model;
                                (function (model) {
                                    var NetStackConfigSpec = (function () {
                                        function NetStackConfigSpec() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.host.netstack.model.NetStackConfigSpec';
                                        }
                                        return NetStackConfigSpec;
                                    }());
                                    model.NetStackConfigSpec = NetStackConfigSpec;
                                })(model = netstack.model || (netstack.model = {}));
                            })(netstack = host.netstack || (host.netstack = {}));
                        })(host = network.host || (network.host = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vim;
        (function (vim) {
            var binding;
            (function (binding) {
                var sms;
                (function (sms) {
                    var storage;
                    (function (storage) {
                        var replication;
                        (function (replication) {
                            var GroupInfo = (function () {
                                function GroupInfo() {
                                    this._type = 'com.vmware.vim.binding.sms.storage.replication.GroupInfo';
                                }
                                return GroupInfo;
                            }());
                            replication.GroupInfo = GroupInfo;
                        })(replication = storage.replication || (storage.replication = {}));
                    })(storage = sms.storage || (sms.storage = {}));
                })(sms = binding.sms || (binding.sms = {}));
            })(binding = vim.binding || (vim.binding = {}));
        })(vim = vmware.vim || (vmware.vim = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var common;
                            (function (common) {
                                var model;
                                (function (model) {
                                    var DvpgFailoverPolicyModel = (function () {
                                        function DvpgFailoverPolicyModel() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgFailoverPolicyModel';
                                        }
                                        return DvpgFailoverPolicyModel;
                                    }());
                                    model.DvpgFailoverPolicyModel = DvpgFailoverPolicyModel;
                                })(model = common.model || (common.model = {}));
                            })(common = dvportgroup.common || (dvportgroup.common = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var switchdiagram;
                        (function (switchdiagram) {
                            var model;
                            (function (model) {
                                var details;
                                (function (details) {
                                    var VmVnicAdapterDetails = (function () {
                                        function VmVnicAdapterDetails() {
                                            this._type = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.details.VmVnicAdapterDetails';
                                        }
                                        return VmVnicAdapterDetails;
                                    }());
                                    details.VmVnicAdapterDetails = VmVnicAdapterDetails;
                                })(details = model.details || (model.details = {}));
                            })(model = switchdiagram.model || (switchdiagram.model = {}));
                        })(switchdiagram = api.switchdiagram || (api.switchdiagram = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var addhost;
                            (function (addhost) {
                                var model;
                                (function (model) {
                                    var VmVnicSettingsData = (function () {
                                        function VmVnicSettingsData() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.addhost.model.VmVnicSettingsData';
                                        }
                                        return VmVnicSettingsData;
                                    }());
                                    model.VmVnicSettingsData = VmVnicSettingsData;
                                })(model = addhost.model || (addhost.model = {}));
                            })(addhost = dvs.addhost || (dvs.addhost = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var pvlan;
                            (function (pvlan) {
                                var model;
                                (function (model) {
                                    var DvsEditPvlanData = (function () {
                                        function DvsEditPvlanData() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.pvlan.model.DvsEditPvlanData';
                                        }
                                        return DvsEditPvlanData;
                                    }());
                                    model.DvsEditPvlanData = DvsEditPvlanData;
                                })(model = pvlan.model || (pvlan.model = {}));
                            })(pvlan = dvs.pvlan || (dvs.pvlan = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var exp;
                            (function (exp) {
                                var model;
                                (function (model) {
                                    var ExportDvsSpec = (function () {
                                        function ExportDvsSpec() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.export.model.ExportDvsSpec';
                                        }
                                        return ExportDvsSpec;
                                    }());
                                    model.ExportDvsSpec = ExportDvsSpec;
                                })(model = exp.model || (exp.model = {}));
                            })(exp = dvs.exp || (dvs.exp = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var vm;
                (function (vm) {
                    var tools;
                    (function (tools) {
                        var VmToolsInstallerSpec = (function () {
                            function VmToolsInstallerSpec() {
                                this._type = 'com.vmware.vsphere.client.vm.tools.VmToolsInstallerSpec';
                            }
                            return VmToolsInstallerSpec;
                        }());
                        tools.VmToolsInstallerSpec = VmToolsInstallerSpec;
                    })(tools = vm.tools || (vm.tools = {}));
                })(vm = client.vm || (client.vm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var upgrade;
                            (function (upgrade) {
                                var model;
                                (function (model) {
                                    var UpgradeDvsActionData = (function () {
                                        function UpgradeDvsActionData() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.upgrade.model.UpgradeDvsActionData';
                                        }
                                        return UpgradeDvsActionData;
                                    }());
                                    model.UpgradeDvsActionData = UpgradeDvsActionData;
                                })(model = upgrade.model || (upgrade.model = {}));
                            })(upgrade = dvs.upgrade || (dvs.upgrade = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var upgrade;
                            (function (upgrade) {
                                var model;
                                (function (model) {
                                    var DvsUpgradeWizardSpec = (function () {
                                        function DvsUpgradeWizardSpec() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.upgrade.model.DvsUpgradeWizardSpec';
                                        }
                                        return DvsUpgradeWizardSpec;
                                    }());
                                    model.DvsUpgradeWizardSpec = DvsUpgradeWizardSpec;
                                })(model = upgrade.model || (upgrade.model = {}));
                            })(upgrade = dvs.upgrade || (dvs.upgrade = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var ports;
                            (function (ports) {
                                var model;
                                (function (model) {
                                    var DvsPortsListData = (function () {
                                        function DvsPortsListData() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortsListData';
                                        }
                                        return DvsPortsListData;
                                    }());
                                    model.DvsPortsListData = DvsPortsListData;
                                })(model = ports.model || (ports.model = {}));
                            })(ports = dvs.ports || (dvs.ports = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var spbm;
                (function (spbm) {
                    var data;
                    (function (data) {
                        var profile;
                        (function (profile) {
                            var content;
                            (function (content) {
                                var SectionData = (function () {
                                    function SectionData() {
                                        this._type = 'com.vmware.vsphere.client.spbm.data.profile.content.SectionData';
                                    }
                                    return SectionData;
                                }());
                                content.SectionData = SectionData;
                            })(content = profile.content || (profile.content = {}));
                        })(profile = data.profile || (data.profile = {}));
                    })(data = spbm.data || (spbm.data = {}));
                })(spbm = client.spbm || (client.spbm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var addvnics;
                            (function (addvnics) {
                                var model;
                                (function (model) {
                                    var DvpgAddVnicsGatewayModel = (function () {
                                        function DvpgAddVnicsGatewayModel() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.addvnics.model.DvpgAddVnicsGatewayModel';
                                        }
                                        return DvpgAddVnicsGatewayModel;
                                    }());
                                    model.DvpgAddVnicsGatewayModel = DvpgAddVnicsGatewayModel;
                                })(model = addvnics.model || (addvnics.model = {}));
                            })(addvnics = dvportgroup.addvnics || (dvportgroup.addvnics = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var common;
                            (function (common) {
                                var model;
                                (function (model) {
                                    var DvpgTrafficShapingPolicyModel = (function () {
                                        function DvpgTrafficShapingPolicyModel() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgTrafficShapingPolicyModel';
                                        }
                                        return DvpgTrafficShapingPolicyModel;
                                    }());
                                    model.DvpgTrafficShapingPolicyModel = DvpgTrafficShapingPolicyModel;
                                })(model = common.model || (common.model = {}));
                            })(common = dvportgroup.common || (dvportgroup.common = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvpg;
                (function (dvpg) {
                    var api;
                    (function (api) {
                        var HostNetworkDetailsData$EnabledServices = (function () {
                            function HostNetworkDetailsData$EnabledServices() {
                                this._type = 'com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData$EnabledServices';
                            }
                            return HostNetworkDetailsData$EnabledServices;
                        }());
                        api.HostNetworkDetailsData$EnabledServices = HostNetworkDetailsData$EnabledServices;
                    })(api = dvpg.api || (dvpg.api = {}));
                })(dvpg = client.dvpg || (client.dvpg = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var addhost;
                            (function (addhost) {
                                var model;
                                (function (model) {
                                    var PnicAssignmentSpec = (function () {
                                        function PnicAssignmentSpec() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.addhost.model.PnicAssignmentSpec';
                                        }
                                        return PnicAssignmentSpec;
                                    }());
                                    model.PnicAssignmentSpec = PnicAssignmentSpec;
                                })(model = addhost.model || (addhost.model = {}));
                            })(addhost = dvs.addhost || (dvs.addhost = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var spbm;
                    (function (spbm) {
                        var data;
                        (function (data) {
                            var StorageProfileContainerCompatibilityData = (function () {
                                function StorageProfileContainerCompatibilityData() {
                                    this._type = 'com.vmware.vsphere.client.h5.spbm.data.StorageProfileContainerCompatibilityData';
                                }
                                return StorageProfileContainerCompatibilityData;
                            }());
                            data.StorageProfileContainerCompatibilityData = StorageProfileContainerCompatibilityData;
                        })(data = spbm.data || (spbm.data = {}));
                    })(spbm = h5.spbm || (h5.spbm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var ports;
                            (function (ports) {
                                var model;
                                (function (model) {
                                    var DvsPortPropertiesModel$NetworkResourcePoolData = (function () {
                                        function DvsPortPropertiesModel$NetworkResourcePoolData() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortPropertiesModel$NetworkResourcePoolData';
                                        }
                                        return DvsPortPropertiesModel$NetworkResourcePoolData;
                                    }());
                                    model.DvsPortPropertiesModel$NetworkResourcePoolData = DvsPortPropertiesModel$NetworkResourcePoolData;
                                })(model = ports.model || (ports.model = {}));
                            })(ports = dvs.ports || (dvs.ports = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var vm;
                (function (vm) {
                    var ft;
                    (function (ft) {
                        var VmRestartSecondarySpec = (function () {
                            function VmRestartSecondarySpec() {
                                this._type = 'com.vmware.vsphere.client.vm.ft.VmRestartSecondarySpec';
                            }
                            return VmRestartSecondarySpec;
                        }());
                        ft.VmRestartSecondarySpec = VmRestartSecondarySpec;
                    })(ft = vm.ft || (vm.ft = {}));
                })(vm = client.vm || (client.vm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vm;
                    (function (vm) {
                        var data;
                        (function (data) {
                            var VmCryptoUnlockSpec = (function () {
                                function VmCryptoUnlockSpec() {
                                    this._type = 'com.vmware.vsphere.client.h5.vm.data.VmCryptoUnlockSpec';
                                }
                                return VmCryptoUnlockSpec;
                            }());
                            data.VmCryptoUnlockSpec = VmCryptoUnlockSpec;
                        })(data = vm.data || (vm.data = {}));
                    })(vm = h5.vm || (h5.vm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var common;
                            (function (common) {
                                var model;
                                (function (model) {
                                    var DvpgMiscPolicyModel = (function () {
                                        function DvpgMiscPolicyModel() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgMiscPolicyModel';
                                        }
                                        return DvpgMiscPolicyModel;
                                    }());
                                    model.DvpgMiscPolicyModel = DvpgMiscPolicyModel;
                                })(model = common.model || (common.model = {}));
                            })(common = dvportgroup.common || (dvportgroup.common = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var ports;
                            (function (ports) {
                                var model;
                                (function (model) {
                                    var DvsPortMiscPolicyModel = (function (_super) {
                                        __extends(DvsPortMiscPolicyModel, _super);
                                        function DvsPortMiscPolicyModel() {
                                            _super.apply(this, arguments);
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortMiscPolicyModel';
                                        }
                                        return DvsPortMiscPolicyModel;
                                    }(com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgMiscPolicyModel));
                                    model.DvsPortMiscPolicyModel = DvsPortMiscPolicyModel;
                                })(model = ports.model || (ports.model = {}));
                            })(ports = dvs.ports || (dvs.ports = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var ports;
                            (function (ports) {
                                var model;
                                (function (model) {
                                    var DvsPortTrafficFilterModel = (function () {
                                        function DvsPortTrafficFilterModel() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortTrafficFilterModel';
                                        }
                                        return DvsPortTrafficFilterModel;
                                    }());
                                    model.DvsPortTrafficFilterModel = DvsPortTrafficFilterModel;
                                })(model = ports.model || (ports.model = {}));
                            })(ports = dvs.ports || (dvs.ports = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var trafficfilter;
                            (function (trafficfilter) {
                                var model;
                                (function (model) {
                                    var DvpgProtocolModel = (function () {
                                        function DvpgProtocolModel() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgProtocolModel';
                                        }
                                        return DvpgProtocolModel;
                                    }());
                                    model.DvpgProtocolModel = DvpgProtocolModel;
                                })(model = trafficfilter.model || (trafficfilter.model = {}));
                            })(trafficfilter = dvportgroup.trafficfilter || (dvportgroup.trafficfilter = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var resmgmt;
                            (function (resmgmt) {
                                var nrp;
                                (function (nrp) {
                                    var model;
                                    (function (model) {
                                        var DvsNetworkResourcePoolPortgroupsListData = (function () {
                                            function DvsNetworkResourcePoolPortgroupsListData() {
                                                this._type = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsNetworkResourcePoolPortgroupsListData';
                                            }
                                            return DvsNetworkResourcePoolPortgroupsListData;
                                        }());
                                        model.DvsNetworkResourcePoolPortgroupsListData = DvsNetworkResourcePoolPortgroupsListData;
                                    })(model = nrp.model || (nrp.model = {}));
                                })(nrp = resmgmt.nrp || (resmgmt.nrp = {}));
                            })(resmgmt = dvs.resmgmt || (dvs.resmgmt = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var switchdiagram;
                        (function (switchdiagram) {
                            var model;
                            (function (model) {
                                var SwitchDiagramBatchVmsSpec = (function () {
                                    function SwitchDiagramBatchVmsSpec() {
                                        this._type = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.SwitchDiagramBatchVmsSpec';
                                    }
                                    return SwitchDiagramBatchVmsSpec;
                                }());
                                model.SwitchDiagramBatchVmsSpec = SwitchDiagramBatchVmsSpec;
                            })(model = switchdiagram.model || (switchdiagram.model = {}));
                        })(switchdiagram = api.switchdiagram || (api.switchdiagram = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var spbm;
                (function (spbm) {
                    var data;
                    (function (data) {
                        var profile;
                        (function (profile) {
                            var content;
                            (function (content) {
                                var ProfileContent = (function () {
                                    function ProfileContent() {
                                        this._type = 'com.vmware.vsphere.client.spbm.data.profile.content.ProfileContent';
                                    }
                                    return ProfileContent;
                                }());
                                content.ProfileContent = ProfileContent;
                            })(content = profile.content || (profile.content = {}));
                        })(profile = data.profile || (data.profile = {}));
                    })(data = spbm.data || (spbm.data = {}));
                })(spbm = client.spbm || (client.spbm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var vm;
                (function (vm) {
                    var ft;
                    (function (ft) {
                        var VmCreateSecondarySpec = (function () {
                            function VmCreateSecondarySpec() {
                                this._type = 'com.vmware.vsphere.client.vm.ft.VmCreateSecondarySpec';
                            }
                            return VmCreateSecondarySpec;
                        }());
                        ft.VmCreateSecondarySpec = VmCreateSecondarySpec;
                    })(ft = vm.ft || (vm.ft = {}));
                })(vm = client.vm || (client.vm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var host;
                        (function (host) {
                            var netstack;
                            (function (netstack) {
                                var model;
                                (function (model) {
                                    var NetStackReconfigureSpec = (function () {
                                        function NetStackReconfigureSpec() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.host.netstack.model.NetStackReconfigureSpec';
                                        }
                                        return NetStackReconfigureSpec;
                                    }());
                                    model.NetStackReconfigureSpec = NetStackReconfigureSpec;
                                })(model = netstack.model || (netstack.model = {}));
                            })(netstack = host.netstack || (host.netstack = {}));
                        })(host = network.host || (network.host = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var addvnics;
                            (function (addvnics) {
                                var model;
                                (function (model) {
                                    var DvpgHostVnicIpModel = (function () {
                                        function DvpgHostVnicIpModel() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.addvnics.model.DvpgHostVnicIpModel';
                                        }
                                        return DvpgHostVnicIpModel;
                                    }());
                                    model.DvpgHostVnicIpModel = DvpgHostVnicIpModel;
                                })(model = addvnics.model || (addvnics.model = {}));
                            })(addvnics = dvportgroup.addvnics || (dvportgroup.addvnics = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var spbm;
                (function (spbm) {
                    var spec;
                    (function (spec) {
                        var AssignDrpSpec = (function () {
                            function AssignDrpSpec() {
                                this._type = 'com.vmware.vsphere.client.spbm.spec.AssignDrpSpec';
                            }
                            return AssignDrpSpec;
                        }());
                        spec.AssignDrpSpec = AssignDrpSpec;
                    })(spec = spbm.spec || (spbm.spec = {}));
                })(spbm = client.spbm || (client.spbm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var vcextension;
                (function (vcextension) {
                    var VcExtensionDetailsData = (function () {
                        function VcExtensionDetailsData() {
                            this._type = 'com.vmware.vsphere.client.vcextension.VcExtensionDetailsData';
                        }
                        return VcExtensionDetailsData;
                    }());
                    vcextension.VcExtensionDetailsData = VcExtensionDetailsData;
                })(vcextension = client.vcextension || (client.vcextension = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var host;
                        (function (host) {
                            var migratevmkadapter;
                            (function (migratevmkadapter) {
                                var model;
                                (function (model) {
                                    var DestinationStandardPortGroupInitialInfo = (function () {
                                        function DestinationStandardPortGroupInitialInfo() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.host.migratevmkadapter.model.DestinationStandardPortGroupInitialInfo';
                                        }
                                        return DestinationStandardPortGroupInitialInfo;
                                    }());
                                    model.DestinationStandardPortGroupInitialInfo = DestinationStandardPortGroupInitialInfo;
                                })(model = migratevmkadapter.model || (migratevmkadapter.model = {}));
                            })(migratevmkadapter = host.migratevmkadapter || (host.migratevmkadapter = {}));
                        })(host = network.host || (network.host = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vm;
                    (function (vm) {
                        var configuration;
                        (function (configuration) {
                            var LinuxTimeZoneArea = (function () {
                                function LinuxTimeZoneArea() {
                                    this._type = 'com.vmware.vsphere.client.h5.vm.configuration.LinuxTimeZoneArea';
                                }
                                return LinuxTimeZoneArea;
                            }());
                            configuration.LinuxTimeZoneArea = LinuxTimeZoneArea;
                        })(configuration = vm.configuration || (vm.configuration = {}));
                    })(vm = h5.vm || (h5.vm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var spec;
                        (function (spec) {
                            var DvsImportPortgroupSpec = (function () {
                                function DvsImportPortgroupSpec() {
                                    this._type = 'com.vmware.vsphere.client.dvs.api.spec.DvsImportPortgroupSpec';
                                }
                                return DvsImportPortgroupSpec;
                            }());
                            spec.DvsImportPortgroupSpec = DvsImportPortgroupSpec;
                        })(spec = api.spec || (api.spec = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var portmirroring;
                            (function (portmirroring) {
                                var model;
                                (function (model) {
                                    var portlist;
                                    (function (portlist) {
                                        var DvsSpanSessionPortListItemData = (function () {
                                            function DvsSpanSessionPortListItemData() {
                                                this._type = 'com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.portlist.DvsSpanSessionPortListItemData';
                                            }
                                            return DvsSpanSessionPortListItemData;
                                        }());
                                        portlist.DvsSpanSessionPortListItemData = DvsSpanSessionPortListItemData;
                                    })(portlist = model.portlist || (model.portlist = {}));
                                })(model = portmirroring.model || (portmirroring.model = {}));
                            })(portmirroring = dvs.portmirroring || (dvs.portmirroring = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var portmirroring;
                            (function (portmirroring) {
                                var model;
                                (function (model) {
                                    var dialog;
                                    (function (dialog) {
                                        var DvsEditSpanSessionDialogData = (function () {
                                            function DvsEditSpanSessionDialogData() {
                                                this._type = 'com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.dialog.DvsEditSpanSessionDialogData';
                                            }
                                            return DvsEditSpanSessionDialogData;
                                        }());
                                        dialog.DvsEditSpanSessionDialogData = DvsEditSpanSessionDialogData;
                                    })(dialog = model.dialog || (model.dialog = {}));
                                })(model = portmirroring.model || (portmirroring.model = {}));
                            })(portmirroring = dvs.portmirroring || (dvs.portmirroring = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var trafficfilter;
                            (function (trafficfilter) {
                                var DvpgEditRuleSequenceSpec = (function () {
                                    function DvpgEditRuleSequenceSpec() {
                                        this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgEditRuleSequenceSpec';
                                    }
                                    return DvpgEditRuleSequenceSpec;
                                }());
                                trafficfilter.DvpgEditRuleSequenceSpec = DvpgEditRuleSequenceSpec;
                            })(trafficfilter = dvportgroup.trafficfilter || (dvportgroup.trafficfilter = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var ports;
                            (function (ports) {
                                var model;
                                (function (model) {
                                    var DvsPortEditData = (function () {
                                        function DvsPortEditData() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortEditData';
                                        }
                                        return DvsPortEditData;
                                    }());
                                    model.DvsPortEditData = DvsPortEditData;
                                })(model = ports.model || (ports.model = {}));
                            })(ports = dvs.ports || (dvs.ports = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var ports;
                            (function (ports) {
                                var model;
                                (function (model) {
                                    var DvsPortEditTrafficRuleSpec = (function () {
                                        function DvsPortEditTrafficRuleSpec() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortEditTrafficRuleSpec';
                                        }
                                        return DvsPortEditTrafficRuleSpec;
                                    }());
                                    model.DvsPortEditTrafficRuleSpec = DvsPortEditTrafficRuleSpec;
                                })(model = ports.model || (ports.model = {}));
                            })(ports = dvs.ports || (dvs.ports = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var addhost;
                            (function (addhost) {
                                var model;
                                (function (model) {
                                    var VmVnicSettingsData$SriovInfo = (function () {
                                        function VmVnicSettingsData$SriovInfo() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.addhost.model.VmVnicSettingsData$SriovInfo';
                                        }
                                        return VmVnicSettingsData$SriovInfo;
                                    }());
                                    model.VmVnicSettingsData$SriovInfo = VmVnicSettingsData$SriovInfo;
                                })(model = addhost.model || (addhost.model = {}));
                            })(addhost = dvs.addhost || (dvs.addhost = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var vm;
                (function (vm) {
                    var ft;
                    (function (ft) {
                        var VmTestFailoverSpec = (function () {
                            function VmTestFailoverSpec() {
                                this._type = 'com.vmware.vsphere.client.vm.ft.VmTestFailoverSpec';
                            }
                            return VmTestFailoverSpec;
                        }());
                        ft.VmTestFailoverSpec = VmTestFailoverSpec;
                    })(ft = vm.ft || (vm.ft = {}));
                })(vm = client.vm || (client.vm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var vm;
                (function (vm) {
                    var customizations;
                    (function (customizations) {
                        var VmCustomizationDuplicateValidationResult = (function () {
                            function VmCustomizationDuplicateValidationResult() {
                                this._type = 'com.vmware.vsphere.client.vm.customizations.VmCustomizationDuplicateValidationResult';
                            }
                            return VmCustomizationDuplicateValidationResult;
                        }());
                        customizations.VmCustomizationDuplicateValidationResult = VmCustomizationDuplicateValidationResult;
                    })(customizations = vm.customizations || (vm.customizations = {}));
                })(vm = client.vm || (client.vm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var storage;
                    (function (storage) {
                        var data;
                        (function (data) {
                            var datastore;
                            (function (datastore) {
                                var vvol;
                                (function (vvol) {
                                    var VvolStorageProvidersViewData = (function () {
                                        function VvolStorageProvidersViewData() {
                                            this._type = 'com.vmware.vsphere.client.h5.storage.data.datastore.vvol.VvolStorageProvidersViewData';
                                        }
                                        return VvolStorageProvidersViewData;
                                    }());
                                    vvol.VvolStorageProvidersViewData = VvolStorageProvidersViewData;
                                })(vvol = datastore.vvol || (datastore.vvol = {}));
                            })(datastore = data.datastore || (data.datastore = {}));
                        })(data = storage.data || (storage.data = {}));
                    })(storage = h5.storage || (h5.storage = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var vm;
                (function (vm) {
                    var customizations;
                    (function (customizations) {
                        var VmCustomizationImportValidationResult = (function () {
                            function VmCustomizationImportValidationResult() {
                                this._type = 'com.vmware.vsphere.client.vm.customizations.VmCustomizationImportValidationResult';
                            }
                            return VmCustomizationImportValidationResult;
                        }());
                        customizations.VmCustomizationImportValidationResult = VmCustomizationImportValidationResult;
                    })(customizations = vm.customizations || (vm.customizations = {}));
                })(vm = client.vm || (client.vm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var spec;
                        (function (spec) {
                            var DvsUpdateHealthCheckSpec = (function () {
                                function DvsUpdateHealthCheckSpec() {
                                    this._type = 'com.vmware.vsphere.client.dvs.api.spec.DvsUpdateHealthCheckSpec';
                                }
                                return DvsUpdateHealthCheckSpec;
                            }());
                            spec.DvsUpdateHealthCheckSpec = DvsUpdateHealthCheckSpec;
                        })(spec = api.spec || (api.spec = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var host;
                        (function (host) {
                            var wizard;
                            (function (wizard) {
                                var addnetworking;
                                (function (addnetworking) {
                                    var model;
                                    (function (model) {
                                        var PnicBindingDataItem = (function () {
                                            function PnicBindingDataItem() {
                                                this._type = 'com.vmware.vsphere.client.h5.network.host.wizard.addnetworking.model.PnicBindingDataItem';
                                            }
                                            return PnicBindingDataItem;
                                        }());
                                        model.PnicBindingDataItem = PnicBindingDataItem;
                                    })(model = addnetworking.model || (addnetworking.model = {}));
                                })(addnetworking = wizard.addnetworking || (wizard.addnetworking = {}));
                            })(wizard = host.wizard || (host.wizard = {}));
                        })(host = network.host || (network.host = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvpg;
                (function (dvpg) {
                    var api;
                    (function (api) {
                        var HostNetworkDetailsData$PortPolicy$Lacp = (function () {
                            function HostNetworkDetailsData$PortPolicy$Lacp() {
                                this._type = 'com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData$PortPolicy$Lacp';
                            }
                            return HostNetworkDetailsData$PortPolicy$Lacp;
                        }());
                        api.HostNetworkDetailsData$PortPolicy$Lacp = HostNetworkDetailsData$PortPolicy$Lacp;
                    })(api = dvpg.api || (dvpg.api = {}));
                })(dvpg = client.dvpg || (client.dvpg = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vm;
                    (function (vm) {
                        var ValidateNameSpec = (function () {
                            function ValidateNameSpec() {
                                this._type = 'com.vmware.vsphere.client.h5.vm.ValidateNameSpec';
                            }
                            return ValidateNameSpec;
                        }());
                        vm.ValidateNameSpec = ValidateNameSpec;
                    })(vm = h5.vm || (h5.vm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vapp;
                    (function (vapp) {
                        var data;
                        (function (data) {
                            var SourceVappData = (function () {
                                function SourceVappData() {
                                    this._type = 'com.vmware.vsphere.client.h5.vapp.data.SourceVappData';
                                }
                                return SourceVappData;
                            }());
                            data.SourceVappData = SourceVappData;
                        })(data = vapp.data || (vapp.data = {}));
                    })(vapp = h5.vapp || (h5.vapp = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vm;
                    (function (vm) {
                        var data;
                        (function (data) {
                            var MigrateOrMoveValidationSpec = (function () {
                                function MigrateOrMoveValidationSpec() {
                                    this._type = 'com.vmware.vsphere.client.h5.vm.data.MigrateOrMoveValidationSpec';
                                }
                                return MigrateOrMoveValidationSpec;
                            }());
                            data.MigrateOrMoveValidationSpec = MigrateOrMoveValidationSpec;
                        })(data = vm.data || (vm.data = {}));
                    })(vm = h5.vm || (h5.vm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var hostprofile;
                (function (hostprofile) {
                    var data;
                    (function (data) {
                        var MissingTypeData = (function () {
                            function MissingTypeData() {
                                this._type = 'com.vmware.vsphere.client.hostprofile.data.MissingTypeData';
                            }
                            return MissingTypeData;
                        }());
                        data.MissingTypeData = MissingTypeData;
                    })(data = hostprofile.data || (hostprofile.data = {}));
                })(hostprofile = client.hostprofile || (client.hostprofile = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var vm;
                (function (vm) {
                    var config;
                    (function (config) {
                        var VmConfigEnvironment = (function () {
                            function VmConfigEnvironment() {
                                this._type = 'com.vmware.vsphere.client.vm.config.VmConfigEnvironment';
                            }
                            return VmConfigEnvironment;
                        }());
                        config.VmConfigEnvironment = VmConfigEnvironment;
                    })(config = vm.config || (vm.config = {}));
                })(vm = client.vm || (client.vm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var spbm;
                    (function (spbm) {
                        var data;
                        (function (data) {
                            var VmStorageProfileComponentsData = (function () {
                                function VmStorageProfileComponentsData() {
                                    this._type = 'com.vmware.vsphere.client.h5.spbm.data.VmStorageProfileComponentsData';
                                }
                                return VmStorageProfileComponentsData;
                            }());
                            data.VmStorageProfileComponentsData = VmStorageProfileComponentsData;
                        })(data = spbm.data || (spbm.data = {}));
                    })(spbm = h5.spbm || (h5.spbm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vm;
                    (function (vm) {
                        var model;
                        (function (model) {
                            var VmStoragePageData = (function () {
                                function VmStoragePageData() {
                                    this._type = 'com.vmware.vsphere.client.h5.vm.model.VmStoragePageData';
                                }
                                return VmStoragePageData;
                            }());
                            model.VmStoragePageData = VmStoragePageData;
                        })(model = vm.model || (vm.model = {}));
                    })(vm = h5.vm || (h5.vm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var spec;
                        (function (spec) {
                            var DvpgRestoreSectionRepresentationSpec = (function () {
                                function DvpgRestoreSectionRepresentationSpec() {
                                    this._type = 'com.vmware.vsphere.client.dvs.api.spec.DvpgRestoreSectionRepresentationSpec';
                                }
                                return DvpgRestoreSectionRepresentationSpec;
                            }());
                            spec.DvpgRestoreSectionRepresentationSpec = DvpgRestoreSectionRepresentationSpec;
                        })(spec = api.spec || (api.spec = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var portmirroring;
                            (function (portmirroring) {
                                var model;
                                (function (model) {
                                    var DvsPortMirroringData = (function () {
                                        function DvsPortMirroringData() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.DvsPortMirroringData';
                                        }
                                        return DvsPortMirroringData;
                                    }());
                                    model.DvsPortMirroringData = DvsPortMirroringData;
                                })(model = portmirroring.model || (portmirroring.model = {}));
                            })(portmirroring = dvs.portmirroring || (dvs.portmirroring = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var addhost;
                            (function (addhost) {
                                var model;
                                (function (model) {
                                    var DvsAddAndManageHostsSpec = (function () {
                                        function DvsAddAndManageHostsSpec() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.addhost.model.DvsAddAndManageHostsSpec';
                                        }
                                        return DvsAddAndManageHostsSpec;
                                    }());
                                    model.DvsAddAndManageHostsSpec = DvsAddAndManageHostsSpec;
                                })(model = addhost.model || (addhost.model = {}));
                            })(addhost = dvs.addhost || (dvs.addhost = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var hostprofile;
                (function (hostprofile) {
                    var data;
                    (function (data) {
                        var h5;
                        (function (h5) {
                            var HostProfileCheckComplianceSpec = (function () {
                                function HostProfileCheckComplianceSpec() {
                                    this._type = 'com.vmware.vsphere.client.hostprofile.data.h5.HostProfileCheckComplianceSpec';
                                }
                                return HostProfileCheckComplianceSpec;
                            }());
                            h5.HostProfileCheckComplianceSpec = HostProfileCheckComplianceSpec;
                        })(h5 = data.h5 || (data.h5 = {}));
                    })(data = hostprofile.data || (hostprofile.data = {}));
                })(hostprofile = client.hostprofile || (client.hostprofile = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var switchdiagram;
                        (function (switchdiagram) {
                            var model;
                            (function (model) {
                                var details;
                                (function (details) {
                                    var VmVnicResourceAllocationModel = (function () {
                                        function VmVnicResourceAllocationModel() {
                                            this._type = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.details.VmVnicResourceAllocationModel';
                                        }
                                        return VmVnicResourceAllocationModel;
                                    }());
                                    details.VmVnicResourceAllocationModel = VmVnicResourceAllocationModel;
                                })(details = model.details || (model.details = {}));
                            })(model = switchdiagram.model || (switchdiagram.model = {}));
                        })(switchdiagram = api.switchdiagram || (api.switchdiagram = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vm;
                    (function (vm) {
                        var model;
                        (function (model) {
                            var migration;
                            (function (migration) {
                                var VmMigrationCancelRecommendationsSpec = (function () {
                                    function VmMigrationCancelRecommendationsSpec() {
                                        this._type = 'com.vmware.vsphere.client.h5.vm.model.migration.VmMigrationCancelRecommendationsSpec';
                                    }
                                    return VmMigrationCancelRecommendationsSpec;
                                }());
                                migration.VmMigrationCancelRecommendationsSpec = VmMigrationCancelRecommendationsSpec;
                            })(migration = model.migration || (model.migration = {}));
                        })(model = vm.model || (vm.model = {}));
                    })(vm = h5.vm || (h5.vm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var addvnics;
                            (function (addvnics) {
                                var model;
                                (function (model) {
                                    var DvpgHostVnicsSettingsModel = (function () {
                                        function DvpgHostVnicsSettingsModel() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.addvnics.model.DvpgHostVnicsSettingsModel';
                                        }
                                        return DvpgHostVnicsSettingsModel;
                                    }());
                                    model.DvpgHostVnicsSettingsModel = DvpgHostVnicsSettingsModel;
                                })(model = addvnics.model || (addvnics.model = {}));
                            })(addvnics = dvportgroup.addvnics || (dvportgroup.addvnics = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var portmirroring;
                            (function (portmirroring) {
                                var model;
                                (function (model) {
                                    var DvsPortMirroringSessionData = (function () {
                                        function DvsPortMirroringSessionData() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.DvsPortMirroringSessionData';
                                        }
                                        return DvsPortMirroringSessionData;
                                    }());
                                    model.DvsPortMirroringSessionData = DvsPortMirroringSessionData;
                                })(model = portmirroring.model || (portmirroring.model = {}));
                            })(portmirroring = dvs.portmirroring || (dvs.portmirroring = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var vm;
                (function (vm) {
                    var customizations;
                    (function (customizations) {
                        var VmCustomizationImportSpec = (function () {
                            function VmCustomizationImportSpec() {
                                this._type = 'com.vmware.vsphere.client.vm.customizations.VmCustomizationImportSpec';
                            }
                            return VmCustomizationImportSpec;
                        }());
                        customizations.VmCustomizationImportSpec = VmCustomizationImportSpec;
                    })(customizations = vm.customizations || (vm.customizations = {}));
                })(vm = client.vm || (client.vm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vm;
                    (function (vm) {
                        var model;
                        (function (model) {
                            var provisioning;
                            (function (provisioning) {
                                var VmConfigEnvironmentPerPoolParams = (function () {
                                    function VmConfigEnvironmentPerPoolParams() {
                                        this._type = 'com.vmware.vsphere.client.h5.vm.model.provisioning.VmConfigEnvironmentPerPoolParams';
                                    }
                                    return VmConfigEnvironmentPerPoolParams;
                                }());
                                provisioning.VmConfigEnvironmentPerPoolParams = VmConfigEnvironmentPerPoolParams;
                            })(provisioning = model.provisioning || (model.provisioning = {}));
                        })(model = vm.model || (vm.model = {}));
                    })(vm = h5.vm || (h5.vm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvportgroup;
                (function (dvportgroup) {
                    var DvPortgroupCreateSpec = (function () {
                        function DvPortgroupCreateSpec() {
                            this._type = 'com.vmware.vsphere.client.dvportgroup.DvPortgroupCreateSpec';
                        }
                        return DvPortgroupCreateSpec;
                    }());
                    dvportgroup.DvPortgroupCreateSpec = DvPortgroupCreateSpec;
                })(dvportgroup = client.dvportgroup || (client.dvportgroup = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvpg;
                (function (dvpg) {
                    var api;
                    (function (api) {
                        var HostNetworkDetailsData$PortPolicy$TrafficShaping = (function () {
                            function HostNetworkDetailsData$PortPolicy$TrafficShaping() {
                                this._type = 'com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData$PortPolicy$TrafficShaping';
                            }
                            return HostNetworkDetailsData$PortPolicy$TrafficShaping;
                        }());
                        api.HostNetworkDetailsData$PortPolicy$TrafficShaping = HostNetworkDetailsData$PortPolicy$TrafficShaping;
                    })(api = dvpg.api || (dvpg.api = {}));
                })(dvpg = client.dvpg || (client.dvpg = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var spbm;
                (function (spbm) {
                    var spec;
                    (function (spec) {
                        var CalculateProvisioningCostSpec = (function (_super) {
                            __extends(CalculateProvisioningCostSpec, _super);
                            function CalculateProvisioningCostSpec() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vsphere.client.spbm.spec.CalculateProvisioningCostSpec';
                            }
                            return CalculateProvisioningCostSpec;
                        }(spec.CalculateCostSpecBase));
                        spec.CalculateProvisioningCostSpec = CalculateProvisioningCostSpec;
                    })(spec = spbm.spec || (spbm.spec = {}));
                })(spbm = client.spbm || (client.spbm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var resmgmt;
                            (function (resmgmt) {
                                var nrp;
                                (function (nrp) {
                                    var model;
                                    (function (model) {
                                        var DvsNetworkResourcePoolVmsListData = (function () {
                                            function DvsNetworkResourcePoolVmsListData() {
                                                this._type = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsNetworkResourcePoolVmsListData';
                                            }
                                            return DvsNetworkResourcePoolVmsListData;
                                        }());
                                        model.DvsNetworkResourcePoolVmsListData = DvsNetworkResourcePoolVmsListData;
                                    })(model = nrp.model || (nrp.model = {}));
                                })(nrp = resmgmt.nrp || (resmgmt.nrp = {}));
                            })(resmgmt = dvs.resmgmt || (dvs.resmgmt = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var common;
                            (function (common) {
                                var model;
                                (function (model) {
                                    var DvpgTrafficShapingPolicyModel$TrafficShapingPolicy = (function () {
                                        function DvpgTrafficShapingPolicyModel$TrafficShapingPolicy() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgTrafficShapingPolicyModel$TrafficShapingPolicy';
                                        }
                                        return DvpgTrafficShapingPolicyModel$TrafficShapingPolicy;
                                    }());
                                    model.DvpgTrafficShapingPolicyModel$TrafficShapingPolicy = DvpgTrafficShapingPolicyModel$TrafficShapingPolicy;
                                })(model = common.model || (common.model = {}));
                            })(common = dvportgroup.common || (dvportgroup.common = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var addhost;
                            (function (addhost) {
                                var model;
                                (function (model) {
                                    var DvsAddHostUplinkData = (function () {
                                        function DvsAddHostUplinkData() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.addhost.model.DvsAddHostUplinkData';
                                        }
                                        return DvsAddHostUplinkData;
                                    }());
                                    model.DvsAddHostUplinkData = DvsAddHostUplinkData;
                                })(model = addhost.model || (addhost.model = {}));
                            })(addhost = dvs.addhost || (dvs.addhost = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vm;
                    (function (vm) {
                        var data;
                        (function (data) {
                            var VmEvcSpec = (function () {
                                function VmEvcSpec() {
                                    this._type = 'com.vmware.vsphere.client.h5.vm.data.VmEvcSpec';
                                }
                                return VmEvcSpec;
                            }());
                            data.VmEvcSpec = VmEvcSpec;
                        })(data = vm.data || (vm.data = {}));
                    })(vm = h5.vm || (h5.vm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var vm;
                        (function (vm) {
                            var migrate;
                            (function (migrate) {
                                var model;
                                (function (model) {
                                    var MigrateVmNetworkDragAndDropResponse = (function () {
                                        function MigrateVmNetworkDragAndDropResponse() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.vm.migrate.model.MigrateVmNetworkDragAndDropResponse';
                                        }
                                        return MigrateVmNetworkDragAndDropResponse;
                                    }());
                                    model.MigrateVmNetworkDragAndDropResponse = MigrateVmNetworkDragAndDropResponse;
                                })(model = migrate.model || (migrate.model = {}));
                            })(migrate = vm.migrate || (vm.migrate = {}));
                        })(vm = network.vm || (network.vm = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var ports;
                            (function (ports) {
                                var model;
                                (function (model) {
                                    var DvsPortTrunkVlanSpec = (function () {
                                        function DvsPortTrunkVlanSpec() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortTrunkVlanSpec';
                                        }
                                        return DvsPortTrunkVlanSpec;
                                    }());
                                    model.DvsPortTrunkVlanSpec = DvsPortTrunkVlanSpec;
                                })(model = ports.model || (ports.model = {}));
                            })(ports = dvs.ports || (dvs.ports = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var vm;
                (function (vm) {
                    var migration;
                    (function (migration) {
                        var LocationSpecPair = (function () {
                            function LocationSpecPair() {
                                this._type = 'com.vmware.vsphere.client.vm.migration.LocationSpecPair';
                            }
                            return LocationSpecPair;
                        }());
                        migration.LocationSpecPair = LocationSpecPair;
                    })(migration = vm.migration || (vm.migration = {}));
                })(vm = client.vm || (client.vm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vm;
                    (function (vm) {
                        var model;
                        (function (model) {
                            var VmAdvancedStoragePageData = (function () {
                                function VmAdvancedStoragePageData() {
                                    this._type = 'com.vmware.vsphere.client.h5.vm.model.VmAdvancedStoragePageData';
                                }
                                return VmAdvancedStoragePageData;
                            }());
                            model.VmAdvancedStoragePageData = VmAdvancedStoragePageData;
                        })(model = vm.model || (vm.model = {}));
                    })(vm = h5.vm || (h5.vm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var host;
                        (function (host) {
                            var proxyswitch;
                            (function (proxyswitch) {
                                var spec;
                                (function (spec) {
                                    var UpdateProxySwitchUplinkMappingSpec = (function () {
                                        function UpdateProxySwitchUplinkMappingSpec() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.host.proxyswitch.spec.UpdateProxySwitchUplinkMappingSpec';
                                        }
                                        return UpdateProxySwitchUplinkMappingSpec;
                                    }());
                                    spec.UpdateProxySwitchUplinkMappingSpec = UpdateProxySwitchUplinkMappingSpec;
                                })(spec = proxyswitch.spec || (proxyswitch.spec = {}));
                            })(proxyswitch = host.proxyswitch || (host.proxyswitch = {}));
                        })(host = network.host || (network.host = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var vm;
                (function (vm) {
                    var customizations;
                    (function (customizations) {
                        var VmCustomizationDuplicateSpec = (function () {
                            function VmCustomizationDuplicateSpec() {
                                this._type = 'com.vmware.vsphere.client.vm.customizations.VmCustomizationDuplicateSpec';
                            }
                            return VmCustomizationDuplicateSpec;
                        }());
                        customizations.VmCustomizationDuplicateSpec = VmCustomizationDuplicateSpec;
                    })(customizations = vm.customizations || (vm.customizations = {}));
                })(vm = client.vm || (client.vm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var vm;
                (function (vm) {
                    var ft;
                    (function (ft) {
                        var VmDisableSecondarySpec = (function () {
                            function VmDisableSecondarySpec() {
                                this._type = 'com.vmware.vsphere.client.vm.ft.VmDisableSecondarySpec';
                            }
                            return VmDisableSecondarySpec;
                        }());
                        ft.VmDisableSecondarySpec = VmDisableSecondarySpec;
                    })(ft = vm.ft || (vm.ft = {}));
                })(vm = client.vm || (client.vm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var vm;
                (function (vm) {
                    var config;
                    (function (config) {
                        var VmConfigPefMetrics = (function () {
                            function VmConfigPefMetrics() {
                                this._type = 'com.vmware.vsphere.client.vm.config.VmConfigPefMetrics';
                            }
                            return VmConfigPefMetrics;
                        }());
                        config.VmConfigPefMetrics = VmConfigPefMetrics;
                    })(config = vm.config || (vm.config = {}));
                })(vm = client.vm || (client.vm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var spec;
                        (function (spec) {
                            var DvPortgroupRestoreInfoWrapperSpec = (function () {
                                function DvPortgroupRestoreInfoWrapperSpec() {
                                    this._type = 'com.vmware.vsphere.client.dvs.api.spec.DvPortgroupRestoreInfoWrapperSpec';
                                }
                                return DvPortgroupRestoreInfoWrapperSpec;
                            }());
                            spec.DvPortgroupRestoreInfoWrapperSpec = DvPortgroupRestoreInfoWrapperSpec;
                        })(spec = api.spec || (api.spec = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var port;
                        (function (port) {
                            var DvsPortData = (function () {
                                function DvsPortData() {
                                    this._type = 'com.vmware.vsphere.client.dvs.api.port.DvsPortData';
                                }
                                return DvsPortData;
                            }());
                            port.DvsPortData = DvsPortData;
                        })(port = api.port || (api.port = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var ports;
                            (function (ports) {
                                var model;
                                (function (model) {
                                    var DvsPortData = (function () {
                                        function DvsPortData() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortData';
                                        }
                                        return DvsPortData;
                                    }());
                                    model.DvsPortData = DvsPortData;
                                })(model = ports.model || (ports.model = {}));
                            })(ports = dvs.ports || (dvs.ports = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var spec;
                        (function (spec) {
                            var DvsApplyProductSpecCommandSpec = (function () {
                                function DvsApplyProductSpecCommandSpec() {
                                    this._type = 'com.vmware.vsphere.client.dvs.api.spec.DvsApplyProductSpecCommandSpec';
                                }
                                return DvsApplyProductSpecCommandSpec;
                            }());
                            spec.DvsApplyProductSpecCommandSpec = DvsApplyProductSpecCommandSpec;
                        })(spec = api.spec || (api.spec = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var trafficfilter;
                            (function (trafficfilter) {
                                var model;
                                (function (model) {
                                    var DvpgMacAddressModel = (function () {
                                        function DvpgMacAddressModel() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgMacAddressModel';
                                        }
                                        return DvpgMacAddressModel;
                                    }());
                                    model.DvpgMacAddressModel = DvpgMacAddressModel;
                                })(model = trafficfilter.model || (trafficfilter.model = {}));
                            })(trafficfilter = dvportgroup.trafficfilter || (dvportgroup.trafficfilter = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var vm;
                (function (vm) {
                    var config;
                    (function (config) {
                        var VmCdromContext = (function () {
                            function VmCdromContext() {
                                this._type = 'com.vmware.vsphere.client.vm.config.VmCdromContext';
                            }
                            return VmCdromContext;
                        }());
                        config.VmCdromContext = VmCdromContext;
                    })(config = vm.config || (vm.config = {}));
                })(vm = client.vm || (client.vm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var trafficfilter;
                            (function (trafficfilter) {
                                var DvpgRemoveTrafficRuleSpec = (function () {
                                    function DvpgRemoveTrafficRuleSpec() {
                                        this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgRemoveTrafficRuleSpec';
                                    }
                                    return DvpgRemoveTrafficRuleSpec;
                                }());
                                trafficfilter.DvpgRemoveTrafficRuleSpec = DvpgRemoveTrafficRuleSpec;
                            })(trafficfilter = dvportgroup.trafficfilter || (dvportgroup.trafficfilter = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var trafficfilter;
                            (function (trafficfilter) {
                                var DvpgTrafficFilterRule = (function () {
                                    function DvpgTrafficFilterRule() {
                                        this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgTrafficFilterRule';
                                    }
                                    return DvpgTrafficFilterRule;
                                }());
                                trafficfilter.DvpgTrafficFilterRule = DvpgTrafficFilterRule;
                            })(trafficfilter = dvportgroup.trafficfilter || (dvportgroup.trafficfilter = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var host;
                        (function (host) {
                            var proxyswitch;
                            (function (proxyswitch) {
                                var model;
                                (function (model) {
                                    var ProxySwitchUplinkData = (function () {
                                        function ProxySwitchUplinkData() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.host.proxyswitch.model.ProxySwitchUplinkData';
                                        }
                                        return ProxySwitchUplinkData;
                                    }());
                                    model.ProxySwitchUplinkData = ProxySwitchUplinkData;
                                })(model = proxyswitch.model || (proxyswitch.model = {}));
                            })(proxyswitch = host.proxyswitch || (host.proxyswitch = {}));
                        })(host = network.host || (network.host = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var switchdiagram;
                        (function (switchdiagram) {
                            var model;
                            (function (model) {
                                var BaseSwitchDiagramComponent = (function () {
                                    function BaseSwitchDiagramComponent() {
                                        this._type = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.BaseSwitchDiagramComponent';
                                    }
                                    return BaseSwitchDiagramComponent;
                                }());
                                model.BaseSwitchDiagramComponent = BaseSwitchDiagramComponent;
                            })(model = switchdiagram.model || (switchdiagram.model = {}));
                        })(switchdiagram = api.switchdiagram || (api.switchdiagram = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var switchdiagram;
                        (function (switchdiagram) {
                            var model;
                            (function (model) {
                                var UplinkPortModel = (function (_super) {
                                    __extends(UplinkPortModel, _super);
                                    function UplinkPortModel() {
                                        _super.apply(this, arguments);
                                        this._type = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.UplinkPortModel';
                                    }
                                    return UplinkPortModel;
                                }(model.BaseSwitchDiagramComponent));
                                model.UplinkPortModel = UplinkPortModel;
                            })(model = switchdiagram.model || (switchdiagram.model = {}));
                        })(switchdiagram = api.switchdiagram || (api.switchdiagram = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var switchdiagram;
                        (function (switchdiagram) {
                            var model;
                            (function (model) {
                                var PortModel = (function (_super) {
                                    __extends(PortModel, _super);
                                    function PortModel() {
                                        _super.apply(this, arguments);
                                        this._type = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.PortModel';
                                    }
                                    return PortModel;
                                }(model.BaseSwitchDiagramComponent));
                                model.PortModel = PortModel;
                            })(model = switchdiagram.model || (switchdiagram.model = {}));
                        })(switchdiagram = api.switchdiagram || (api.switchdiagram = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var switchdiagram;
                        (function (switchdiagram) {
                            var model;
                            (function (model) {
                                var PortGroupModel = (function (_super) {
                                    __extends(PortGroupModel, _super);
                                    function PortGroupModel() {
                                        _super.apply(this, arguments);
                                        this._type = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.PortGroupModel';
                                    }
                                    return PortGroupModel;
                                }(model.BaseSwitchDiagramComponent));
                                model.PortGroupModel = PortGroupModel;
                            })(model = switchdiagram.model || (switchdiagram.model = {}));
                        })(switchdiagram = api.switchdiagram || (api.switchdiagram = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var switchdiagram;
                        (function (switchdiagram) {
                            var model;
                            (function (model) {
                                var UplinkPortGroupModel = (function (_super) {
                                    __extends(UplinkPortGroupModel, _super);
                                    function UplinkPortGroupModel() {
                                        _super.apply(this, arguments);
                                        this._type = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.UplinkPortGroupModel';
                                    }
                                    return UplinkPortGroupModel;
                                }(model.BaseSwitchDiagramComponent));
                                model.UplinkPortGroupModel = UplinkPortGroupModel;
                            })(model = switchdiagram.model || (switchdiagram.model = {}));
                        })(switchdiagram = api.switchdiagram || (api.switchdiagram = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var healthcheck;
                            (function (healthcheck) {
                                var model;
                                (function (model) {
                                    var DvsHealthCheckDetailsData = (function () {
                                        function DvsHealthCheckDetailsData() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.healthcheck.model.DvsHealthCheckDetailsData';
                                        }
                                        return DvsHealthCheckDetailsData;
                                    }());
                                    model.DvsHealthCheckDetailsData = DvsHealthCheckDetailsData;
                                })(model = healthcheck.model || (healthcheck.model = {}));
                            })(healthcheck = dvs.healthcheck || (dvs.healthcheck = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var resmgmt;
                            (function (resmgmt) {
                                var nrp;
                                (function (nrp) {
                                    var model;
                                    (function (model) {
                                        var DvsNetworkResourcePoolsData = (function () {
                                            function DvsNetworkResourcePoolsData() {
                                                this._type = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsNetworkResourcePoolsData';
                                            }
                                            return DvsNetworkResourcePoolsData;
                                        }());
                                        model.DvsNetworkResourcePoolsData = DvsNetworkResourcePoolsData;
                                    })(model = nrp.model || (nrp.model = {}));
                                })(nrp = resmgmt.nrp || (resmgmt.nrp = {}));
                            })(resmgmt = dvs.resmgmt || (dvs.resmgmt = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var portmirroring;
                            (function (portmirroring) {
                                var model;
                                (function (model) {
                                    var wizard;
                                    (function (wizard) {
                                        var DvsSpanSessionDestinationUplinksData = (function () {
                                            function DvsSpanSessionDestinationUplinksData() {
                                                this._type = 'com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.wizard.DvsSpanSessionDestinationUplinksData';
                                            }
                                            return DvsSpanSessionDestinationUplinksData;
                                        }());
                                        wizard.DvsSpanSessionDestinationUplinksData = DvsSpanSessionDestinationUplinksData;
                                    })(wizard = model.wizard || (model.wizard = {}));
                                })(model = portmirroring.model || (portmirroring.model = {}));
                            })(portmirroring = dvs.portmirroring || (dvs.portmirroring = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var spec;
                        (function (spec) {
                            var ResControlUpgradeVersionCommandSpec = (function () {
                                function ResControlUpgradeVersionCommandSpec() {
                                    this._type = 'com.vmware.vsphere.client.dvs.api.spec.ResControlUpgradeVersionCommandSpec';
                                }
                                return ResControlUpgradeVersionCommandSpec;
                            }());
                            spec.ResControlUpgradeVersionCommandSpec = ResControlUpgradeVersionCommandSpec;
                        })(spec = api.spec || (api.spec = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvpg;
                (function (dvpg) {
                    var api;
                    (function (api) {
                        var DvPortgroupPortProperties = (function () {
                            function DvPortgroupPortProperties() {
                                this._type = 'com.vmware.vsphere.client.dvpg.api.DvPortgroupPortProperties';
                            }
                            return DvPortgroupPortProperties;
                        }());
                        api.DvPortgroupPortProperties = DvPortgroupPortProperties;
                    })(api = dvpg.api || (dvpg.api = {}));
                })(dvpg = client.dvpg || (client.dvpg = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vm;
                    (function (vm) {
                        var data;
                        (function (data) {
                            var VmConfigEnvironmentData = (function () {
                                function VmConfigEnvironmentData() {
                                    this._type = 'com.vmware.vsphere.client.h5.vm.data.VmConfigEnvironmentData';
                                }
                                return VmConfigEnvironmentData;
                            }());
                            data.VmConfigEnvironmentData = VmConfigEnvironmentData;
                        })(data = vm.data || (vm.data = {}));
                    })(vm = h5.vm || (h5.vm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vapp;
                    (function (vapp) {
                        var data;
                        (function (data) {
                            var ValidateMoveVappSpec = (function () {
                                function ValidateMoveVappSpec() {
                                    this._type = 'com.vmware.vsphere.client.h5.vapp.data.ValidateMoveVappSpec';
                                }
                                return ValidateMoveVappSpec;
                            }());
                            data.ValidateMoveVappSpec = ValidateMoveVappSpec;
                        })(data = vapp.data || (vapp.data = {}));
                    })(vapp = h5.vapp || (h5.vapp = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var vapp;
                (function (vapp) {
                    var VAppRemovalSpec = (function () {
                        function VAppRemovalSpec() {
                            this._type = 'com.vmware.vsphere.client.vapp.VAppRemovalSpec';
                        }
                        return VAppRemovalSpec;
                    }());
                    vapp.VAppRemovalSpec = VAppRemovalSpec;
                })(vapp = client.vapp || (client.vapp = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var addhost;
                            (function (addhost) {
                                var model;
                                (function (model) {
                                    var DvsHostsSpec = (function () {
                                        function DvsHostsSpec() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.addhost.model.DvsHostsSpec';
                                        }
                                        return DvsHostsSpec;
                                    }());
                                    model.DvsHostsSpec = DvsHostsSpec;
                                })(model = addhost.model || (addhost.model = {}));
                            })(addhost = dvs.addhost || (dvs.addhost = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vapp;
                    (function (vapp) {
                        var data;
                        (function (data) {
                            var ValidateSourceVappSpec = (function () {
                                function ValidateSourceVappSpec() {
                                    this._type = 'com.vmware.vsphere.client.h5.vapp.data.ValidateSourceVappSpec';
                                }
                                return ValidateSourceVappSpec;
                            }());
                            data.ValidateSourceVappSpec = ValidateSourceVappSpec;
                        })(data = vapp.data || (vapp.data = {}));
                    })(vapp = h5.vapp || (h5.vapp = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var healthcheck;
                            (function (healthcheck) {
                                var model;
                                (function (model) {
                                    var DvsHealthCheckItemData = (function () {
                                        function DvsHealthCheckItemData() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.healthcheck.model.DvsHealthCheckItemData';
                                        }
                                        return DvsHealthCheckItemData;
                                    }());
                                    model.DvsHealthCheckItemData = DvsHealthCheckItemData;
                                })(model = healthcheck.model || (healthcheck.model = {}));
                            })(healthcheck = dvs.healthcheck || (dvs.healthcheck = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vm;
                    (function (vm) {
                        var model;
                        (function (model) {
                            var MigrateOrMoveValidationData = (function () {
                                function MigrateOrMoveValidationData() {
                                    this._type = 'com.vmware.vsphere.client.h5.vm.model.MigrateOrMoveValidationData';
                                }
                                return MigrateOrMoveValidationData;
                            }());
                            model.MigrateOrMoveValidationData = MigrateOrMoveValidationData;
                        })(model = vm.model || (vm.model = {}));
                    })(vm = h5.vm || (h5.vm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vm;
                    (function (vm) {
                        var model;
                        (function (model) {
                            var provisioning;
                            (function (provisioning) {
                                var VmCreateScheduledTaskEditDefaultsData = (function () {
                                    function VmCreateScheduledTaskEditDefaultsData() {
                                        this._type = 'com.vmware.vsphere.client.h5.vm.model.provisioning.VmCreateScheduledTaskEditDefaultsData';
                                    }
                                    return VmCreateScheduledTaskEditDefaultsData;
                                }());
                                provisioning.VmCreateScheduledTaskEditDefaultsData = VmCreateScheduledTaskEditDefaultsData;
                            })(provisioning = model.provisioning || (model.provisioning = {}));
                        })(model = vm.model || (vm.model = {}));
                    })(vm = h5.vm || (h5.vm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvpg;
                (function (dvpg) {
                    var api;
                    (function (api) {
                        var HostNetworkDetailsData$PortPolicy$Vlan = (function () {
                            function HostNetworkDetailsData$PortPolicy$Vlan() {
                                this._type = 'com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData$PortPolicy$Vlan';
                            }
                            return HostNetworkDetailsData$PortPolicy$Vlan;
                        }());
                        api.HostNetworkDetailsData$PortPolicy$Vlan = HostNetworkDetailsData$PortPolicy$Vlan;
                    })(api = dvpg.api || (dvpg.api = {}));
                })(dvpg = client.dvpg || (client.dvpg = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var storage;
                (function (storage) {
                    var DatastoreCreateSpec = (function () {
                        function DatastoreCreateSpec() {
                            this._type = 'com.vmware.vsphere.client.storage.DatastoreCreateSpec';
                        }
                        return DatastoreCreateSpec;
                    }());
                    storage.DatastoreCreateSpec = DatastoreCreateSpec;
                })(storage = client.storage || (client.storage = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var storage;
                (function (storage) {
                    var vvol;
                    (function (vvol) {
                        var CreateVvolDatastoreSpec = (function (_super) {
                            __extends(CreateVvolDatastoreSpec, _super);
                            function CreateVvolDatastoreSpec() {
                                _super.apply(this, arguments);
                                this._type = 'com.vmware.vsphere.client.storage.vvol.CreateVvolDatastoreSpec';
                            }
                            return CreateVvolDatastoreSpec;
                        }(com.vmware.vsphere.client.storage.DatastoreCreateSpec));
                        vvol.CreateVvolDatastoreSpec = CreateVvolDatastoreSpec;
                    })(vvol = storage.vvol || (storage.vvol = {}));
                })(storage = client.storage || (client.storage = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvpg;
                (function (dvpg) {
                    var api;
                    (function (api) {
                        var DvPortgroupDetailsData = (function () {
                            function DvPortgroupDetailsData() {
                                this._type = 'com.vmware.vsphere.client.dvpg.api.DvPortgroupDetailsData';
                            }
                            return DvPortgroupDetailsData;
                        }());
                        api.DvPortgroupDetailsData = DvPortgroupDetailsData;
                    })(api = dvpg.api || (dvpg.api = {}));
                })(dvpg = client.dvpg || (client.dvpg = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var healthcheck;
                            (function (healthcheck) {
                                var model;
                                (function (model) {
                                    var DvsHealthCheckDetailsItemData = (function () {
                                        function DvsHealthCheckDetailsItemData() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.healthcheck.model.DvsHealthCheckDetailsItemData';
                                        }
                                        return DvsHealthCheckDetailsItemData;
                                    }());
                                    model.DvsHealthCheckDetailsItemData = DvsHealthCheckDetailsItemData;
                                })(model = healthcheck.model || (healthcheck.model = {}));
                            })(healthcheck = dvs.healthcheck || (dvs.healthcheck = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var resmgmt;
                            (function (resmgmt) {
                                var nrp;
                                (function (nrp) {
                                    var model;
                                    (function (model) {
                                        var DvsNetworkResourcePoolsMeterData = (function () {
                                            function DvsNetworkResourcePoolsMeterData() {
                                                this._type = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsNetworkResourcePoolsMeterData';
                                            }
                                            return DvsNetworkResourcePoolsMeterData;
                                        }());
                                        model.DvsNetworkResourcePoolsMeterData = DvsNetworkResourcePoolsMeterData;
                                    })(model = nrp.model || (nrp.model = {}));
                                })(nrp = resmgmt.nrp || (resmgmt.nrp = {}));
                            })(resmgmt = dvs.resmgmt || (dvs.resmgmt = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var network;
                (function (network) {
                    var PhysicalNicEditSpec = (function () {
                        function PhysicalNicEditSpec() {
                            this._type = 'com.vmware.vsphere.client.network.PhysicalNicEditSpec';
                        }
                        return PhysicalNicEditSpec;
                    }());
                    network.PhysicalNicEditSpec = PhysicalNicEditSpec;
                })(network = client.network || (client.network = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var spec;
                        (function (spec) {
                            var LacpUpgradeVersionCommandSpec = (function () {
                                function LacpUpgradeVersionCommandSpec() {
                                    this._type = 'com.vmware.vsphere.client.dvs.api.spec.LacpUpgradeVersionCommandSpec';
                                }
                                return LacpUpgradeVersionCommandSpec;
                            }());
                            spec.LacpUpgradeVersionCommandSpec = LacpUpgradeVersionCommandSpec;
                        })(spec = api.spec || (api.spec = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var spec;
                        (function (spec) {
                            var DvsRestoreActionSpec = (function () {
                                function DvsRestoreActionSpec() {
                                    this._type = 'com.vmware.vsphere.client.dvs.api.spec.DvsRestoreActionSpec';
                                }
                                return DvsRestoreActionSpec;
                            }());
                            spec.DvsRestoreActionSpec = DvsRestoreActionSpec;
                        })(spec = api.spec || (api.spec = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var vm;
                (function (vm) {
                    var customizations;
                    (function (customizations) {
                        var VmCustomizationCopySpec = (function () {
                            function VmCustomizationCopySpec() {
                                this._type = 'com.vmware.vsphere.client.vm.customizations.VmCustomizationCopySpec';
                            }
                            return VmCustomizationCopySpec;
                        }());
                        customizations.VmCustomizationCopySpec = VmCustomizationCopySpec;
                    })(customizations = vm.customizations || (vm.customizations = {}));
                })(vm = client.vm || (client.vm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var host;
                        (function (host) {
                            var vnic;
                            (function (vnic) {
                                var model;
                                (function (model) {
                                    var VnicRemoveValidationResult = (function () {
                                        function VnicRemoveValidationResult() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.host.vnic.model.VnicRemoveValidationResult';
                                        }
                                        return VnicRemoveValidationResult;
                                    }());
                                    model.VnicRemoveValidationResult = VnicRemoveValidationResult;
                                })(model = vnic.model || (vnic.model = {}));
                            })(vnic = host.vnic || (host.vnic = {}));
                        })(host = network.host || (network.host = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vapp;
                    (function (vapp) {
                        var data;
                        (function (data) {
                            var ValidateNameAndFolderSpec = (function () {
                                function ValidateNameAndFolderSpec() {
                                    this._type = 'com.vmware.vsphere.client.h5.vapp.data.ValidateNameAndFolderSpec';
                                }
                                return ValidateNameAndFolderSpec;
                            }());
                            data.ValidateNameAndFolderSpec = ValidateNameAndFolderSpec;
                        })(data = vapp.data || (vapp.data = {}));
                    })(vapp = h5.vapp || (h5.vapp = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var switchdiagram;
                        (function (switchdiagram) {
                            var model;
                            (function (model) {
                                var AdapterModel = (function (_super) {
                                    __extends(AdapterModel, _super);
                                    function AdapterModel() {
                                        _super.apply(this, arguments);
                                        this._type = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.AdapterModel';
                                    }
                                    return AdapterModel;
                                }(model.BaseSwitchDiagramComponent));
                                model.AdapterModel = AdapterModel;
                            })(model = switchdiagram.model || (switchdiagram.model = {}));
                        })(switchdiagram = api.switchdiagram || (api.switchdiagram = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var switchdiagram;
                        (function (switchdiagram) {
                            var model;
                            (function (model) {
                                var PhysicalAdapterModel = (function (_super) {
                                    __extends(PhysicalAdapterModel, _super);
                                    function PhysicalAdapterModel() {
                                        _super.apply(this, arguments);
                                        this._type = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.PhysicalAdapterModel';
                                    }
                                    return PhysicalAdapterModel;
                                }(model.AdapterModel));
                                model.PhysicalAdapterModel = PhysicalAdapterModel;
                            })(model = switchdiagram.model || (switchdiagram.model = {}));
                        })(switchdiagram = api.switchdiagram || (api.switchdiagram = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var switchdiagram;
                        (function (switchdiagram) {
                            var model;
                            (function (model) {
                                var SriovAdapterModel = (function (_super) {
                                    __extends(SriovAdapterModel, _super);
                                    function SriovAdapterModel() {
                                        _super.apply(this, arguments);
                                        this._type = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.SriovAdapterModel';
                                    }
                                    return SriovAdapterModel;
                                }(model.PhysicalAdapterModel));
                                model.SriovAdapterModel = SriovAdapterModel;
                            })(model = switchdiagram.model || (switchdiagram.model = {}));
                        })(switchdiagram = api.switchdiagram || (api.switchdiagram = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var DvsConfigSpec = (function () {
                        function DvsConfigSpec() {
                            this._type = 'com.vmware.vsphere.client.dvs.DvsConfigSpec';
                        }
                        return DvsConfigSpec;
                    }());
                    dvs.DvsConfigSpec = DvsConfigSpec;
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var addhost;
                            (function (addhost) {
                                var hierarchicalList;
                                (function (hierarchicalList) {
                                    var DvsAddHostHierarchicalListItem = (function () {
                                        function DvsAddHostHierarchicalListItem() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListItem';
                                        }
                                        return DvsAddHostHierarchicalListItem;
                                    }());
                                    hierarchicalList.DvsAddHostHierarchicalListItem = DvsAddHostHierarchicalListItem;
                                })(hierarchicalList = addhost.hierarchicalList || (addhost.hierarchicalList = {}));
                            })(addhost = dvs.addhost || (dvs.addhost = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var addhost;
                            (function (addhost) {
                                var hierarchicalList;
                                (function (hierarchicalList) {
                                    var DvsAddHostHierarchicalListGroupingItem = (function (_super) {
                                        __extends(DvsAddHostHierarchicalListGroupingItem, _super);
                                        function DvsAddHostHierarchicalListGroupingItem() {
                                            _super.apply(this, arguments);
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListGroupingItem';
                                        }
                                        return DvsAddHostHierarchicalListGroupingItem;
                                    }(hierarchicalList.DvsAddHostHierarchicalListItem));
                                    hierarchicalList.DvsAddHostHierarchicalListGroupingItem = DvsAddHostHierarchicalListGroupingItem;
                                })(hierarchicalList = addhost.hierarchicalList || (addhost.hierarchicalList = {}));
                            })(addhost = dvs.addhost || (dvs.addhost = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var addhost;
                            (function (addhost) {
                                var hierarchicalList;
                                (function (hierarchicalList) {
                                    var DvsAddHostHierarchicalListPnicItem = (function (_super) {
                                        __extends(DvsAddHostHierarchicalListPnicItem, _super);
                                        function DvsAddHostHierarchicalListPnicItem() {
                                            _super.apply(this, arguments);
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListPnicItem';
                                        }
                                        return DvsAddHostHierarchicalListPnicItem;
                                    }(hierarchicalList.DvsAddHostHierarchicalListItem));
                                    hierarchicalList.DvsAddHostHierarchicalListPnicItem = DvsAddHostHierarchicalListPnicItem;
                                })(hierarchicalList = addhost.hierarchicalList || (addhost.hierarchicalList = {}));
                            })(addhost = dvs.addhost || (dvs.addhost = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var addhost;
                            (function (addhost) {
                                var hierarchicalList;
                                (function (hierarchicalList) {
                                    var DvsAddHostHierarchicalListVmItem = (function (_super) {
                                        __extends(DvsAddHostHierarchicalListVmItem, _super);
                                        function DvsAddHostHierarchicalListVmItem() {
                                            _super.apply(this, arguments);
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListVmItem';
                                        }
                                        return DvsAddHostHierarchicalListVmItem;
                                    }(hierarchicalList.DvsAddHostHierarchicalListItem));
                                    hierarchicalList.DvsAddHostHierarchicalListVmItem = DvsAddHostHierarchicalListVmItem;
                                })(hierarchicalList = addhost.hierarchicalList || (addhost.hierarchicalList = {}));
                            })(addhost = dvs.addhost || (dvs.addhost = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var addhost;
                            (function (addhost) {
                                var hierarchicalList;
                                (function (hierarchicalList) {
                                    var DvsAddHostHierarchicalListVmVnicItem = (function (_super) {
                                        __extends(DvsAddHostHierarchicalListVmVnicItem, _super);
                                        function DvsAddHostHierarchicalListVmVnicItem() {
                                            _super.apply(this, arguments);
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListVmVnicItem';
                                        }
                                        return DvsAddHostHierarchicalListVmVnicItem;
                                    }(hierarchicalList.DvsAddHostHierarchicalListItem));
                                    hierarchicalList.DvsAddHostHierarchicalListVmVnicItem = DvsAddHostHierarchicalListVmVnicItem;
                                })(hierarchicalList = addhost.hierarchicalList || (addhost.hierarchicalList = {}));
                            })(addhost = dvs.addhost || (dvs.addhost = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var addhost;
                            (function (addhost) {
                                var hierarchicalList;
                                (function (hierarchicalList) {
                                    var DvsAddHostHierarchicalListHostItem = (function (_super) {
                                        __extends(DvsAddHostHierarchicalListHostItem, _super);
                                        function DvsAddHostHierarchicalListHostItem() {
                                            _super.apply(this, arguments);
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListHostItem';
                                        }
                                        return DvsAddHostHierarchicalListHostItem;
                                    }(hierarchicalList.DvsAddHostHierarchicalListItem));
                                    hierarchicalList.DvsAddHostHierarchicalListHostItem = DvsAddHostHierarchicalListHostItem;
                                })(hierarchicalList = addhost.hierarchicalList || (addhost.hierarchicalList = {}));
                            })(addhost = dvs.addhost || (dvs.addhost = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var spbm;
                    (function (spbm) {
                        var data;
                        (function (data) {
                            var SpbmComplianceFailure = (function () {
                                function SpbmComplianceFailure() {
                                    this._type = 'com.vmware.vsphere.client.h5.spbm.data.SpbmComplianceFailure';
                                }
                                return SpbmComplianceFailure;
                            }());
                            data.SpbmComplianceFailure = SpbmComplianceFailure;
                        })(data = spbm.data || (spbm.data = {}));
                    })(spbm = h5.spbm || (h5.spbm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var switchdiagram;
                        (function (switchdiagram) {
                            var model;
                            (function (model) {
                                var GraphRelation = (function () {
                                    function GraphRelation() {
                                        this._type = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.GraphRelation';
                                    }
                                    return GraphRelation;
                                }());
                                model.GraphRelation = GraphRelation;
                            })(model = switchdiagram.model || (switchdiagram.model = {}));
                        })(switchdiagram = api.switchdiagram || (api.switchdiagram = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var vm;
                (function (vm) {
                    var VmCloneSpec = (function () {
                        function VmCloneSpec() {
                            this._type = 'com.vmware.vsphere.client.vm.VmCloneSpec';
                        }
                        return VmCloneSpec;
                    }());
                    vm.VmCloneSpec = VmCloneSpec;
                })(vm = client.vm || (client.vm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var resmgmt;
                            (function (resmgmt) {
                                var systemtraffic;
                                (function (systemtraffic) {
                                    var model;
                                    (function (model) {
                                        var DvsSystemTrafficListItemData = (function () {
                                            function DvsSystemTrafficListItemData() {
                                                this._type = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.systemtraffic.model.DvsSystemTrafficListItemData';
                                            }
                                            return DvsSystemTrafficListItemData;
                                        }());
                                        model.DvsSystemTrafficListItemData = DvsSystemTrafficListItemData;
                                    })(model = systemtraffic.model || (systemtraffic.model = {}));
                                })(systemtraffic = resmgmt.systemtraffic || (resmgmt.systemtraffic = {}));
                            })(resmgmt = dvs.resmgmt || (dvs.resmgmt = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var common;
                            (function (common) {
                                var model;
                                (function (model) {
                                    var DvpgMonitoringPolicyModel = (function () {
                                        function DvpgMonitoringPolicyModel() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgMonitoringPolicyModel';
                                        }
                                        return DvpgMonitoringPolicyModel;
                                    }());
                                    model.DvpgMonitoringPolicyModel = DvpgMonitoringPolicyModel;
                                })(model = common.model || (common.model = {}));
                            })(common = dvportgroup.common || (dvportgroup.common = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var ports;
                            (function (ports) {
                                var model;
                                (function (model) {
                                    var DvsPortMonitoringPolicyModel = (function (_super) {
                                        __extends(DvsPortMonitoringPolicyModel, _super);
                                        function DvsPortMonitoringPolicyModel() {
                                            _super.apply(this, arguments);
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortMonitoringPolicyModel';
                                        }
                                        return DvsPortMonitoringPolicyModel;
                                    }(com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgMonitoringPolicyModel));
                                    model.DvsPortMonitoringPolicyModel = DvsPortMonitoringPolicyModel;
                                })(model = ports.model || (ports.model = {}));
                            })(ports = dvs.ports || (dvs.ports = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var spbm;
                    (function (spbm) {
                        var data;
                        (function (data) {
                            var datastore;
                            (function (datastore) {
                                var DatastoreCapabilityProfilesCapacityData = (function () {
                                    function DatastoreCapabilityProfilesCapacityData() {
                                        this._type = 'com.vmware.vsphere.client.h5.spbm.data.datastore.DatastoreCapabilityProfilesCapacityData';
                                    }
                                    return DatastoreCapabilityProfilesCapacityData;
                                }());
                                datastore.DatastoreCapabilityProfilesCapacityData = DatastoreCapabilityProfilesCapacityData;
                            })(datastore = data.datastore || (data.datastore = {}));
                        })(data = spbm.data || (spbm.data = {}));
                    })(spbm = h5.spbm || (h5.spbm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var spbm;
                (function (spbm) {
                    var data;
                    (function (data) {
                        var profile;
                        (function (profile) {
                            var content;
                            (function (content) {
                                var MessageItem = (function () {
                                    function MessageItem() {
                                        this._type = 'com.vmware.vsphere.client.spbm.data.profile.content.MessageItem';
                                    }
                                    return MessageItem;
                                }());
                                content.MessageItem = MessageItem;
                            })(content = profile.content || (profile.content = {}));
                        })(profile = data.profile || (data.profile = {}));
                    })(data = spbm.data || (spbm.data = {}));
                })(spbm = client.spbm || (client.spbm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var spec;
                        (function (spec) {
                            var DvPortgroupQueryRestoreSpec = (function () {
                                function DvPortgroupQueryRestoreSpec() {
                                    this._type = 'com.vmware.vsphere.client.dvs.api.spec.DvPortgroupQueryRestoreSpec';
                                }
                                return DvPortgroupQueryRestoreSpec;
                            }());
                            spec.DvPortgroupQueryRestoreSpec = DvPortgroupQueryRestoreSpec;
                        })(spec = api.spec || (api.spec = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var spbm;
                (function (spbm) {
                    var spec;
                    (function (spec) {
                        var CheckVmRollupComplianceSpec = (function () {
                            function CheckVmRollupComplianceSpec() {
                                this._type = 'com.vmware.vsphere.client.spbm.spec.CheckVmRollupComplianceSpec';
                            }
                            return CheckVmRollupComplianceSpec;
                        }());
                        spec.CheckVmRollupComplianceSpec = CheckVmRollupComplianceSpec;
                    })(spec = spbm.spec || (spbm.spec = {}));
                })(spbm = client.spbm || (client.spbm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var MoveNetworkSpec = (function () {
                            function MoveNetworkSpec() {
                                this._type = 'com.vmware.vsphere.client.h5.network.MoveNetworkSpec';
                            }
                            return MoveNetworkSpec;
                        }());
                        network.MoveNetworkSpec = MoveNetworkSpec;
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var spec;
                        (function (spec) {
                            var DvsImportInfoSpec = (function () {
                                function DvsImportInfoSpec() {
                                    this._type = 'com.vmware.vsphere.client.dvs.api.spec.DvsImportInfoSpec';
                                }
                                return DvsImportInfoSpec;
                            }());
                            spec.DvsImportInfoSpec = DvsImportInfoSpec;
                        })(spec = api.spec || (api.spec = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvpg;
                (function (dvpg) {
                    var api;
                    (function (api) {
                        var HostNetworkDetailsData$PortPolicy$TeamingAndFailover = (function () {
                            function HostNetworkDetailsData$PortPolicy$TeamingAndFailover() {
                                this._type = 'com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData$PortPolicy$TeamingAndFailover';
                            }
                            return HostNetworkDetailsData$PortPolicy$TeamingAndFailover;
                        }());
                        api.HostNetworkDetailsData$PortPolicy$TeamingAndFailover = HostNetworkDetailsData$PortPolicy$TeamingAndFailover;
                    })(api = dvpg.api || (dvpg.api = {}));
                })(dvpg = client.dvpg || (client.dvpg = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var storage;
                    (function (storage) {
                        var data;
                        (function (data) {
                            var datastore;
                            (function (datastore) {
                                var StorageContainerInfoItem = (function () {
                                    function StorageContainerInfoItem() {
                                        this._type = 'com.vmware.vsphere.client.h5.storage.data.datastore.StorageContainerInfoItem';
                                    }
                                    return StorageContainerInfoItem;
                                }());
                                datastore.StorageContainerInfoItem = StorageContainerInfoItem;
                            })(datastore = data.datastore || (data.datastore = {}));
                        })(data = storage.data || (storage.data = {}));
                    })(storage = h5.storage || (h5.storage = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vm;
                    (function (vm) {
                        var data;
                        (function (data) {
                            var CustomizeGosSpecData = (function () {
                                function CustomizeGosSpecData() {
                                    this._type = 'com.vmware.vsphere.client.h5.vm.data.CustomizeGosSpecData';
                                }
                                return CustomizeGosSpecData;
                            }());
                            data.CustomizeGosSpecData = CustomizeGosSpecData;
                        })(data = vm.data || (vm.data = {}));
                    })(vm = h5.vm || (h5.vm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var vm;
                (function (vm) {
                    var VmCustomizationValidationResult = (function () {
                        function VmCustomizationValidationResult() {
                            this._type = 'com.vmware.vsphere.client.vm.VmCustomizationValidationResult';
                        }
                        return VmCustomizationValidationResult;
                    }());
                    vm.VmCustomizationValidationResult = VmCustomizationValidationResult;
                })(vm = client.vm || (client.vm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var spbm;
                    (function (spbm) {
                        var data;
                        (function (data) {
                            var StorageProfileContainerCompatibilityData$StoragePodContainerCompatibilityInfo = (function (_super) {
                                __extends(StorageProfileContainerCompatibilityData$StoragePodContainerCompatibilityInfo, _super);
                                function StorageProfileContainerCompatibilityData$StoragePodContainerCompatibilityInfo() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vsphere.client.h5.spbm.data.StorageProfileContainerCompatibilityData$StoragePodContainerCompatibilityInfo';
                                }
                                return StorageProfileContainerCompatibilityData$StoragePodContainerCompatibilityInfo;
                            }(data.StorageProfileContainerCompatibilityData$StorageContainerCompatibilityInfo));
                            data.StorageProfileContainerCompatibilityData$StoragePodContainerCompatibilityInfo = StorageProfileContainerCompatibilityData$StoragePodContainerCompatibilityInfo;
                        })(data = spbm.data || (spbm.data = {}));
                    })(spbm = h5.spbm || (h5.spbm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var trafficfilter;
                            (function (trafficfilter) {
                                var model;
                                (function (model) {
                                    var DvpgTrafficRuleModel = (function () {
                                        function DvpgTrafficRuleModel() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgTrafficRuleModel';
                                        }
                                        return DvpgTrafficRuleModel;
                                    }());
                                    model.DvpgTrafficRuleModel = DvpgTrafficRuleModel;
                                })(model = trafficfilter.model || (trafficfilter.model = {}));
                            })(trafficfilter = dvportgroup.trafficfilter || (dvportgroup.trafficfilter = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvpg;
                (function (dvpg) {
                    var api;
                    (function (api) {
                        var HostNetworkDetailsData$IpProperties = (function () {
                            function HostNetworkDetailsData$IpProperties() {
                                this._type = 'com.vmware.vsphere.client.dvpg.api.HostNetworkDetailsData$IpProperties';
                            }
                            return HostNetworkDetailsData$IpProperties;
                        }());
                        api.HostNetworkDetailsData$IpProperties = HostNetworkDetailsData$IpProperties;
                    })(api = dvpg.api || (dvpg.api = {}));
                })(dvpg = client.dvpg || (client.dvpg = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var spec;
                        (function (spec) {
                            var DvsPortsStatisticsSpec = (function () {
                                function DvsPortsStatisticsSpec() {
                                    this._type = 'com.vmware.vsphere.client.dvs.api.spec.DvsPortsStatisticsSpec';
                                }
                                return DvsPortsStatisticsSpec;
                            }());
                            spec.DvsPortsStatisticsSpec = DvsPortsStatisticsSpec;
                        })(spec = api.spec || (api.spec = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var upgrade;
                            (function (upgrade) {
                                var model;
                                (function (model) {
                                    var UpgradeLacpDvsData = (function () {
                                        function UpgradeLacpDvsData() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.upgrade.model.UpgradeLacpDvsData';
                                        }
                                        return UpgradeLacpDvsData;
                                    }());
                                    model.UpgradeLacpDvsData = UpgradeLacpDvsData;
                                })(model = upgrade.model || (upgrade.model = {}));
                            })(upgrade = dvs.upgrade || (dvs.upgrade = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var trafficfilter;
                            (function (trafficfilter) {
                                var model;
                                (function (model) {
                                    var DvpgIpAddressModel = (function () {
                                        function DvpgIpAddressModel() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgIpAddressModel';
                                        }
                                        return DvpgIpAddressModel;
                                    }());
                                    model.DvpgIpAddressModel = DvpgIpAddressModel;
                                })(model = trafficfilter.model || (trafficfilter.model = {}));
                            })(trafficfilter = dvportgroup.trafficfilter || (dvportgroup.trafficfilter = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var ports;
                            (function (ports) {
                                var model;
                                (function (model) {
                                    var DvsPortFailoverPolicyModel = (function (_super) {
                                        __extends(DvsPortFailoverPolicyModel, _super);
                                        function DvsPortFailoverPolicyModel() {
                                            _super.apply(this, arguments);
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortFailoverPolicyModel';
                                        }
                                        return DvsPortFailoverPolicyModel;
                                    }(com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgFailoverPolicyModel));
                                    model.DvsPortFailoverPolicyModel = DvsPortFailoverPolicyModel;
                                })(model = ports.model || (ports.model = {}));
                            })(ports = dvs.ports || (dvs.ports = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var resmgmt;
                            (function (resmgmt) {
                                var nrp;
                                (function (nrp) {
                                    var model;
                                    (function (model) {
                                        var DvsNetworkResourcePoolPortgroupsListItemData = (function () {
                                            function DvsNetworkResourcePoolPortgroupsListItemData() {
                                                this._type = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsNetworkResourcePoolPortgroupsListItemData';
                                            }
                                            return DvsNetworkResourcePoolPortgroupsListItemData;
                                        }());
                                        model.DvsNetworkResourcePoolPortgroupsListItemData = DvsNetworkResourcePoolPortgroupsListItemData;
                                    })(model = nrp.model || (nrp.model = {}));
                                })(nrp = resmgmt.nrp || (resmgmt.nrp = {}));
                            })(resmgmt = dvs.resmgmt || (dvs.resmgmt = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var switchdiagram;
                        (function (switchdiagram) {
                            var model;
                            (function (model) {
                                var SriovPortModel = (function (_super) {
                                    __extends(SriovPortModel, _super);
                                    function SriovPortModel() {
                                        _super.apply(this, arguments);
                                        this._type = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.SriovPortModel';
                                    }
                                    return SriovPortModel;
                                }(model.PortModel));
                                model.SriovPortModel = SriovPortModel;
                            })(model = switchdiagram.model || (switchdiagram.model = {}));
                        })(switchdiagram = api.switchdiagram || (api.switchdiagram = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var ports;
                            (function (ports) {
                                var model;
                                (function (model) {
                                    var DvsPortRemoveTrafficRuleSpec = (function () {
                                        function DvsPortRemoveTrafficRuleSpec() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortRemoveTrafficRuleSpec';
                                        }
                                        return DvsPortRemoveTrafficRuleSpec;
                                    }());
                                    model.DvsPortRemoveTrafficRuleSpec = DvsPortRemoveTrafficRuleSpec;
                                })(model = ports.model || (ports.model = {}));
                            })(ports = dvs.ports || (dvs.ports = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var storage;
                (function (storage) {
                    var adapters;
                    (function (adapters) {
                        var InternetScsiStorageAdapterAuthenticationUpdateSpec = (function () {
                            function InternetScsiStorageAdapterAuthenticationUpdateSpec() {
                                this._type = 'com.vmware.vsphere.client.storage.adapters.InternetScsiStorageAdapterAuthenticationUpdateSpec';
                            }
                            return InternetScsiStorageAdapterAuthenticationUpdateSpec;
                        }());
                        adapters.InternetScsiStorageAdapterAuthenticationUpdateSpec = InternetScsiStorageAdapterAuthenticationUpdateSpec;
                    })(adapters = storage.adapters || (storage.adapters = {}));
                })(storage = client.storage || (client.storage = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vm;
                    (function (vm) {
                        var model;
                        (function (model) {
                            var VmEditEvcData = (function () {
                                function VmEditEvcData() {
                                    this._type = 'com.vmware.vsphere.client.h5.vm.model.VmEditEvcData';
                                }
                                return VmEditEvcData;
                            }());
                            model.VmEditEvcData = VmEditEvcData;
                        })(model = vm.model || (vm.model = {}));
                    })(vm = h5.vm || (h5.vm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var spbm;
                (function (spbm) {
                    var data;
                    (function (data) {
                        var profile;
                        (function (profile) {
                            var content;
                            (function (content) {
                                var CategoryData = (function () {
                                    function CategoryData() {
                                        this._type = 'com.vmware.vsphere.client.spbm.data.profile.content.CategoryData';
                                    }
                                    return CategoryData;
                                }());
                                content.CategoryData = CategoryData;
                            })(content = profile.content || (profile.content = {}));
                        })(profile = data.profile || (data.profile = {}));
                    })(data = spbm.data || (spbm.data = {}));
                })(spbm = client.spbm || (client.spbm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var spbm;
                    (function (spbm) {
                        var data;
                        (function (data) {
                            var VmStorageProfilesData = (function () {
                                function VmStorageProfilesData() {
                                    this._type = 'com.vmware.vsphere.client.h5.spbm.data.VmStorageProfilesData';
                                }
                                return VmStorageProfilesData;
                            }());
                            data.VmStorageProfilesData = VmStorageProfilesData;
                        })(data = spbm.data || (spbm.data = {}));
                    })(spbm = h5.spbm || (h5.spbm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var manage;
                            (function (manage) {
                                var model;
                                (function (model) {
                                    var ManagePortgroupsWizardModel = (function () {
                                        function ManagePortgroupsWizardModel() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.manage.model.ManagePortgroupsWizardModel';
                                        }
                                        return ManagePortgroupsWizardModel;
                                    }());
                                    model.ManagePortgroupsWizardModel = ManagePortgroupsWizardModel;
                                })(model = manage.model || (manage.model = {}));
                            })(manage = dvportgroup.manage || (dvportgroup.manage = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var trafficfilter;
                            (function (trafficfilter) {
                                var model;
                                (function (model) {
                                    var DvpgMacQualifierModel = (function () {
                                        function DvpgMacQualifierModel() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.model.DvpgMacQualifierModel';
                                        }
                                        return DvpgMacQualifierModel;
                                    }());
                                    model.DvpgMacQualifierModel = DvpgMacQualifierModel;
                                })(model = trafficfilter.model || (trafficfilter.model = {}));
                            })(trafficfilter = dvportgroup.trafficfilter || (dvportgroup.trafficfilter = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var storage;
                (function (storage) {
                    var ProtocolEndpointData = (function () {
                        function ProtocolEndpointData() {
                            this._type = 'com.vmware.vsphere.client.storage.ProtocolEndpointData';
                        }
                        return ProtocolEndpointData;
                    }());
                    storage.ProtocolEndpointData = ProtocolEndpointData;
                })(storage = client.storage || (client.storage = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var hostprofile;
                (function (hostprofile) {
                    var data;
                    (function (data) {
                        var h5;
                        (function (h5) {
                            var UpdateHostCustomizationsSpec = (function () {
                                function UpdateHostCustomizationsSpec() {
                                    this._type = 'com.vmware.vsphere.client.hostprofile.data.h5.UpdateHostCustomizationsSpec';
                                }
                                return UpdateHostCustomizationsSpec;
                            }());
                            h5.UpdateHostCustomizationsSpec = UpdateHostCustomizationsSpec;
                        })(h5 = data.h5 || (data.h5 = {}));
                    })(data = hostprofile.data || (hostprofile.data = {}));
                })(hostprofile = client.hostprofile || (client.hostprofile = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var spbm;
                    (function (spbm) {
                        var data;
                        (function (data) {
                            var VmStorageProfiles = (function () {
                                function VmStorageProfiles() {
                                    this._type = 'com.vmware.vsphere.client.h5.spbm.data.VmStorageProfiles';
                                }
                                return VmStorageProfiles;
                            }());
                            data.VmStorageProfiles = VmStorageProfiles;
                        })(data = spbm.data || (spbm.data = {}));
                    })(spbm = h5.spbm || (h5.spbm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vapp;
                    (function (vapp) {
                        var data;
                        (function (data) {
                            var ValidateDestinationSpec = (function () {
                                function ValidateDestinationSpec() {
                                    this._type = 'com.vmware.vsphere.client.h5.vapp.data.ValidateDestinationSpec';
                                }
                                return ValidateDestinationSpec;
                            }());
                            data.ValidateDestinationSpec = ValidateDestinationSpec;
                        })(data = vapp.data || (vapp.data = {}));
                    })(vapp = h5.vapp || (h5.vapp = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var portmirroring;
                            (function (portmirroring) {
                                var model;
                                (function (model) {
                                    var DvsPortMirroringSourcePorts = (function () {
                                        function DvsPortMirroringSourcePorts() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.DvsPortMirroringSourcePorts';
                                        }
                                        return DvsPortMirroringSourcePorts;
                                    }());
                                    model.DvsPortMirroringSourcePorts = DvsPortMirroringSourcePorts;
                                })(model = portmirroring.model || (portmirroring.model = {}));
                            })(portmirroring = dvs.portmirroring || (dvs.portmirroring = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var resmgmt;
                            (function (resmgmt) {
                                var nrp;
                                (function (nrp) {
                                    var model;
                                    (function (model) {
                                        var DvsNetworkResourcePoolsOverviewData = (function () {
                                            function DvsNetworkResourcePoolsOverviewData() {
                                                this._type = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsNetworkResourcePoolsOverviewData';
                                            }
                                            return DvsNetworkResourcePoolsOverviewData;
                                        }());
                                        model.DvsNetworkResourcePoolsOverviewData = DvsNetworkResourcePoolsOverviewData;
                                    })(model = nrp.model || (nrp.model = {}));
                                })(nrp = resmgmt.nrp || (resmgmt.nrp = {}));
                            })(resmgmt = dvs.resmgmt || (dvs.resmgmt = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var addvnics;
                            (function (addvnics) {
                                var model;
                                (function (model) {
                                    var DvpgHostVnicServiceModel = (function () {
                                        function DvpgHostVnicServiceModel() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.addvnics.model.DvpgHostVnicServiceModel';
                                        }
                                        return DvpgHostVnicServiceModel;
                                    }());
                                    model.DvpgHostVnicServiceModel = DvpgHostVnicServiceModel;
                                })(model = addvnics.model || (addvnics.model = {}));
                            })(addvnics = dvportgroup.addvnics || (dvportgroup.addvnics = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var storage;
                    (function (storage) {
                        var data;
                        (function (data) {
                            var datastore;
                            (function (datastore) {
                                var DatastoreLocatorData = (function () {
                                    function DatastoreLocatorData() {
                                        this._type = 'com.vmware.vsphere.client.h5.storage.data.datastore.DatastoreLocatorData';
                                    }
                                    return DatastoreLocatorData;
                                }());
                                datastore.DatastoreLocatorData = DatastoreLocatorData;
                            })(datastore = data.datastore || (data.datastore = {}));
                        })(data = storage.data || (storage.data = {}));
                    })(storage = h5.storage || (h5.storage = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var ports;
                            (function (ports) {
                                var model;
                                (function (model) {
                                    var DvsPortTrafficShapingPolicyModel = (function (_super) {
                                        __extends(DvsPortTrafficShapingPolicyModel, _super);
                                        function DvsPortTrafficShapingPolicyModel() {
                                            _super.apply(this, arguments);
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortTrafficShapingPolicyModel';
                                        }
                                        return DvsPortTrafficShapingPolicyModel;
                                    }(com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgTrafficShapingPolicyModel));
                                    model.DvsPortTrafficShapingPolicyModel = DvsPortTrafficShapingPolicyModel;
                                })(model = ports.model || (ports.model = {}));
                            })(ports = dvs.ports || (dvs.ports = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var storage;
                    (function (storage) {
                        var data;
                        (function (data) {
                            var datastore;
                            (function (datastore) {
                                var vvol;
                                (function (vvol) {
                                    var VvolStorageProvidersViewData$StorageProviderItem = (function () {
                                        function VvolStorageProvidersViewData$StorageProviderItem() {
                                            this._type = 'com.vmware.vsphere.client.h5.storage.data.datastore.vvol.VvolStorageProvidersViewData$StorageProviderItem';
                                        }
                                        return VvolStorageProvidersViewData$StorageProviderItem;
                                    }());
                                    vvol.VvolStorageProvidersViewData$StorageProviderItem = VvolStorageProvidersViewData$StorageProviderItem;
                                })(vvol = datastore.vvol || (datastore.vvol = {}));
                            })(datastore = data.datastore || (data.datastore = {}));
                        })(data = storage.data || (storage.data = {}));
                    })(storage = h5.storage || (h5.storage = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var upgrade;
                            (function (upgrade) {
                                var model;
                                (function (model) {
                                    var UpgradeDvsData = (function () {
                                        function UpgradeDvsData() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.upgrade.model.UpgradeDvsData';
                                        }
                                        return UpgradeDvsData;
                                    }());
                                    model.UpgradeDvsData = UpgradeDvsData;
                                })(model = upgrade.model || (upgrade.model = {}));
                            })(upgrade = dvs.upgrade || (dvs.upgrade = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var library;
                (function (library) {
                    var specs;
                    (function (specs) {
                        var ComputeResourceSubscriptionSpec = (function () {
                            function ComputeResourceSubscriptionSpec() {
                                this._type = 'com.vmware.vsphere.client.library.specs.ComputeResourceSubscriptionSpec';
                            }
                            return ComputeResourceSubscriptionSpec;
                        }());
                        specs.ComputeResourceSubscriptionSpec = ComputeResourceSubscriptionSpec;
                    })(specs = library.specs || (library.specs = {}));
                })(library = client.library || (client.library = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var vm;
                        (function (vm) {
                            var migrate;
                            (function (migrate) {
                                var specs;
                                (function (specs) {
                                    var MigrateVmNetworkingDragAndDropSpec = (function () {
                                        function MigrateVmNetworkingDragAndDropSpec() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.vm.migrate.specs.MigrateVmNetworkingDragAndDropSpec';
                                        }
                                        return MigrateVmNetworkingDragAndDropSpec;
                                    }());
                                    specs.MigrateVmNetworkingDragAndDropSpec = MigrateVmNetworkingDragAndDropSpec;
                                })(specs = migrate.specs || (migrate.specs = {}));
                            })(migrate = vm.migrate || (vm.migrate = {}));
                        })(vm = network.vm || (network.vm = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var hostprofile;
                (function (hostprofile) {
                    var data;
                    (function (data) {
                        var HostCustomizationImportException = (function () {
                            function HostCustomizationImportException() {
                                this._type = 'com.vmware.vsphere.client.hostprofile.data.HostCustomizationImportException';
                            }
                            return HostCustomizationImportException;
                        }());
                        data.HostCustomizationImportException = HostCustomizationImportException;
                    })(data = hostprofile.data || (hostprofile.data = {}));
                })(hostprofile = client.hostprofile || (client.hostprofile = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var network;
                (function (network) {
                    var switchdiagram;
                    (function (switchdiagram) {
                        var api;
                        (function (api) {
                            var spec;
                            (function (spec) {
                                var VirtualMachineSectionsModel = (function () {
                                    function VirtualMachineSectionsModel() {
                                        this._type = 'com.vmware.vsphere.client.network.switchdiagram.api.spec.VirtualMachineSectionsModel';
                                    }
                                    return VirtualMachineSectionsModel;
                                }());
                                spec.VirtualMachineSectionsModel = VirtualMachineSectionsModel;
                            })(spec = api.spec || (api.spec = {}));
                        })(api = switchdiagram.api || (switchdiagram.api = {}));
                    })(switchdiagram = network.switchdiagram || (network.switchdiagram = {}));
                })(network = client.network || (client.network = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var spec;
                        (function (spec) {
                            var DvpgRestoreSettingRepresentationSpec = (function () {
                                function DvpgRestoreSettingRepresentationSpec() {
                                    this._type = 'com.vmware.vsphere.client.dvs.api.spec.DvpgRestoreSettingRepresentationSpec';
                                }
                                return DvpgRestoreSettingRepresentationSpec;
                            }());
                            spec.DvpgRestoreSettingRepresentationSpec = DvpgRestoreSettingRepresentationSpec;
                        })(spec = api.spec || (api.spec = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var portmirroring;
                            (function (portmirroring) {
                                var model;
                                (function (model) {
                                    var wizard;
                                    (function (wizard) {
                                        var DvsAddSpanSessionWizardData = (function () {
                                            function DvsAddSpanSessionWizardData() {
                                                this._type = 'com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.wizard.DvsAddSpanSessionWizardData';
                                            }
                                            return DvsAddSpanSessionWizardData;
                                        }());
                                        wizard.DvsAddSpanSessionWizardData = DvsAddSpanSessionWizardData;
                                    })(wizard = model.wizard || (model.wizard = {}));
                                })(model = portmirroring.model || (portmirroring.model = {}));
                            })(portmirroring = dvs.portmirroring || (dvs.portmirroring = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var resmgmt;
                            (function (resmgmt) {
                                var systemtraffic;
                                (function (systemtraffic) {
                                    var model;
                                    (function (model) {
                                        var DvsSystemTrafficEditDialogData = (function () {
                                            function DvsSystemTrafficEditDialogData() {
                                                this._type = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.systemtraffic.model.DvsSystemTrafficEditDialogData';
                                            }
                                            return DvsSystemTrafficEditDialogData;
                                        }());
                                        model.DvsSystemTrafficEditDialogData = DvsSystemTrafficEditDialogData;
                                    })(model = systemtraffic.model || (systemtraffic.model = {}));
                                })(systemtraffic = resmgmt.systemtraffic || (resmgmt.systemtraffic = {}));
                            })(resmgmt = dvs.resmgmt || (dvs.resmgmt = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var vm;
                (function (vm) {
                    var VmCustomizationSpecification = (function () {
                        function VmCustomizationSpecification() {
                            this._type = 'com.vmware.vsphere.client.vm.VmCustomizationSpecification';
                        }
                        return VmCustomizationSpecification;
                    }());
                    vm.VmCustomizationSpecification = VmCustomizationSpecification;
                })(vm = client.vm || (client.vm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var spbm;
                    (function (spbm) {
                        var data;
                        (function (data) {
                            var datastore;
                            (function (datastore) {
                                var DatastoreResourceProfilesData = (function () {
                                    function DatastoreResourceProfilesData() {
                                        this._type = 'com.vmware.vsphere.client.h5.spbm.data.datastore.DatastoreResourceProfilesData';
                                    }
                                    return DatastoreResourceProfilesData;
                                }());
                                datastore.DatastoreResourceProfilesData = DatastoreResourceProfilesData;
                            })(datastore = data.datastore || (data.datastore = {}));
                        })(data = spbm.data || (spbm.data = {}));
                    })(spbm = h5.spbm || (h5.spbm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var portmirroring;
                            (function (portmirroring) {
                                var model;
                                (function (model) {
                                    var portbrowser;
                                    (function (portbrowser) {
                                        var DvsSpanSessionPortBrowserPortData = (function () {
                                            function DvsSpanSessionPortBrowserPortData() {
                                                this._type = 'com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.portbrowser.DvsSpanSessionPortBrowserPortData';
                                            }
                                            return DvsSpanSessionPortBrowserPortData;
                                        }());
                                        portbrowser.DvsSpanSessionPortBrowserPortData = DvsSpanSessionPortBrowserPortData;
                                    })(portbrowser = model.portbrowser || (model.portbrowser = {}));
                                })(model = portmirroring.model || (portmirroring.model = {}));
                            })(portmirroring = dvs.portmirroring || (dvs.portmirroring = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var upgrade;
                            (function (upgrade) {
                                var model;
                                (function (model) {
                                    var UpgradeNiocDvsData = (function () {
                                        function UpgradeNiocDvsData() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.upgrade.model.UpgradeNiocDvsData';
                                        }
                                        return UpgradeNiocDvsData;
                                    }());
                                    model.UpgradeNiocDvsData = UpgradeNiocDvsData;
                                })(model = upgrade.model || (upgrade.model = {}));
                            })(upgrade = dvs.upgrade || (dvs.upgrade = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var network;
                (function (network) {
                    var GhostProxySwitchRemoveSpec = (function () {
                        function GhostProxySwitchRemoveSpec() {
                            this._type = 'com.vmware.vsphere.client.network.GhostProxySwitchRemoveSpec';
                        }
                        return GhostProxySwitchRemoveSpec;
                    }());
                    network.GhostProxySwitchRemoveSpec = GhostProxySwitchRemoveSpec;
                })(network = client.network || (client.network = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var host;
                        (function (host) {
                            var netstack;
                            (function (netstack) {
                                var model;
                                (function (model) {
                                    var NetStackConfigItem = (function () {
                                        function NetStackConfigItem() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.host.netstack.model.NetStackConfigItem';
                                        }
                                        return NetStackConfigItem;
                                    }());
                                    model.NetStackConfigItem = NetStackConfigItem;
                                })(model = netstack.model || (netstack.model = {}));
                            })(netstack = host.netstack || (host.netstack = {}));
                        })(host = network.host || (network.host = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var portmirroring;
                            (function (portmirroring) {
                                var model;
                                (function (model) {
                                    var wizard;
                                    (function (wizard) {
                                        var DvsSpanSessionNameSpec = (function () {
                                            function DvsSpanSessionNameSpec() {
                                                this._type = 'com.vmware.vsphere.client.h5.network.dvs.portmirroring.model.wizard.DvsSpanSessionNameSpec';
                                            }
                                            return DvsSpanSessionNameSpec;
                                        }());
                                        wizard.DvsSpanSessionNameSpec = DvsSpanSessionNameSpec;
                                    })(wizard = model.wizard || (model.wizard = {}));
                                })(model = portmirroring.model || (portmirroring.model = {}));
                            })(portmirroring = dvs.portmirroring || (dvs.portmirroring = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var trafficfilter;
                            (function (trafficfilter) {
                                var DvpgAddTrafficRuleSpec = (function () {
                                    function DvpgAddTrafficRuleSpec() {
                                        this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgAddTrafficRuleSpec';
                                    }
                                    return DvpgAddTrafficRuleSpec;
                                }());
                                trafficfilter.DvpgAddTrafficRuleSpec = DvpgAddTrafficRuleSpec;
                            })(trafficfilter = dvportgroup.trafficfilter || (dvportgroup.trafficfilter = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var storage;
                    (function (storage) {
                        var spec;
                        (function (spec) {
                            var UpdateVmfsUnmapBandwidthSpec = (function () {
                                function UpdateVmfsUnmapBandwidthSpec() {
                                    this._type = 'com.vmware.vsphere.client.h5.storage.spec.UpdateVmfsUnmapBandwidthSpec';
                                }
                                return UpdateVmfsUnmapBandwidthSpec;
                            }());
                            spec.UpdateVmfsUnmapBandwidthSpec = UpdateVmfsUnmapBandwidthSpec;
                        })(spec = storage.spec || (storage.spec = {}));
                    })(storage = h5.storage || (h5.storage = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var switchdiagram;
                        (function (switchdiagram) {
                            var model;
                            (function (model) {
                                var PortChannelModel = (function (_super) {
                                    __extends(PortChannelModel, _super);
                                    function PortChannelModel() {
                                        _super.apply(this, arguments);
                                        this._type = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.PortChannelModel';
                                    }
                                    return PortChannelModel;
                                }(model.BaseSwitchDiagramComponent));
                                model.PortChannelModel = PortChannelModel;
                            })(model = switchdiagram.model || (switchdiagram.model = {}));
                        })(switchdiagram = api.switchdiagram || (api.switchdiagram = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var resmgmt;
                            (function (resmgmt) {
                                var systemtraffic;
                                (function (systemtraffic) {
                                    var model;
                                    (function (model) {
                                        var DvsSystemTrafficOverviewData = (function () {
                                            function DvsSystemTrafficOverviewData() {
                                                this._type = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.systemtraffic.model.DvsSystemTrafficOverviewData';
                                            }
                                            return DvsSystemTrafficOverviewData;
                                        }());
                                        model.DvsSystemTrafficOverviewData = DvsSystemTrafficOverviewData;
                                    })(model = systemtraffic.model || (systemtraffic.model = {}));
                                })(systemtraffic = resmgmt.systemtraffic || (resmgmt.systemtraffic = {}));
                            })(resmgmt = dvs.resmgmt || (dvs.resmgmt = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var trafficfilter;
                            (function (trafficfilter) {
                                var DvpgTrafficFilterModel = (function () {
                                    function DvpgTrafficFilterModel() {
                                        this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.trafficfilter.DvpgTrafficFilterModel';
                                    }
                                    return DvpgTrafficFilterModel;
                                }());
                                trafficfilter.DvpgTrafficFilterModel = DvpgTrafficFilterModel;
                            })(trafficfilter = dvportgroup.trafficfilter || (dvportgroup.trafficfilter = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvportgroup;
                        (function (dvportgroup) {
                            var common;
                            (function (common) {
                                var model;
                                (function (model) {
                                    var DvpgResourceAllocationPolicyModel$NetworkResourcePool = (function () {
                                        function DvpgResourceAllocationPolicyModel$NetworkResourcePool() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvportgroup.common.model.DvpgResourceAllocationPolicyModel$NetworkResourcePool';
                                        }
                                        return DvpgResourceAllocationPolicyModel$NetworkResourcePool;
                                    }());
                                    model.DvpgResourceAllocationPolicyModel$NetworkResourcePool = DvpgResourceAllocationPolicyModel$NetworkResourcePool;
                                })(model = common.model || (common.model = {}));
                            })(common = dvportgroup.common || (dvportgroup.common = {}));
                        })(dvportgroup = network.dvportgroup || (network.dvportgroup = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vm;
                    (function (vm) {
                        var data;
                        (function (data) {
                            var RegisterVmSpec = (function () {
                                function RegisterVmSpec() {
                                    this._type = 'com.vmware.vsphere.client.h5.vm.data.RegisterVmSpec';
                                }
                                return RegisterVmSpec;
                            }());
                            data.RegisterVmSpec = RegisterVmSpec;
                        })(data = vm.data || (vm.data = {}));
                    })(vm = h5.vm || (h5.vm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var addhost;
                            (function (addhost) {
                                var hierarchicalList;
                                (function (hierarchicalList) {
                                    var DvsAddHostHierarchicalListVnicItem = (function (_super) {
                                        __extends(DvsAddHostHierarchicalListVnicItem, _super);
                                        function DvsAddHostHierarchicalListVnicItem() {
                                            _super.apply(this, arguments);
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.addhost.hierarchicalList.DvsAddHostHierarchicalListVnicItem';
                                        }
                                        return DvsAddHostHierarchicalListVnicItem;
                                    }(hierarchicalList.DvsAddHostHierarchicalListItem));
                                    hierarchicalList.DvsAddHostHierarchicalListVnicItem = DvsAddHostHierarchicalListVnicItem;
                                })(hierarchicalList = addhost.hierarchicalList || (addhost.hierarchicalList = {}));
                            })(addhost = dvs.addhost || (dvs.addhost = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var healthcheck;
                            (function (healthcheck) {
                                var model;
                                (function (model) {
                                    var DvsHealthCheckData = (function () {
                                        function DvsHealthCheckData() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.healthcheck.model.DvsHealthCheckData';
                                        }
                                        return DvsHealthCheckData;
                                    }());
                                    model.DvsHealthCheckData = DvsHealthCheckData;
                                })(model = healthcheck.model || (healthcheck.model = {}));
                            })(healthcheck = dvs.healthcheck || (dvs.healthcheck = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var resmgmt;
                            (function (resmgmt) {
                                var nrp;
                                (function (nrp) {
                                    var model;
                                    (function (model) {
                                        var DvsNetworkResourcePoolsListItemData = (function () {
                                            function DvsNetworkResourcePoolsListItemData() {
                                                this._type = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsNetworkResourcePoolsListItemData';
                                            }
                                            return DvsNetworkResourcePoolsListItemData;
                                        }());
                                        model.DvsNetworkResourcePoolsListItemData = DvsNetworkResourcePoolsListItemData;
                                    })(model = nrp.model || (nrp.model = {}));
                                })(nrp = resmgmt.nrp || (resmgmt.nrp = {}));
                            })(resmgmt = dvs.resmgmt || (dvs.resmgmt = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var vm;
                (function (vm) {
                    var customizations;
                    (function (customizations) {
                        var VmCustomizationRemovalSpec = (function () {
                            function VmCustomizationRemovalSpec() {
                                this._type = 'com.vmware.vsphere.client.vm.customizations.VmCustomizationRemovalSpec';
                            }
                            return VmCustomizationRemovalSpec;
                        }());
                        customizations.VmCustomizationRemovalSpec = VmCustomizationRemovalSpec;
                    })(customizations = vm.customizations || (vm.customizations = {}));
                })(vm = client.vm || (client.vm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var vm;
                (function (vm) {
                    var customizations;
                    (function (customizations) {
                        var VmCustomizationImportValidationSpec = (function () {
                            function VmCustomizationImportValidationSpec() {
                                this._type = 'com.vmware.vsphere.client.vm.customizations.VmCustomizationImportValidationSpec';
                            }
                            return VmCustomizationImportValidationSpec;
                        }());
                        customizations.VmCustomizationImportValidationSpec = VmCustomizationImportValidationSpec;
                    })(customizations = vm.customizations || (vm.customizations = {}));
                })(vm = client.vm || (client.vm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var switchdiagram;
                        (function (switchdiagram) {
                            var model;
                            (function (model) {
                                var LagModel = (function (_super) {
                                    __extends(LagModel, _super);
                                    function LagModel() {
                                        _super.apply(this, arguments);
                                        this._type = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.LagModel';
                                    }
                                    return LagModel;
                                }(model.BaseSwitchDiagramComponent));
                                model.LagModel = LagModel;
                            })(model = switchdiagram.model || (switchdiagram.model = {}));
                        })(switchdiagram = api.switchdiagram || (api.switchdiagram = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var spbm;
                    (function (spbm) {
                        var data;
                        (function (data) {
                            var VmComplianceSummaryData = (function () {
                                function VmComplianceSummaryData() {
                                    this._type = 'com.vmware.vsphere.client.h5.spbm.data.VmComplianceSummaryData';
                                }
                                return VmComplianceSummaryData;
                            }());
                            data.VmComplianceSummaryData = VmComplianceSummaryData;
                        })(data = spbm.data || (spbm.data = {}));
                    })(spbm = h5.spbm || (h5.spbm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var vm;
                    (function (vm) {
                        var model;
                        (function (model) {
                            var migration;
                            (function (migration) {
                                var VmMigrationFlowValidationSpec = (function () {
                                    function VmMigrationFlowValidationSpec() {
                                        this._type = 'com.vmware.vsphere.client.h5.vm.model.migration.VmMigrationFlowValidationSpec';
                                    }
                                    return VmMigrationFlowValidationSpec;
                                }());
                                migration.VmMigrationFlowValidationSpec = VmMigrationFlowValidationSpec;
                            })(migration = model.migration || (model.migration = {}));
                        })(model = vm.model || (vm.model = {}));
                    })(vm = h5.vm || (h5.vm = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var imports;
                            (function (imports) {
                                var DvpgRestoreVlanPolicyModel = (function () {
                                    function DvpgRestoreVlanPolicyModel() {
                                        this._type = 'com.vmware.vsphere.client.h5.network.dvs.imports.DvpgRestoreVlanPolicyModel';
                                    }
                                    return DvpgRestoreVlanPolicyModel;
                                }());
                                imports.DvpgRestoreVlanPolicyModel = DvpgRestoreVlanPolicyModel;
                            })(imports = dvs.imports || (dvs.imports = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var vsancommon;
                (function (vsancommon) {
                    var data;
                    (function (data) {
                        var VsanCreateCostData = (function () {
                            function VsanCreateCostData() {
                                this._type = 'com.vmware.vsphere.client.vsancommon.data.VsanCreateCostData';
                            }
                            return VsanCreateCostData;
                        }());
                        data.VsanCreateCostData = VsanCreateCostData;
                    })(data = vsancommon.data || (vsancommon.data = {}));
                })(vsancommon = client.vsancommon || (client.vsancommon = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var spec;
                        (function (spec) {
                            var DvsResControlUpgradeSpec = (function () {
                                function DvsResControlUpgradeSpec() {
                                    this._type = 'com.vmware.vsphere.client.dvs.api.spec.DvsResControlUpgradeSpec';
                                }
                                return DvsResControlUpgradeSpec;
                            }());
                            spec.DvsResControlUpgradeSpec = DvsResControlUpgradeSpec;
                        })(spec = api.spec || (api.spec = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var switchdiagram;
                        (function (switchdiagram) {
                            var model;
                            (function (model) {
                                var SwitchDiagramFilter = (function () {
                                    function SwitchDiagramFilter() {
                                        this._type = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.SwitchDiagramFilter';
                                    }
                                    return SwitchDiagramFilter;
                                }());
                                model.SwitchDiagramFilter = SwitchDiagramFilter;
                            })(model = switchdiagram.model || (switchdiagram.model = {}));
                        })(switchdiagram = api.switchdiagram || (api.switchdiagram = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var ports;
                            (function (ports) {
                                var model;
                                (function (model) {
                                    var DvsPortEditTrafficFilterSpec = (function () {
                                        function DvsPortEditTrafficFilterSpec() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortEditTrafficFilterSpec';
                                        }
                                        return DvsPortEditTrafficFilterSpec;
                                    }());
                                    model.DvsPortEditTrafficFilterSpec = DvsPortEditTrafficFilterSpec;
                                })(model = ports.model || (ports.model = {}));
                            })(ports = dvs.ports || (dvs.ports = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var switchdiagram;
                        (function (switchdiagram) {
                            var model;
                            (function (model) {
                                var details;
                                (function (details) {
                                    var VmVnicModel = (function () {
                                        function VmVnicModel() {
                                            this._type = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.details.VmVnicModel';
                                        }
                                        return VmVnicModel;
                                    }());
                                    details.VmVnicModel = VmVnicModel;
                                })(details = model.details || (model.details = {}));
                            })(model = switchdiagram.model || (switchdiagram.model = {}));
                        })(switchdiagram = api.switchdiagram || (api.switchdiagram = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var switchdiagram;
                        (function (switchdiagram) {
                            var model;
                            (function (model) {
                                var SwitchDiagramModel = (function (_super) {
                                    __extends(SwitchDiagramModel, _super);
                                    function SwitchDiagramModel() {
                                        _super.apply(this, arguments);
                                        this._type = 'com.vmware.vsphere.client.dvs.api.switchdiagram.model.SwitchDiagramModel';
                                    }
                                    return SwitchDiagramModel;
                                }(model.BaseSwitchDiagramComponent));
                                model.SwitchDiagramModel = SwitchDiagramModel;
                            })(model = switchdiagram.model || (switchdiagram.model = {}));
                        })(switchdiagram = api.switchdiagram || (api.switchdiagram = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var hostprofile;
                (function (hostprofile) {
                    var data;
                    (function (data) {
                        var h5;
                        (function (h5) {
                            var ValidateHostCustomizationsSpec = (function (_super) {
                                __extends(ValidateHostCustomizationsSpec, _super);
                                function ValidateHostCustomizationsSpec() {
                                    _super.apply(this, arguments);
                                    this._type = 'com.vmware.vsphere.client.hostprofile.data.h5.ValidateHostCustomizationsSpec';
                                }
                                return ValidateHostCustomizationsSpec;
                            }(com.vmware.vsphere.client.hostprofile.data.DeferredHostSettingsSpec));
                            h5.ValidateHostCustomizationsSpec = ValidateHostCustomizationsSpec;
                        })(h5 = data.h5 || (data.h5 = {}));
                    })(data = hostprofile.data || (hostprofile.data = {}));
                })(hostprofile = client.hostprofile || (client.hostprofile = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var ippools;
                        (function (ippools) {
                            var data;
                            (function (data) {
                                var ValidateNameAndNetworksSpec = (function () {
                                    function ValidateNameAndNetworksSpec() {
                                        this._type = 'com.vmware.vsphere.client.h5.network.ippools.data.ValidateNameAndNetworksSpec';
                                    }
                                    return ValidateNameAndNetworksSpec;
                                }());
                                data.ValidateNameAndNetworksSpec = ValidateNameAndNetworksSpec;
                            })(data = ippools.data || (ippools.data = {}));
                        })(ippools = network.ippools || (network.ippools = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var ports;
                            (function (ports) {
                                var model;
                                (function (model) {
                                    var DvsPortPropertiesModel = (function () {
                                        function DvsPortPropertiesModel() {
                                            this._type = 'com.vmware.vsphere.client.h5.network.dvs.ports.model.DvsPortPropertiesModel';
                                        }
                                        return DvsPortPropertiesModel;
                                    }());
                                    model.DvsPortPropertiesModel = DvsPortPropertiesModel;
                                })(model = ports.model || (ports.model = {}));
                            })(ports = dvs.ports || (dvs.ports = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var spec;
                        (function (spec) {
                            var LacpUpgradeSpec = (function () {
                                function LacpUpgradeSpec() {
                                    this._type = 'com.vmware.vsphere.client.dvs.api.spec.LacpUpgradeSpec';
                                }
                                return LacpUpgradeSpec;
                            }());
                            spec.LacpUpgradeSpec = LacpUpgradeSpec;
                        })(spec = api.spec || (api.spec = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var dvs;
                (function (dvs) {
                    var api;
                    (function (api) {
                        var spec;
                        (function (spec) {
                            var DvsImportInfoWrapperSpec = (function () {
                                function DvsImportInfoWrapperSpec() {
                                    this._type = 'com.vmware.vsphere.client.dvs.api.spec.DvsImportInfoWrapperSpec';
                                }
                                return DvsImportInfoWrapperSpec;
                            }());
                            spec.DvsImportInfoWrapperSpec = DvsImportInfoWrapperSpec;
                        })(spec = api.spec || (api.spec = {}));
                    })(api = dvs.api || (dvs.api = {}));
                })(dvs = client.dvs || (client.dvs = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var h5;
                (function (h5) {
                    var network;
                    (function (network) {
                        var dvs;
                        (function (dvs) {
                            var resmgmt;
                            (function (resmgmt) {
                                var nrp;
                                (function (nrp) {
                                    var model;
                                    (function (model) {
                                        var DvsVmVnicResourceSettingsSpec = (function () {
                                            function DvsVmVnicResourceSettingsSpec() {
                                                this._type = 'com.vmware.vsphere.client.h5.network.dvs.resmgmt.nrp.model.DvsVmVnicResourceSettingsSpec';
                                            }
                                            return DvsVmVnicResourceSettingsSpec;
                                        }());
                                        model.DvsVmVnicResourceSettingsSpec = DvsVmVnicResourceSettingsSpec;
                                    })(model = nrp.model || (nrp.model = {}));
                                })(nrp = resmgmt.nrp || (resmgmt.nrp = {}));
                            })(resmgmt = dvs.resmgmt || (dvs.resmgmt = {}));
                        })(dvs = network.dvs || (network.dvs = {}));
                    })(network = h5.network || (h5.network = {}));
                })(h5 = client.h5 || (client.h5 = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var spbm;
                (function (spbm) {
                    var data;
                    (function (data) {
                        var VmRollupComplianceSummayData = (function () {
                            function VmRollupComplianceSummayData() {
                                this._type = 'com.vmware.vsphere.client.spbm.data.VmRollupComplianceSummayData';
                            }
                            return VmRollupComplianceSummayData;
                        }());
                        data.VmRollupComplianceSummayData = VmRollupComplianceSummayData;
                    })(data = spbm.data || (spbm.data = {}));
                })(spbm = client.spbm || (client.spbm = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));
var com;
(function (com) {
    var vmware;
    (function (vmware) {
        var vsphere;
        (function (vsphere) {
            var client;
            (function (client) {
                var network;
                (function (network) {
                    var host;
                    (function (host) {
                        var api;
                        (function (api) {
                            var HostPnicDetailsItem = (function () {
                                function HostPnicDetailsItem() {
                                    this._type = 'com.vmware.vsphere.client.network.host.api.HostPnicDetailsItem';
                                }
                                return HostPnicDetailsItem;
                            }());
                            api.HostPnicDetailsItem = HostPnicDetailsItem;
                        })(api = host.api || (host.api = {}));
                    })(host = network.host || (network.host = {}));
                })(network = client.network || (client.network = {}));
            })(client = vsphere.client || (vsphere.client = {}));
        })(vsphere = vmware.vsphere || (vmware.vsphere = {}));
    })(vmware = com.vmware || (com.vmware = {}));
})(com || (com = {}));


